import _ from "lodash";
import { getPolicies, getPolicyByName } from "../../services/api";

const REQUEST_POLICIES = "policies/PRE_REQUEST";
const REQUEST_POLICIES_LIST = "policies/REQUEST";
const WALMART_POLICY = "policy/WALMART";

const initialState = {
  policyList: [],
  default_privacy_policy: null,
  requestPoliciesInProgress: false,
  requestMadeBefore: false,
};

const WALMART_POLICY_NAME = "walmart";

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST_POLICIES:
      state.requestPoliciesInProgress = true;
      state.requestMadeBefore = true;
      break;
    case REQUEST_POLICIES_LIST: {
      state.policyList = action.data.map((policy) => {
        policy.label =
          policy.label.charAt(0).toUpperCase() + policy.label.slice(1);
        return policy;
      });
      //set walmart as default policy from the list
      let default_privacy_policy = state.policyList.find(
        (policy) => policy.value.toLowerCase() === "230" // Value for Walmart
      );
      //small update in case there is no privacy policies in DB
      if (default_privacy_policy) {
        state.default_privacy_policy = {
          policy_name: default_privacy_policy.label,
          policy_link: default_privacy_policy.link,
          policy_id: default_privacy_policy.value,
          icon: default_privacy_policy.icon,
        };
      }
      state.requestPoliciesInProgress = false;
      break;
    }
    case WALMART_POLICY:
      state.default_privacy_policy = action.data;
      break;
  }
  return state;
};

export const requestPolicies = () => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_POLICIES,
    });
    const policies = getPolicies();
    return policies
      .then((data) =>
        dispatch({
          type: REQUEST_POLICIES_LIST,
          data,
        })
      )
      .catch(() => {});
  };
};

export const requestWalmartPolicy = (name = WALMART_POLICY_NAME) => {
  return (dispatch) => {
    const policy = getPolicyByName(name);
    return policy.then((data) =>
      dispatch({
        type: WALMART_POLICY,
        data,
      })
    );
  };
};

export const actions = {
  getPolicyList: requestPolicies,
  getWalmartPolicy: requestWalmartPolicy,
};
