import React, { Component, lazy, Suspense } from "react";
import "./style/bootstrap_reboot.scss";
import "./style/fonts/bogle.scss";
import "./style/global.scss";
import {
  redirectUserToSSO,
  checkIfTokenExists,
  setUserIdToCookie,
} from "./services/sso";
import { Redirect } from "react-router";

import Header from "./components/pres/Header";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import colors from "./style/colors";
import { Provider } from "react-redux";
import store from "./store";
import getPath from "./services/getPath";
import Dialog from "./components/pres/Dialog";
import Analytics from "./services/analytics";
import qs from "query-string";
import Loading from "./components/pres/Loading/index.jsx";
import MaintenancePage from "./components/pres/MaintenancePage";
import ErrorBoundary from "./components/pres/ErrorBoundary";
import { parseJwtToken } from "./services/commons";
import Disclaimer from "./components/pres/Disclaimer";
import config from "./config/config";

import { Routes } from "./routes";
const SurveyList = lazy(() => import("./components/page/SurveyList"));
const Login = lazy(() => import("./components/page/Login"));

const theme = createTheme({
  components: {
    // Name of the components
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
      dark: colors.primaryDark,
      contrastText: colors.primaryContrastText,
    },
    secondary: {
      main: colors.primaryGreen,
      light: colors.secondaryLight,
      dark: colors.secondaryDark,
      contrastText: colors.secondaryContrastText,
    },
    error: {
      main: colors.trashRed,
    },
  },
  typography: {
    fontFamily: "inherit",
    allVariants: {
      color: "#333333",
    },
  },
  overrides: {
    MuiChip: createStyles({
      deleteIcon: {
        "&&": {
          color: "white !important",
        },
      },
    }),
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          color: colors.primary + "!important",
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        color: colors.primaryDark + "!important",
      },
    },
    MuiButton: {
      outlinedPrimary: {
        color: colors.primary,
        border: `1px solid ${colors.primary}`,
      },
    },
  },
  MuiTableCell: {
    root: {
      padding: `0px !important`,
    },
  },
});

export { theme };

const history = createHistory();

history.listen(function (location) {
  Analytics.sendPageView(location.pathname + location.search);
});

let maintenanceModeON = config.MAINTENANCE_MODE_ON;

export const getRenderComponent = (component) => {
  if (checkIfTokenExists()) {
    if (
      sessionStorage.getItem("redirectUri") &&
      sessionStorage.getItem("redirectUri") !== "/"
    ) {
      let redirectURL = sessionStorage.getItem("redirectUri");
      sessionStorage.setItem("redirectUri", "/");
      return <Redirect to={getPath(redirectURL)} />;
    } else {
      return component;
    }
  } else {
    redirectUserToSSO();
    return <Loading />;
  }
};
class App extends Component {
  constructor() {
    super();
    // this.headerItems = ["Audience", "Feedback", "Insights"]
    // this.state = {
    //   selectedHeader: this.headerItems[1],
    // };
    this.state = {
      ready: false,
    };
  }

  // changeSelection = (selection) => {
  //   const indexOf = this.headerItems.indexOf(selection);
  //   if (~indexOf) {
  //     this.setState({
  //       selectedHeader: this.headerItems[indexOf],
  //     });
  //   }
  // };

  initAnalytics = () => {
    let payload = parseJwtToken();
    if (payload !== "") {
      const userId = payload.userid.replace(/[,;=| ]+/g, "_");
      window.appInsights.setAuthenticatedUserContext(userId);
      setUserIdToCookie();
    } else {
      window.appInsights.clearAuthenticatedUserContext();
    }
    Analytics.sendPageView(window.location.pathname + window.location.search);
  };

  componentDidMount() {
    const queryParams = qs.parse(window.location.search);
    if (queryParams.loginResult === "success") {
      if (window.location.href.indexOf("?") > 0) {
        window.history.replaceState(null, null, window.location.pathname);
      }
      this.setState({ ready: true });
      this.initAnalytics();
    } else {
      this.setState({ ready: true });
      this.initAnalytics();
    }
  }

  render() {
    const { ready } = this.state;
    if (!ready) {
      return <Loading />;
    }
    if (maintenanceModeON === "true") {
      return <MaintenancePage />;
    } else {
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Provider store={store}>
                  <Router history={history} basename={"/"}>
                    <React.Fragment>
                      <Disclaimer />
                      <Header
                      // selectedHeader={selectedHeader}
                      // changeSelection={this.changeSelection}
                      // allheaders={this.headerItems}
                      />
                      <Route
                        exact
                        path={getPath("/")}
                        render={() =>
                          getRenderComponent(
                            <SurveyList
                              // headers={this.headerItems}
                              history={history}
                            />
                          )
                        }
                      />
                      <Routes />
                      <Route path={getPath("/login")} component={Login} />
                      <Dialog />
                    </React.Fragment>
                  </Router>
                </Provider>
              </Suspense>
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }
  }
}

// if (window.Cypress) {
window.store = store;
// }

export default App;
