import React from "react";
import PropTypes from "prop-types";
import walmartClassicLogo from "../../../images/walmartHeader/classic@2x.png";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import { useTranslation } from "react-i18next";

const Loading = ({ display, classes }) => {
  const { t } = useTranslation();

  const loadingText = display || t("loading") + "...";

  return (
    <div className={classes.center}>
      <Fade in timeout={1000}>
        <div className={classes.flexDir}>
          <div className={`${classes.title} ${classes.titleText}`}>
            {loadingText}
          </div>
          <LinearProgress
            className={classes.loader}
            classes={{
              root: classes.root,
              colorPrimary: classes.colorPrimary,
              bar: classes.bar,
            }}
          />
          <div className={classes.flexCenter}>
            <img
              src={walmartClassicLogo}
              alt="feedback"
              className={classes.logo}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

Loading.propTypes = {
  display: PropTypes.string,
};

export default withStyles(styles)(Loading);
