import _ from "lodash";

export const supportedQuestions = new Set(["NPS", "number", "rangeNumeric"]);

export const calculateEquation = (eq, answers, validate) => {
  let exp = "";
  let dependentAnswersFound = false,
    answerDependency = false;
  eq.forEach((item, index) => {
    switch (item.type) {
      case "operand":
        if (item.value === "x") {
          exp = exp + "*";
        } else if (
          index != 0 &&
          !["+", "/", "*", "-", "[", "("].includes(exp[exp.length - 1]) &&
          (item.value === "[" || item.value === "(")
        ) {
          exp = exp + "*" + item.value;
        } else {
          exp = exp + item.value;
        }
        break;
      case "question": {
        answerDependency = true;
        item.questions?.forEach((item) => {
          let dummyAns = 0;
          if (
            index > 0 &&
            eq[index - 1].type == "operand" &&
            (eq[index - 1].value === "x" || eq[index - 1].value === "/")
          ) {
            dummyAns = 1;
          }
          let ans = dummyAns;
          if (Object.prototype.hasOwnProperty.call(answers, item.value)) {
            dependentAnswersFound = true;
            ans = answers[item.value];
          }
          if (validate) {
            ans = 1;
          }
          exp = exp + ans;
        });
        break;
      }
      case "number": {
        exp = exp + item.value;
        break;
      }
      case "function": {
        answerDependency = true;
        const answerList = item.questions
          ?.filter(
            (item) =>
              item.type !== "question" ||
              validate ||
              Object.prototype.hasOwnProperty.call(answers, item.value)
          )
          .map((item) => {
            if (validate) {
              return 1;
            }
            if (item.type === "question") return Number(answers[item.value]);
            return Number(item.value);
          });
        if (answerList.length > 0) {
          dependentAnswersFound = true;
          switch (item.function) {
            case "SUM":
              exp = exp + _.sum(answerList);
              break;
            case "MAX":
              exp = exp + _.max(answerList);
              break;
            case "MIN":
              exp = exp + _.min(answerList);
              break;
            case "AVG":
              exp = exp + _.mean(answerList);
              break;
          }
        }
      }
    }
  });
  if (answerDependency && !dependentAnswersFound && !validate) return "0";
  return exp;
};

export const validate = (eq, questions = []) => {
  let prevItem = "";
  let error = false;
  if (eq instanceof Array) {
    for (const item of eq) {
      if (
        prevItem == "" &&
        item.type === "operand" &&
        ["+", "-", "x", "/"].includes(item.value)
      ) {
        error = true;
        break;
      }
      if (
        (item.type === prevItem && item.type != "operand") ||
        (prevItem === "number" &&
          (item.type === "function" || item.type === "question")) ||
        (item.type === "number" &&
          (prevItem === "function" || prevItem === "question"))
      ) {
        error = true;
        break;
      } else if (item.type === "function" && item.questions.length === 0) {
        error = true;
        break;
      }
      prevItem = item.type;
    }
    if (!error) {
      const response = calculateEquation(eq, {}, true);
      try {
        Function("return " + response)();
        return checkEVQuestionConflict(eq, questions);
      } catch (error) {
        window.console.error("error", error);
        return true;
      }
    }
    return true;
  }
};

export const checkEVQuestionConflict = (eq, questions = []) => {
  const dependentQues = [];
  eq?.forEach((eq) => {
    if (eq.type === "question") {
      eq.questions.forEach((q) => dependentQues.push(q.value));
    } else if (eq.type === "function") {
      eq.questions.forEach((q) => {
        if (q.type === "question") {
          dependentQues.push(q.value);
        }
      });
    }
  });
  return dependentQues.some((val) => {
    return !checkTypeValid(questions.find((q) => q.id === val) || {});
  });
};

export const checkTypeValid = (item) => {
  return (
    supportedQuestions.has(item.type) ||
    (item.type === "range" && (item.max === 10 || item.max === 5))
  );
};
