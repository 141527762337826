import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Portal from "@mui/material/Portal";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const TokenDialogContent = ({ token, copyButtonText }) => {
  const { t } = useTranslation("modal");
  const copied = copyButtonText === "COPIED";

  return (
    <>
      <Portal>
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={copied}
          key={"topcenter"}
        >
          <Alert
            severity="info"
            variant="filled"
            elevation={6}
            sx={{
              backgroundColor: "#333333",
              borderRadius: 32,
            }}
          >
            {t("getToken.copySuccess")}
          </Alert>
        </Snackbar>
      </Portal>
      <Box display="flex">
        <Typography variant="body1" color={"#4a4a4a"} style={{ width: "100%" }}>
          {token}
        </Typography>
      </Box>
    </>
  );
};

export default TokenDialogContent;
