import _ from "lodash";
import { getReleaseNotes } from "../../services/api";

const REQUEST_RELEASE_NOTES = "releaseNotes/REQUEST";
const RELEASE_NOTES_LIST = "releaseNotes/REQUEST_COMPLETED";
const initialState = {
  data: [],
  requestMadeBefore: false,
  requestInProgress: false,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST_RELEASE_NOTES:
      state.requestInProgress = true;
      break;
    case RELEASE_NOTES_LIST:
      state.requestMadeBefore = true;
      state.requestInProgress = false;
      state.data = action.data;
      break;
    default:
      break;
  }
  return state;
};

export const requestReleaseNotes = () => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_RELEASE_NOTES,
    });
    const releaseNotes = getReleaseNotes();
    return releaseNotes.then((data) =>
      dispatch({
        type: RELEASE_NOTES_LIST,
        data,
      })
    );
  };
};

export const actions = {
  getReleaseNotes: requestReleaseNotes,
};
