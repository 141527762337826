import React from "react";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { classes } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className={classes.container}>
          <h2>Something Went Wrong.</h2>
          <details className={classes.details}>
            {this.state.error && this.state.error.toString()}
            <br />
            <pre className={classes.pre}>
              {this.state.errorInfo.componentStack}
            </pre>
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
