import _ from "lodash";
import {
  createSurvey,
  getSurvey,
  getTemplateDetails,
  publishSurvey,
  updateSurvey,
  updateThirdPartySettings,
  getSurveySubmissions,
  download,
  saveSurvey,
  previewSourceFile,
} from "../../services/api";
import {
  trackAndShowError,
  showDataSourceError,
  showDynamicChoicesInfo,
} from "../dialog";
import {
  publishOrUnpublish as surveyListPublishOrUnpublish,
  updatePublishableStatus,
  updateHiddenQuestionStatus,
} from "../surveyList";
import { setCurrentSurveyId } from "../survey";
import {
  isAllQuestionsValid,
  isCurrentUserTranslator,
} from "../../services/commons";
import {
  UPDATED_MESSAGES,
  ROLE,
  CONDITIONS,
  DEFAULT_PARENT_ID,
  QUESTION_TYPES,
} from "../../components/constants";
import config from "../../config/config";
import i18next from "i18next";
import placeBlockQuestionsNearBlock from "../../utils/placeBlockQuestionsNearBlock";
import getUpdatedBlockEmptyState from "../../utils/getUpdatedBlockEmptyState";
import { loadQuestionOptionsForCV } from "../../utils/commonUtils";
import { syncSourceFilesWithVariables } from "../../utils/sourceFileUtils";

const CREATE = "surveyEdit/CREATE";
const CREATE_SUCCESS = "surveyEdit/CREATE_SUCCESS";
const OPEN = "surveyEdit/OPEN";
const GET_SURVEY = "surveyEdit/GET_SURVEY";
const LOAD_QUESTIONS = "surveyEdit/LOAD_QUESTIONS";
const UPDATE_MULTILINGUAL_QUESTIONS =
  "surveyEdit/UPDATE_MULTILINGUAL_QUESTIONS";
const REMOVE_QUESTION = "surveyEdit/REMOVE_QUESTION";
const START_LOAD_QUESTIONS_FROM_TEMPLATE =
  "surveyEdit/START_LOAD_QUESTIONS_FROM_TEMPLATE";
const LOAD_QUESTIONS_FROM_TEMPLATE = "surveyEdit/LOAD_QUESTIONS_FROM_TEMPLATE";
const MODIFY = "surveyEdit/MODIFY";
const CLEAR = "surveyEdit/CLEAR";
const RESET_FROM_DRAWER = "surveyEdit/RESET_FROM_DRAWER";
const SAVE = "surveyEdit/SAVE";
const SAVE_SUCCESS = "surveyEdit/SAVE_SUCCESS";
const REORDER_QUESTIONS = "surveyEdit/REORDER_QUESTIONS";
const SURVEY_SSO = "surveyEdit/SURVEY_SSO";
const SURVEY_ANONYMOUS = "surveyEdit/SURVEY_ANONYMOUS";
const SURVEY_MULTIPLE_SUBMISSIONS = "surveyEdit/SURVEY_MULTIPLE_SUBMISSIONS";
const SURVEY_WIN_COLLECT = "surveyEdit/SURVEY_WIN_COLLECT";
const SURVEY_CUSTOMER_ID_COLLECT = "surveyEdit/SURVEY_CUSTOMER_ID_COLLECT";
const SURVEY_STEP_BACK = "surveyEdit/SURVEY_STEP_BACK";
const SURVEY_STEP_FORWARD = "surveyEdit/SURVEY_STEP_FORWARD";
const SURVEY_CHANGE_WELCOME = "surveyEdit/SURVEY_CHANGE_WELCOME";
const SURVEY_CHANGE_THANKS = "surveyEdit/SURVEY_CHANGE_THANKS";
const SURVEY_UPDATE_TITLE = "surveyEdit/SURVEY_UPDATE_TITLE";
const SURVEY_UPDATE_SETTINGS = "surveyEdit/SURVEY_UPDATE_SETTINGS";
const SURVEY_UPDATE_SETTINGS_SUCCESS =
  "surveyEdit/SURVEY_UPDATE_SETTINGS_SUCCESS";
const SURVEY_UPDATE_WELCOME_HEAD = "surveyEdit/SURVEY_UPDATE_WELCOME_HEAD";
const SURVEY_UPDATE_WELCOME_SUB_HEAD =
  "surveyEdit/SURVEY_UPDATE_WELCOME_SUB_HEAD";
const SURVEY_UPDATE_THANKS_HEAD = "surveyEdit/SURVEY_UPDATE_THANKS_HEAD";
const SURVEY_UPDATE_THANKS_SUB_HEAD =
  "surveyEdit/SURVEY_UPDATE_THANKS_SUB_HEAD";
const SURVEY_SET_STEP = "surveyEdit/SURVEY_SET_STEP";
const SURVEY_SET_STEPS_VALIDITY = "surveyEdit/SURVEY_SET_STEPS_VALIDITY";
const SURVEY_GET_RESULTS = "surveyEdit/SURVEY_GET_RESULTS";
const SURVEY_GET_RESULTS_REQUEST = "surveyEdit/SURVEY_GET_RESULTS_REQUEST";
const SURVEY_GET_RESULTS_REQUEST_COMPLETE =
  "surveyEdit/SURVEY_GET_RESULTS_REQUEST_COMPLETE";
const SURVEY_VIEWS_COUNT = "surveyEdit/SURVEY_VIEWS_COUNT";
const SURVEY_GET_TIMES = "surveyEdit/SURVEY_GET_TIMES";
const SURVEY_PUBLISHED = "surveyEdit/SURVEY_PUBLISHED";
const SURVEY_UPDATE_POLICY_SETTINGS =
  "surveyEdit/SURVEY_UPDATE_POLICY_SETTINGS";
const SURVEY_PUBLISH_REQUEST_INPROGRESS =
  "surveyEdit/SURVEY_PUBLISH_REQUEST_INPROGRESS";
const SURVEY_UPDATE_CO_OWNER = "surveyEdit/SURVEY_UPDATE_CO_OWNER";
const SURVEY_UPDATE_VIEWER = "surveyEdit/SURVEY_UPDATE_VIEWER";
const SURVEY_UPDATE_COLLAB = "surveyEdit/SURVEY_UPDATE_COLLAB";
const SURVEY_UPDATE_COLLAB_INVITES = "surveyEdit/SURVEY_UPDATE_COLLAB_INVITES";
const SURVEY_UPDATE_DATA_SOURCES = "surveyEdit/SURVEY_UPDATE_DATA_SOURCES";
const SURVEY_UPDATE_SOURCE_FILES = "surveyEdit/SURVEY_UPDATE_SOURCE_FILES";
const SURVEY_UPDATE_AUDIENCE = "surveyEdit/SURVEY_UPDATE_AUDIENCE";
const SURVEY_UPDATE_THEME = "surveyEdit/SURVEY_UPDATE_THEME";
const SURVEY_UPDATE_LANGUAGES = "surveyEdit/SURVEY_UPDATE_LANGUAGES";
const SURVEY_UPDATE_MODIFIED_QUES_LIST =
  "surveyEdit/SURVEY_UPDATE_MODIFIED_QUES_LIST";
const SURVEY_UPDATE_EXPERIENCE = "surveyEdit/SURVEY_UPDATE_EXPERIENCE";
const SURVEY_UPDATE_SHOW_THEME_HEADERNLOGO =
  "surveyEdit/SURVEY_UPDATE_SHOW_THEME_HEADERNLOGO";
const SURVEY_UPDATE_SHOW_PROGRESSBAR =
  "surveyEdit/SURVEY_UPDATE_SHOW_PROGRESSBAR";
const REQUEST_DOWNLOAD = "surveyEdit/REQUEST_DOWNLOAD";
const DOWNLOAD_COMPLETE = "surveyEdit/DOWNLOAD_COMPLETE";
const QUESTIONS_DATA_SAVEABLE = "surveyEdit/QUESTIONS_DATA_SAVEABLE";
const SURVEY_UPDATE_GREETINGS = "surveyEdit/SURVEY_UPDATE_GREETINGS";
const SURVEY_UPDATE_CLOSING_MESSAGE =
  "surveyEdit/SURVEY_UPDATE_CLOSING_MESSAGE";
const SURVEY_CONTENT_UPLOAD_START = "surveyEdit/SURVEY_CONTENT_UPLOAD_START";
const SURVEY_CONTENT_UPLOAD_SUCCESS =
  "surveyEdit/SURVEY_CONTENT_UPLOAD_SUCCESS";
const SAVE_QUESTION_LABEL = "surveyEdit/SAVE_QUESTION_LABEL";
const SURVEY_NOTIFICATION_UPDATE = "surveyEdit/SURVEY_NOTIFICATION_UPDATE";
const ADD_PARENTID_TO_NEW_QUESTION = "surveyEdit/ADD_PARENTID_TO_NEW_QUESTION";
const UPDATE_PARENTID_OF_QUESTION = "surveyEdit/UPDATE_PARENTID_OF_QUESTION";
const DELETE_QUESTION = "surveyEdit/DELETE_QUESTION";
const UPDATE_SOURCE_FILE_VALUES = "surveyEdit/UPDATE_SOURCE_FILE_VALUES";
const UPDATE_SOURCE_INFO = "surveyEdit/UPDATE_SOURCE_INFO";

const validResultRetryId = {};
const validDownloadRetryId = {};
const resultRequestStatuses = [
  "QUEUED",
  "FETCHING",
  "PROCESSING_QUEUED",
  "PROCESSING",
];
export const STEP_SURVEY_SETTINGS = "Survey Settings";
export const STEP_CREATE_QUESTIONS = "Create Questions";
export const STEP_SURVEY_REVIEW = "Preview & Test Your Survey";
export const STEP_SEND_SURVEY = "Send Survey";
export const STEP_REVIEW_SURVEY_RESULT = "Review Survey Results";
export const STEP_SURVEY_TEMPLATE_LIST = "Select template from list";

const initialState = {
  multiLingualQuestions: {},
  multiLingualGreetings: {},
  multiLingualClosingMessage: {},
  modifiedMultiLingualQuestions: {},
  multiLingualHiddenQuestions: {},
  saveInProgress: false,
  updateSettingsInProgress: false,
  requestInProgress: false,
  requestCompletedPercent: 0,
  publishInProgress: false,
  downloadRequestInProgress: false,
  contentRequestInProgress: false,
  // templateDetailsInProgress: true,
  data: {
    thirdPartySettings: {
      co_owners_uid: [],
      viewers_uid: [],
      collaboraters: [],
      anonymous_access: false,
      anonymize: false,
      allowMultipleSubmissions: false,
      win_access: false,
      replaceTextValues: [],
      templateId: null,
      createdFromTemplate: false,
      defaultLang: undefined,
      languages: {},
    },
    status: "closed",
    collaboratorInvites: [],
    refreshSurveyList: false,
  },

  results: null,
  views: null,
  step: 1,
  steps: [
    {
      index: 1,
      text: STEP_SURVEY_SETTINGS,
      complete: false,
      iconName: "settings",
    },
    {
      index: 2,
      text: STEP_CREATE_QUESTIONS,
      complete: false,
      iconName: "add_circle",
    },
    {
      index: 3,
      text: STEP_SURVEY_REVIEW,
      complete: true,
      iconName: "visibility",
    },
    {
      index: 4,
      text: STEP_SEND_SURVEY,
      complete: true,
      iconName: "send",
    },
    {
      index: 5,
      text: STEP_REVIEW_SURVEY_RESULT,
      complete: true,
      iconName: "insert_chart",
    },
  ],
  titleModified: false,
  thirdPartySettingsModified: false,
  questionsModified: false,
  surveySaveStatus: false,
  updateHistory: [],
  newQuestionData: {
    parentId: DEFAULT_PARENT_ID,
  },
};

export const SURVEY_STEPS = {
  [STEP_SURVEY_SETTINGS]: 1,
  [STEP_SURVEY_TEMPLATE_LIST]: 2,
  [STEP_CREATE_QUESTIONS]: 3,
  [STEP_SURVEY_REVIEW]: 4,
  [STEP_SEND_SURVEY]: 5,
  [STEP_REVIEW_SURVEY_RESULT]: 6,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  let nextStep = state.step;
  switch (action.type) {
    case CREATE:
      state.createInProgress = true;
      break;
    case CREATE_SUCCESS:
      state.createInProgress = false;
      state.data["drupalId"] = action.data;
      break;
    case OPEN: {
      state.data = { ...state.data, ...action.data };

      if (action.languages) {
        state.data.thirdPartySettings.languages = action.languages;
      }

      state.data.availableLanguages = Object.keys(
        state.data.thirdPartySettings?.languages || {}
      );
      if (
        action.data.surveyExists === true &&
        action.data.surveyAuthorized === true
      ) {
        state.steps[0].complete =
          action.data.thirdPartySettings?.welcome_head !== "" &&
          action.data.thirdPartySettings?.thanks_head !== "";
      }
      state.requestInProgress = false;
      state.titleModified = false;
      state.thirdPartySettingsModified = false;
      state.questionsModified = false;
      state.updateHistory = [];
      state.data.collaboratorInvites = [];
      state.data.isTranslator = isCurrentUserTranslator(
        state.data.isMultilingual,
        state.data.thirdPartySettings?.translators
      );
      state.defaultLang = action.data.thirdPartySettings?.defaultLang; // keeping redux state logic from i18next lang to surveys default lang
      state.currentLang = action.lang
        ? action.lang
        : action.data.thirdPartySettings?.defaultLang;
      state.multiLingualGreetings = {
        ...state.multiLingualGreetings,
        [action.lang || state.defaultLang]:
          Object.keys(action.data.greetings || {}).length === 0
            ? state.multiLingualGreetings[state.defaultLang]
            : action.data.greetings,
      };
      state.multiLingualClosingMessage = {
        ...state.multiLingualClosingMessage,
        [action.lang || state.defaultLang]:
          Object.keys(action.data.closingMessage || {}).length === 0
            ? state.multiLingualClosingMessage[state.defaultLang]
            : action.data.closingMessage,
      };
      const greetings = {
        ...action.data.greetings,
        image_url: state.multiLingualGreetings[state.defaultLang]?.image_url,
      };
      state.data.thirdPartySettings.greetings =
        action.data.greetings &&
        Object.keys(action.data.greetings || {}).length < 2
          ? state.multiLingualGreetings[state.defaultLang]
          : greetings;
      state.data.greetings =
        action.data.greetings &&
        Object.keys(action.data.greetings || {}).length < 2
          ? state.multiLingualGreetings[state.defaultLang]
          : greetings;
      const closing = {
        ...action.data.closingMessage,
        image_url:
          state.multiLingualClosingMessage[state.defaultLang]?.image_url,
      };
      state.data.thirdPartySettings.closingMessage =
        action.data.closingMessage &&
        Object.keys(action.data.closingMessage || {}).length === 0
          ? state.multiLingualClosingMessage[state.defaultLang]
          : closing;
      state.data.closingMessage =
        action.data.closingMessage &&
        Object.keys(action.data.closingMessage || {}).length === 0
          ? state.multiLingualClosingMessage[state.defaultLang]
          : closing;
      const modifiedQues =
        action.data.modifiedQuesList &&
        Object.keys(action.data.modifiedQuesList || {}).length === 0
          ? state.modifiedMultiLingualQuestions[state.defaultLang] || {}
          : action.data.modifiedQuesList;
      state.data.modifiedQuesList = modifiedQues;
      state.modifiedMultiLingualQuestions = {
        ...state.modifiedMultiLingualQuestions,
        [action.lang || state.defaultLang]: modifiedQues,
      };
      break;
    }
    case GET_SURVEY:
      state.requestInProgress = true;
      break;
    case LOAD_QUESTIONS: {
      const questions =
        action.questions?.length === 0 && action.lang !== state.defaultLang
          ? state.multiLingualQuestions[state.defaultLang] || []
          : action.questions;
      const { dropdownOptions, ratingsOptions, ratingNumericOptions } =
        action.questionOptions.data[action.lang || i18next.language];
      const clonedQues = _.cloneDeep(questions);
      clonedQues.forEach((question) => {
        if (
          (action.questions?.length === 0 ||
            (state.data.showQuestionLabels &&
              state.data.isMultilingual &&
              Object.keys(state.data.modifiedQuesList || {}).includes(
                question.id
              ) &&
              !state.data.modifiedQuesList[question.id])) &&
          action.lang !== state.defaultLang &&
          !question.formattingType
        ) {
          if (
            question.preDefinedOption &&
            question.preDefinedOption !== "custom"
          ) {
            if (
              question.type === "range" &&
              question.max != 5 &&
              question.max != 10
            ) {
              const selectedItem = ratingsOptions?.find(
                (item) => item.preDefinedOption === question.preDefinedOption
              );
              const newChoices = selectedItem.options
                .find((item) => item.scalePoints === question.scalePoints)
                .options?.map((item, index) => {
                  return {
                    label: item,
                    value: index + 1,
                  };
                });
              question.choices = newChoices;
            } else if (
              question.type === "radios" ||
              question.type === "select" ||
              question.type === "checkboxes"
            ) {
              if (dropdownOptions) {
                const selectedItem = dropdownOptions?.find(
                  (item) => item.preDefinedOption === question.preDefinedOption
                );
                const newChoices = selectedItem?.options?.map((item, index) => {
                  return {
                    label: item,
                    value: question.choices?.[index].value,
                    logic: question.choices?.[index].logic,
                  };
                });
                const dependentChoices = question.choices?.filter(
                  (choice) => choice?.source?.parentId
                );
                const tranlstaionForDependentChoices = loadQuestionOptionsForCV(
                  dependentChoices,
                  clonedQues
                );
                if (tranlstaionForDependentChoices) {
                  question.choices = [
                    ...tranlstaionForDependentChoices,
                    ...newChoices,
                  ];
                }
              }
            } else if (question.type === "ranking") {
              const newChoices = question.rankingOptions?.map((item) => {
                if (item.source) {
                  const referencedQuestion = clonedQues.find(
                    (data) => data.id === item.source.parentId
                  );
                  const referencedChoice = referencedQuestion.choices?.find(
                    (val) => val.value === item.value
                  );
                  return { ...item, label: referencedChoice.label };
                }
                return item;
              });
              question.rankingOptions = newChoices;
            }
          } else if (
            question.endpoint &&
            question.endpoint !== "custom" &&
            question.type === "rangeNumeric"
          ) {
            const selectedItem = ratingNumericOptions?.options?.find(
              (item) => item.endpoint === question.endpoint
            );
            question.endpoint = selectedItem.endpoint;
            question.endpointLeft = selectedItem.endpointLeft;
            question.endpointRight = selectedItem.endpointRight;
          } else if (
            question.type === "radios" ||
            question.type === "select" ||
            question.type === "checkboxes" ||
            question.type === "range" ||
            question.type === "variable"
          ) {
            const newChoices = loadQuestionOptionsForCV(
              question.choices,
              clonedQues
            );
            question.choices = newChoices;
          } else if (question.type === "ranking") {
            const newChoices = question.rankingOptions?.map((item) => {
              if (item.source) {
                const referencedQuestion = clonedQues.find(
                  (data) => data.id === item.source.parentId
                );
                const referencedChoice = referencedQuestion.choices.find(
                  (val) => val.value === item.value
                );
                return { ...item, label: referencedChoice.label };
              }
              return item;
            });
            question.choices = newChoices;
          }
        }
      });
      // this will check if any variable is referencing to files that
      // are missing then will update state accordingly
      const sourceFiles =
        (state.data.thirdPartySettings.sourceFiles &&
          state.data.thirdPartySettings.sourceFiles[state.currentLang]) ||
        [];
      state.data.questions = syncSourceFilesWithVariables(
        sourceFiles,
        clonedQues
      );
      const hiddenQuestion =
        action.hiddenQuestions?.length === 0 &&
        action.lang !== state.defaultLang
          ? state.multiLingualHiddenQuestions[state.defaultLang] || []
          : action.hiddenQuestions;
      state.data.hiddenQuestions = hiddenQuestion;
      state.steps[1].complete = action.questionsValid;
      state.multiLingualQuestions = {
        ...state.multiLingualQuestions,
        [action.lang || state.defaultLang]: questions,
      };
      state.multiLingualHiddenQuestions = {
        ...state.multiLingualHiddenQuestions,
        [action.lang || state.defaultLang]: hiddenQuestion,
      };
      break;
    }
    case UPDATE_MULTILINGUAL_QUESTIONS:
      state.multiLingualQuestions[action.prevLang] = state.data.questions;
      state.data.questions = state.multiLingualQuestions[action.currLang];
      state.modifiedMultiLingualQuestions[action.prevLang] =
        state.data.modifiedQuesList;
      state.data.modifiedQuesList =
        state.modifiedMultiLingualQuestions[action.currLang];
      state.multiLingualHiddenQuestions[action.prevLang] =
        state.data.hiddenQuestions;
      state.data.hiddenQuestions =
        state.multiLingualHiddenQuestions[action.currLang];
      state.currentLang = action.currLang;
      state.multiLingualGreetings[action.prevLang] =
        state.data.thirdPartySettings.greetings;
      state.data.thirdPartySettings.greetings =
        state.multiLingualGreetings[action.currLang];
      state.multiLingualClosingMessage[action.prevLang] =
        state.data.thirdPartySettings.closingMessage;
      state.data.thirdPartySettings.closingMessage =
        state.multiLingualClosingMessage[action.currLang];
      state.steps[1].complete = isAllQuestionsValid(
        state.data.questions
      ).isAllQuestionsValid;
      break;
    case REMOVE_QUESTION:
      state.data.hiddenQuestions = [];
      state.data.questions = [];
      state.steps[1].complete = false;
      state.data.thirdPartySettings.replaceTextValues = [];
      state.data.thirdPartySettings.templateId = null;
      state.data.thirdPartySettings.createdFromTemplate = false;
      state.thirdPartySettingsModified = true;
      break;
    case MODIFY:
      if (
        (action.data.isQuestionsModified && !state.questionsModified) ||
        (state.currentLang !== i18next.language && state.data.isMultilingual) ||
        (state.data.questions && state.data.questions.length > 0)
      )
        state.questionsModified = true;
      if (
        state.currentLang !== i18next.language &&
        state.data.isMultilingual &&
        (state.data.thirdPartySettings.greetings ||
          state.data.thirdPartySettings.closingMessage)
      ) {
        state.thirdPartySettingsModified = true;
      }
      state.data.questions = state.data.questions?.filter(Boolean) || [];
      action.data.questions = action.data.questions?.filter(Boolean) || [];
      state.data = {
        ...action.data,
        questions: action.data.questions?.map((question) => {
          const oldQuestion = state.data.questions?.find(
            (q) => question.id === q.id
          );
          if (oldQuestion) {
            return { ...question, parentId: oldQuestion.parentId };
          } else {
            return {
              ...question,
              parentId: question.parentId || DEFAULT_PARENT_ID,
            };
          }
        }),
      };
      state.data.collaboratorInvites = [];

      // updating the empty block error if any
      state.data.questions = getUpdatedBlockEmptyState(state.data.questions);
      break;
    case RESET_FROM_DRAWER:
      state = action.data;
      break;
    case CLEAR:
      state = initialState;
      nextStep = 1;
      break;
    case SAVE:
      state.saveInProgress = true;
      break;
    case SAVE_SUCCESS:
      state.saveInProgress = false;
      state.questionsModified = false;
      state.surveySaveStatus = true;
      state.updateHistory = [];
      break;
    case REORDER_QUESTIONS:
      state.data.questions = action.data;
      state.questionsModified = true;
      state.updateHistory.push(UPDATED_MESSAGES.QUESTION_REORDERED);
      break;
    case SURVEY_SSO:
      if (
        state.data.thirdPartySettings.anonymous_access !==
        action.anonymous_access
      ) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.REQUIRE_SSO);
      }
      state.data.thirdPartySettings.win_access = action.win_access;
      state.data.thirdPartySettings.anonymous_access = action.anonymous_access;
      state.data.thirdPartySettings.anonymize = action.anonymize;
      state.data.thirdPartySettings.allowMultipleSubmissions =
        action.allowMultipleSubmissions;
      break;
    case SURVEY_ANONYMOUS:
      if (state.data.thirdPartySettings.anonymize !== action.anonymize) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.MAKE_RESPONSES_ANONYMOUS);
      }
      state.data.thirdPartySettings.anonymize = action.anonymize;
      state.data.thirdPartySettings.win_access = action.win_access;
      break;
    case SURVEY_MULTIPLE_SUBMISSIONS:
      if (
        state.data.thirdPartySettings.allowMultipleSubmissions !==
        action.allowMultipleSubmissions
      ) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.ALLOW_MULTIPLE_SUBMISSIONS);
      }
      state.data.thirdPartySettings.allowMultipleSubmissions =
        action.allowMultipleSubmissions;
      break;
    case SURVEY_WIN_COLLECT:
      if (state.data.thirdPartySettings.win_access !== action.win_access) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.ALLOW_WIN_COLLECT);
      }
      state.data.thirdPartySettings.win_access = action.win_access;
      state.data.thirdPartySettings.anonymize = action.anonymize;
      break;
    case SURVEY_CUSTOMER_ID_COLLECT:
      if (
        state.data.thirdPartySettings.campaign_enabled !==
        action.campaign_enabled
      ) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.ALLOW_CUSTOMER_ID_COLLECT);
      }
      if (!action.campaign_enabled) {
        state.data.thirdPartySettings.anonymize = true;
        state.data.thirdPartySettings.allowMultipleSubmissions = true;
      }
      state.data.thirdPartySettings.campaign_enabled = action.campaign_enabled;
      break;
    case SURVEY_STEP_FORWARD:
      nextStep = state.step + 1;
      break;
    case SURVEY_STEP_BACK:
      nextStep = state.step - 1;
      break;
    case SURVEY_SET_STEP:
      nextStep = action.data;
      break;
    case SURVEY_SET_STEPS_VALIDITY:
      state.steps[0].complete = action.data.settings;
      state.steps[1].complete = action.data.questions;
      break;
    case SURVEY_CHANGE_WELCOME:
      if (state.data.thirdPartySettings.welcome_theme !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateSettingsInProgress = true;
        state.updateHistory.push(UPDATED_MESSAGES.WELCOME_THEME);
      }
      state.data.thirdPartySettings.welcome_theme = action.data;
      break;
    case SURVEY_CHANGE_THANKS:
      if (state.data.thirdPartySettings.thanks_theme !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.THANKYOU_THEME);
      }
      state.data.thirdPartySettings.thanks_theme = action.data;
      break;
    case SURVEY_UPDATE_TITLE:
      state.data.name = action.data;
      state.data.thirdPartySettings.title = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_SETTINGS:
      state.updateSettingsInProgress = true;
      state.data.refreshSurveyList = false;
      break;
    case SURVEY_UPDATE_SETTINGS_SUCCESS:
      state.thirdPartySettingsModified = false;
      state.updateSettingsInProgress = false;
      state.updateHistory = [];
      state.data.thirdPartySettings.collaboraters =
        state.data.thirdPartySettings.collaboraters.concat(
          state.data.collaboratorInvites
        );
      state.data.collaboratorInvites = [];
      state.data.refreshSurveyList = true;
      break;
    case SURVEY_UPDATE_WELCOME_HEAD:
      if (state.data.thirdPartySettings.welcome_head !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.WELCOME_HEADLINE);
      }
      state.data.thirdPartySettings.welcome_head = action.data;
      if (
        state.data.thirdPartySettings.welcome_head !== "" &&
        state.data.thirdPartySettings.thanks_head !== ""
      ) {
        state.steps[0].complete = true;
      } else {
        state.steps[0].complete = false;
      }
      break;
    case SURVEY_UPDATE_WELCOME_SUB_HEAD:
      if (state.data.thirdPartySettings.welcome_subhead !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.WELCOME_SUB_HEADLINE);
      }
      state.data.thirdPartySettings.welcome_subhead = action.data;
      break;
    case SURVEY_UPDATE_THANKS_HEAD:
      if (state.data.thirdPartySettings.thanks_head !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.THANKYOU_HEADLINE);
      }
      state.data.thirdPartySettings.thanks_head = action.data;
      if (
        state.data.thirdPartySettings.welcome_head !== "" &&
        state.data.thirdPartySettings.thanks_head !== ""
      ) {
        state.steps[0].complete = true;
      } else {
        state.steps[0].complete = false;
      }
      break;
    case SURVEY_UPDATE_THANKS_SUB_HEAD:
      if (state.data.thirdPartySettings.thanks_subhead !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.THANKYOU_SUB_HEADLINE);
      }
      state.data.thirdPartySettings.thanks_subhead = action.data;
      break;
    case SURVEY_GET_RESULTS:
      state.results = action.questions;
      state.submissionCount = action.submissionCount;
      state.terminateCount = action.terminateCount;
      state.isStaleData = action.isStaleData;
      break;
    case SURVEY_GET_RESULTS_REQUEST:
      state.requestCompletedPercent = action.percentComplete;
      break;
    case SURVEY_GET_RESULTS_REQUEST_COMPLETE:
      state.requestCompletedPercent = 100;
      break;
    case SURVEY_VIEWS_COUNT:
      state.views = action.data;
      break;
    case SURVEY_GET_TIMES:
      state.times = action.avg_time;
      break;
    case SURVEY_PUBLISH_REQUEST_INPROGRESS:
      state.publishInProgress = true;
      break;
    case SURVEY_PUBLISHED:
      state.data.status = action.status;
      state.data.modified = action.modified_on;
      state.publishInProgress = false;
      break;
    case SURVEY_UPDATE_POLICY_SETTINGS:
      if (state.data.thirdPartySettings.selected_policy !== action.data) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.POLICY);
      }
      state.data.thirdPartySettings.selected_policy = action.data;
      break;
    case REQUEST_DOWNLOAD:
      state.downloadRequestInProgress = true;
      break;
    case DOWNLOAD_COMPLETE:
      state.downloadRequestInProgress = false;
      break;
    case QUESTIONS_DATA_SAVEABLE: {
      state.updateHistory.push(UPDATED_MESSAGES.QUESTION_UPDATED);
      state.steps[1].complete = action.isValid;

      let is_publishable = state.steps[0].complete && state.steps[1].complete;
      //Do no mark as dirty/modified when the is_publishable is already true
      if (
        state.data.thirdPartySettings.is_publishable != true &&
        state.data.thirdPartySettings.is_publishable !== is_publishable
      ) {
        state.thirdPartySettingsModified = true;
        state.updateHistory.push(UPDATED_MESSAGES.IS_PUBLISHABLE_UPDATED);
        state.data.thirdPartySettings.is_publishable =
          state.steps[0].complete && state.steps[1].complete;
      }
      break;
    }
    case SURVEY_UPDATE_CO_OWNER:
      state.updateHistory.push(UPDATED_MESSAGES.CO_OWNER_UPDATED);
      state.data.thirdPartySettings.co_owners_uid = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_VIEWER:
      state.updateHistory.push(UPDATED_MESSAGES.VIEWER_UPDATED);
      state.data.thirdPartySettings.viewers_uid = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_COLLAB:
      state.updateHistory.push(UPDATED_MESSAGES.COLLAB_UPDATED);
      state.data.thirdPartySettings.collaboraters = action.data.collaboraters;
      state.data.thirdPartySettings.translators = action.data.translators;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_COLLAB_INVITES:
      state.updateHistory.push(UPDATED_MESSAGES.COLLAB_UPDATED);
      state.data.collaboratorInvites = action.data.collaboraters;
      state.data.thirdPartySettings.translators = action.data.translators;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_DATA_SOURCES:
      state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.dataSources = action.data;
      state.questionsModified = true;
      break;
    case SURVEY_UPDATE_SOURCE_FILES:
      state.updateHistory.push(UPDATED_MESSAGES.SOURCE_FILE_UPDATED);
      state.data.thirdPartySettings.sourceFiles = action.data;

      // this will check if any variable is referencing to files that
      // are missing then will update state accordingly
      state.data.questions = syncSourceFilesWithVariables(
        action.data[state.currentLang],
        state.data.questions
      );
      break;
    case SURVEY_UPDATE_AUDIENCE:
      state.data.thirdPartySettings.audience = action.data;
      state.thirdPartySettingsModified = true;
      if (action.data === "customer") {
        state.data.thirdPartySettings.anonymize = true;
        state.data.thirdPartySettings.anonymous_access = true;
        state.data.thirdPartySettings.allowMultipleSubmissions = true;
      }
      break;
    case SURVEY_UPDATE_EXPERIENCE:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.experienceType = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_THEME:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.theme = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_LANGUAGES:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.languages = action.data;
      state.thirdPartySettingsModified = true;

      // set survey default lang if not already set
      if (
        !state.data.thirdPartySettings.defaultLang &&
        Object.keys(action.data).length > 0
      ) {
        state.data.thirdPartySettings.defaultLang = Object.keys(
          action.data
        ).includes(i18next.language)
          ? i18next.language
          : Object.keys(action.data)[0];
      }

      if (action.lang) {
        state.multiLingualClosingMessage = {
          ...state.multiLingualClosingMessage,
          [action.lang]: state.multiLingualClosingMessage[i18next.language],
        };

        state.multiLingualQuestions = {
          ...state.multiLingualQuestions,
          [action.lang]: state.multiLingualQuestions[i18next.language],
        };

        state.multiLingualGreetings = {
          ...state.multiLingualGreetings,
          [action.lang]: state.multiLingualGreetings[i18next.language],
        };

        state.multiLingualHiddenQuestions = {
          ...state.multiLingualHiddenQuestions,
          [action.lang]: state.multiLingualHiddenQuestions[i18next.language],
        };
      }
      break;
    case SURVEY_UPDATE_MODIFIED_QUES_LIST:
      state.questionsModified = true;
      if (action.remove) {
        //eslint-disable-next-line no-unused-vars
        const { [action.data]: omit, ...rest } = state.data.modifiedQuesList;
        state.data.modifiedQuesList = rest;
      } else if (action.modified) {
        if (Object.hasOwn(state.data.modifiedQuesList, action.data)) {
          state.data.modifiedQuesList = {
            ...state.data.modifiedQuesList,
            [action.data]: true,
          };
        }
        if (action.data === "closingMessage") {
          state.data.thirdPartySettings.closingMessage = {
            ...state.data.thirdPartySettings.closingMessage,
            reactivationErrMsg: false,
          };
        } else if (action.data === "greetings") {
          state.data.thirdPartySettings.greetings = {
            ...state.data.thirdPartySettings.greetings,
            reactivationErrMsg: false,
          };
        } else {
          state.data.questions = state.data.questions.map((question) => {
            if (question.id === action.data) {
              question = { ...question, reactivationErrMsg: false };
            }
            return question;
          });
        }
      } else {
        state.data.modifiedQuesList = {
          ...state.data.modifiedQuesList,
          [action.data]: false,
        };
      }
      break;
    case SURVEY_UPDATE_SHOW_THEME_HEADERNLOGO:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.visibleThemeHeaderNLogo = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_SHOW_PROGRESSBAR:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.data.thirdPartySettings.visibleProgressBar = action.data;
      state.thirdPartySettingsModified = true;
      break;
    case SURVEY_UPDATE_GREETINGS:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.questionsModified = true;
      state.data.thirdPartySettings.greetings = action.data;
      break;
    case SURVEY_UPDATE_CLOSING_MESSAGE:
      //state.updateHistory.push(UPDATED_MESSAGES.DATA_SOURCES_UPDATED);
      state.questionsModified = true;
      state.data.thirdPartySettings.closingMessage = action.data;
      break;
    case SURVEY_CONTENT_UPLOAD_START:
      state.contentRequestInProgress = true;
      break;
    case SURVEY_CONTENT_UPLOAD_SUCCESS:
      state.contentRequestInProgress = false;
      break;
    case START_LOAD_QUESTIONS_FROM_TEMPLATE:
      state.templateDetailsInProgress = true;
      break;
    case LOAD_QUESTIONS_FROM_TEMPLATE:
      state.data.hiddenQuestions = action.hiddenQuestions;
      state.data.questions = action.questions;
      state.data.thirdPartySettings.replaceTextValues =
        action.replaceTextValues;
      state.data.thirdPartySettings.templateId = action.templateId;
      state.steps[1].complete = action.questionsValid;
      state.templateDetailsInProgress = false;
      state.data.thirdPartySettings.createdFromTemplate = true;
      state.thirdPartySettingsModified = true;
      state.questionsModified = true;
      break;
    case SAVE_QUESTION_LABEL:
      state.data.showQuestionLabels = true;
      break;
    case SURVEY_NOTIFICATION_UPDATE:
      state.data.responseNotifications = action.data;
      break;
    case UPDATE_PARENTID_OF_QUESTION:
      if (state.data.questions && Array.isArray(state.data.questions)) {
        state.data.questions.forEach((question) => {
          if (question.id === action.data.questionId) {
            question.parentId = action.data.parentId;
          }
        });
        const parentBlock = state.data.questions?.find(
          (q) => q.id === action.data.parentId
        );
        const parentPipingFrom =
          parentBlock &&
          parentBlock[CONDITIONS] &&
          parentBlock[CONDITIONS].pipingConditions?.pipingFrom;
        state.newQuestionData.pipingFrom = parentPipingFrom || [];
        state.data.questions = getUpdatedBlockEmptyState(state.data.questions);
      }
      break;
    case ADD_PARENTID_TO_NEW_QUESTION:
      if (action.data) {
        const parentBlock = state.data.questions?.find(
          (q) => q.id === action.data.parentId
        );
        const parentPipingFrom =
          parentBlock &&
          parentBlock[CONDITIONS] &&
          parentBlock[CONDITIONS].pipingConditions?.pipingFrom;
        state.newQuestionData.parentId = action.data.parentId;
        state.newQuestionData.pipingFrom = parentPipingFrom || [];
      } else {
        state.newQuestionData = {};
      }
      break;
    case DELETE_QUESTION:
      state.data.questions = state.data.questions.filter(
        (q) => q.id !== action.data.id
      );
      state.data.isQuestionsModified = true;

      if (
        (state.data.status === "inactive" ||
          state.data.status === "closed" ||
          state.data.thirdPartySettings.createdFromTemplate) &&
        state.data.showQuestionLabels &&
        state.data.isMultilingual
      ) {
        let questionId = action.data.id;
        // eslint-disable-next-line no-unused-vars
        const { [questionId]: omit, ...rest } = state.data.modifiedQuesList;
        state.data.modifiedQuesList = rest;
      }
      if (
        state.data.questions[state.data.questions.length - 1] &&
        state.data.questions[state.data.questions.length - 1]["#conditions"] &&
        state.data.questions[state.data.questions.length - 1]["#conditions"]
          .skipConditions &&
        state.data.questions[state.data.questions.length - 1]["#conditions"]
          .skipConditions.length > 0
      ) {
        //Remove skip conditions if the current question becomes the last question
        state.data.questions[state.data.questions.length - 1][
          "#conditions"
        ].skipConditions = [];
      }
      // remove display logic/ piping logic from other questions associated with it
      for (let i = 0; i < state.data.questions.length; i++) {
        if (state.data.questions[i][CONDITIONS]) {
          const getDisplayCondition = state.data.questions[i][
            CONDITIONS
          ].displayConditions.findIndex(
            (ele) => ele.question === action.data.id
          );
          if (getDisplayCondition !== -1) {
            const index = state.data.questions[i][
              CONDITIONS
            ].displayConditions.findIndex(
              (ele) => ele.question === action.data.id
            );
            state.data.questions[i][CONDITIONS].displayConditions.splice(
              index,
              1
            );
          }
        }
        const pipingToConditons =
          state.data.questions[i]["#conditions"]?.pipingConditions?.pipingTo;
        if (pipingToConditons?.length) {
          const filteredPipingToConditons = pipingToConditons.filter(
            (item) => item.id !== action.data.id
          );
          state.data.questions[i]["#conditions"].pipingConditions.pipingTo =
            filteredPipingToConditons;
        }
      }

      state.steps[1].complete = isAllQuestionsValid(
        state.data.questions
      ).isAllQuestionsValid;

      break;
    case UPDATE_SOURCE_FILE_VALUES:
      state.data.thirdPartySettings.sourceFiles[action.payload.currentLang] =
        state.data.thirdPartySettings.sourceFiles[
          action.payload.currentLang
        ].map((sourceFile) =>
          sourceFile.identifier === action.payload.identifier
            ? { ...sourceFile, ...action.payload }
            : sourceFile
        );
      break;
    case UPDATE_SOURCE_INFO:
      state.data.questions = state.data.questions.map((question) =>
        question.type === QUESTION_TYPES.SOURCE_FILE_VARIABLE &&
        question.id === action.payload.sourceVariableId
          ? { ...question, sourceInfo: action.payload.sourceInfo }
          : question
      );
      break;
    default:
      break;
  }

  if (
    ROLE.VIEWER !== state.data.role ||
    (!state.saveInProgress && !state.updateSettingsInProgress)
  )
    state.step = nextStep;
  return state;
};

export const create = (data) => {
  return (dispatch, getState) => {
    let surveyEditData = getState().surveyEdit.data;
    surveyEditData = { ...surveyEditData, ...data };
    dispatch({
      type: CREATE,
    });
    return createSurvey(surveyEditData)
      .then(function (data) {
        dispatch({
          type: CREATE_SUCCESS,
          data: data.message,
        });
        dispatch(setCurrentSurveyId(data.message));
      })
      .catch((response) => {
        const trackMessage = "Error in survey create popup for survey - ";
        dispatch(
          trackAndShowError("ERROR_SURVEY_CREATION", trackMessage, response)
        );
      });
  };
};

export const open = (
  surveyId,
  isStepTwo = false,
  lang,
  callback,
  languages
) => {
  return (dispatch, getState) => {
    if (!isStepTwo) {
      dispatch({
        type: GET_SURVEY,
      });
    }

    return getSurvey(surveyId, lang)
      .then((data) => {
        let questions = [];
        let hiddenQuestions = [];
        _.each(data.questions, (question) => {
          if (question.hidden !== true) {
            questions.push(question);
          } else {
            hiddenQuestions.push(question);
          }
        });
        dispatch({
          type: OPEN,
          data: data,
          lang: lang,
          languages: languages,
        });
        dispatch(setCurrentSurveyId(data.drupalId));

        const { surveyEdit: state, questionOptions } = getState();
        const currentLang = lang || "en";
        const defaultLang = data.thirdPartySettings?.defaultLang;
        const isInactiveOrCloningFlow = data.showQuestionLabels;
        const isMultilingual = data.isMultilingual;

        if (
          isInactiveOrCloningFlow &&
          isMultilingual &&
          currentLang !== defaultLang
        ) {
          const defaultLangQuestions = state.multiLingualQuestions[defaultLang];
          const defaultLangHiddenQues =
            state.multiLingualHiddenQuestions[defaultLang];
          const currentLangQuestions = questions;
          const currentLangHiddenQues = hiddenQuestions;

          if (
            Array.isArray(defaultLangQuestions) &&
            Array.isArray(currentLangQuestions) &&
            currentLangQuestions.length > 0
          ) {
            const updatedQuestions = [];
            const currentQuestionsById = {};
            currentLangQuestions.forEach((question) => {
              currentQuestionsById[question.id] = question;
            });
            defaultLangQuestions.forEach((question) => {
              const id = question.id;
              const isModified = Object.keys(
                state.data.modifiedQuesList
              ).includes(id);
              if (
                currentQuestionsById[id] &&
                (!isModified || (isModified && state.data.modifiedQuesList[id]))
              ) {
                if (!_.isEqual(currentQuestionsById[id], question)) {
                  if (
                    question["#conditions"] &&
                    !_.isEqual(
                      currentQuestionsById[id]["#conditions"],
                      question["#conditions"]
                    )
                  ) {
                    currentQuestionsById[id]["#conditions"] =
                      question["#conditions"];
                    [
                      ...question["#conditions"].skipConditions,
                      ...question["#conditions"].displayConditions,
                    ].forEach((condition) => {
                      if (
                        ["textfield", "textarea", "hidden"].includes(
                          condition.questionType
                        )
                      ) {
                        dispatch(updateModifiedQuesList(id));
                      }
                    });
                  }
                  currentQuestionsById[id].required = question.required;
                  currentQuestionsById[id].random = question.random;
                  currentQuestionsById[id].questionLabel =
                    question.questionLabel;
                  currentQuestionsById[id].isPipedFrom = question.isPipedFrom;
                  currentQuestionsById[id].isPipedTo = question.isPipedTo;
                }
                updatedQuestions.push(currentQuestionsById[id]);
              } else {
                updatedQuestions.push(question);
              }
            });
            questions = updatedQuestions;
          }
          if (
            Array.isArray(defaultLangHiddenQues) &&
            Array.isArray(currentLangHiddenQues) &&
            currentLangHiddenQues.length > 0
          ) {
            const updatedHiddenQuestions = [];
            const currentHiddenQuesById = {};
            currentLangHiddenQues.forEach((hiddenQues) => {
              currentHiddenQuesById[hiddenQues.id] = hiddenQues;
            });
            defaultLangHiddenQues.forEach((defaultHiddenQues) => {
              if (currentHiddenQuesById[defaultHiddenQues.id]) {
                updatedHiddenQuestions.push(
                  currentHiddenQuesById[defaultHiddenQues.id]
                );
              } else {
                updatedHiddenQuestions.push(defaultHiddenQues);
              }
            });
            hiddenQuestions = updatedHiddenQuestions;
          }
        }

        dispatch({
          type: LOAD_QUESTIONS,
          hiddenQuestions: hiddenQuestions,
          questions: placeBlockQuestionsNearBlock(questions),
          questionsValid: isAllQuestionsValid(questions).isAllQuestionsValid,
          lang: lang,
          questionOptions: questionOptions,
        });
        if (callback) {
          callback();
        }
      })
      .catch((response) => {
        const trackMessage = "Error in survey edit - id - " + surveyId;
        dispatch(
          trackAndShowError("ERROR_SURVEY_EDIT", trackMessage, response)
        );
      });
  };
};

export const setMultiLingualQuestions = (prevLang, currLang, callback) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MULTILINGUAL_QUESTIONS,
      prevLang: prevLang,
      currLang: currLang,
    });
    if (callback) {
      callback();
    }
  };
};

export const getTemplate = (templateId) => {
  return (dispatch) => {
    dispatch({
      type: START_LOAD_QUESTIONS_FROM_TEMPLATE,
    });

    return getTemplateDetails(templateId)
      .then((data) => {
        let questions = [];
        let hiddenQuestions = [];
        _.each(data.questions, (question) => {
          if (question.hidden !== true) {
            questions.push(question);
          } else {
            hiddenQuestions.push(question);
          }
        });

        dispatch({
          type: LOAD_QUESTIONS_FROM_TEMPLATE,
          hiddenQuestions: hiddenQuestions,
          questions: questions,
          replaceTextValues: data.replace_text_values,
          templateId: templateId,
          questionsValid: isAllQuestionsValid(questions).isAllQuestionsValid,
        });
      })
      .catch((response) => {
        const trackMessage = "Error in survey edit - id - " + templateId;
        dispatch(
          trackAndShowError("ERROR_SURVEY_EDIT", trackMessage, response)
        );
      });
  };
};

export const getSurveyResults = (
  surveyId,
  requestId,
  questionId,
  isRecursiveCall
) => {
  return (dispatch, getState) => {
    if (!isRecursiveCall) {
      validResultRetryId[requestId] = requestId; //Just a dummy value as a place holder
      if (!questionId) {
        dispatch({
          type: SURVEY_GET_RESULTS_REQUEST,
          percentComplete: -1,
        });
      }
    }
    return getSurveySubmissions(surveyId, questionId)
      .then((data) => {
        if (validResultRetryId[requestId] !== undefined) {
          let resultStatusIndex = resultRequestStatuses.indexOf(data.status);

          if (data.status && resultStatusIndex > -1) {
            let retryAfter = config.API_RETRY_INTERVAL
              ? +config.API_RETRY_INTERVAL
              : 30000;
            let percentComplete = 0;
            if (resultStatusIndex === 1) {
              let percentFetched = 0;
              if (data.percentFetched) {
                percentFetched = data.percentFetched;
              } else if (data.lastRetrieved) {
                percentFetched = (data.lastRetrieved / data.totalPages) * 100;
              }
              percentComplete = 5 + Math.ceil((75 / 100) * percentFetched);
            } else if (resultStatusIndex === 2) {
              percentComplete = 80;
            } else if (resultStatusIndex > 2) {
              percentComplete = 100;
            }
            //dispatch only for summary calls
            if (!questionId) {
              dispatch({
                type: SURVEY_GET_RESULTS_REQUEST,
                percentComplete: percentComplete,
              });
            }

            var promise = new Promise(function (resolve) {
              //capturing the set timeout id to use it for cancelling it
              validResultRetryId[requestId] = setTimeout(
                function () {
                  return resolve(
                    dispatch(
                      getSurveyResults(surveyId, requestId, questionId, true)
                    )
                  );
                }.bind(this),
                retryAfter
              );
            });
            return promise;
          } else {
            //if questionId is not defined then it is summary
            dispatch(stopFetchingResult(requestId));
            if (!questionId) {
              return handleSubmissionsSummary(
                data,
                surveyId,
                dispatch,
                getState
              );
            } else {
              return Promise.resolve(data.answers);
            }
          }
        }
      })
      .catch((response) => {
        const trackMessage =
          "Error in gettting submissions for survey - " + surveyId;
        dispatch(
          trackAndShowError(
            "ERROR_SUBMISSIONS_RETRIEVAL",
            trackMessage,
            response
          )
        );
      });
  };
};

export const stopFetchingResult = (requestId) => {
  return (dispatch) => {
    //clearing the on going set timeout if any
    clearTimeout(validResultRetryId[requestId]);
    delete validResultRetryId[requestId];
    dispatch({
      type: SURVEY_GET_RESULTS_REQUEST_COMPLETE,
    });
  };
};

export const stopFetchingDownloadResult = (requestId) => {
  return (dispatch) => {
    //clearing the on going set timeout if any
    clearTimeout(validDownloadRetryId[requestId]);
    delete validDownloadRetryId[requestId];
    dispatch({
      type: DOWNLOAD_COMPLETE,
    });
  };
};

export const handleSubmissionsSummary = (
  data,
  surveyId,
  dispatch,
  getState
) => {
  //format the drupal data into something that makes more sense for us, probably should move this into a helper function later
  let cleanResults = data.summary, //objectToArray(_.omit(data, "data_header")),
    isStaleData = data.staleData,
    questions = getState().surveyEdit.data.questions,
    submissionCount = cleanResults.TOTAL_SUBMISSIONS,
    terminateCount = cleanResults.TOTAL_TERMINATES;

  //clear the old answers
  if (!questions) return;

  for (let a = 0; a < questions.length; a++) {
    questions[a].answers = null;
  }

  let avg_time = cleanResults.AVG_TIME;
  //iterate over the results object after removing the flat "data_header" key that will mess it up
  for (let z = 0; z < questions.length; z++) {
    if (!_.isUndefined(cleanResults.RESPONSE_SUMMARY[questions[z].id])) {
      const questionSummary = cleanResults.RESPONSE_SUMMARY[questions[z].id];
      questions[z].answeredCount = questionSummary.answered;
      questions[z].skippedCount = questionSummary.skipped;
    }
  }

  dispatch({
    type: SURVEY_VIEWS_COUNT,
    data: data.views,
  });

  dispatch({
    type: SURVEY_GET_RESULTS,
    questions,
    submissionCount,
    terminateCount,
    isStaleData,
  });

  dispatch({
    type: SURVEY_GET_TIMES,
    avg_time,
  });

  dispatch({
    type: SURVEY_GET_RESULTS_REQUEST_COMPLETE,
  });

  return questions;
};

export const clear = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR,
    });
  };
};

export const resetFromDrawer = (data) => {
  return (dispatch) => {
    dispatch({
      type: RESET_FROM_DRAWER,
      data: data,
    });
  };
};

export const modify = (data) => {
  return (dispatch) => {
    dispatch({
      type: MODIFY,
      data: { ...data, questions: data.questions },
    });
  };
};

//check for this
const debouncedSave = _.debounce(
  (dispatch, getState, lang) => {
    if (getState().surveyEdit.questionsModified) {
      dispatch({
        type: SAVE,
      });
      let surveyEditData = getState().surveyEdit.data;
      let updateHistory = getState().surveyEdit.updateHistory;
      let surveyEdit = getState().surveyEdit;
      if (surveyEditData.isMultilingual && surveyEdit.step === 2) {
        dispatch({
          type: SAVE_QUESTION_LABEL,
        });
      }

      dispatch(
        updatePublishableStatus(
          surveyEditData.drupalId,
          surveyEditData.thirdPartySettings.is_publishable
        )
      );

      return updateSurvey(
        surveyEditData.drupalId,
        surveyEditData,
        updateHistory,
        lang
      )
        .then((data) => {
          getState().surveyEdit.data.modified = data.modified;
          dispatch({
            type: SAVE_SUCCESS,
          });
          data.mine = true;

          let hasHiddenQuestion = false;
          for (let q of surveyEditData.questions) {
            if (q.type == "hidden") {
              hasHiddenQuestion = true;
            }
          }
          dispatch(
            updateHiddenQuestionStatus(
              surveyEditData.drupalId,
              hasHiddenQuestion,
              data.modified
            )
          );
        })
        .catch((response) => {
          const trackMessage =
            "Error in updating survey id - " + surveyEditData.drupalId;
          if (response == "surveydata_not_latest") {
            dispatch(
              trackAndShowError(
                "ERROR_SURVEY_UPDATE",
                trackMessage,
                response,
                "Survey has been updated by someone else while you were working on it."
              )
            );
          } else {
            dispatch(
              trackAndShowError("ERROR_SURVEY_UPDATE", trackMessage, response)
            );
          }
        });
    } else {
      return Promise.resolve();
    }
  },
  400,
  {
    leading: true,
    trailing: false,
  }
);

const debouncedSaveSurvey = _.debounce(
  (dispatch, getState, lang) => {
    dispatch({
      type: SAVE,
    });
    let surveyEditData = getState().surveyEdit.data;
    let multiLingualQuestions = getState().surveyEdit.multiLingualQuestions;
    let multiLingualGreetings = getState().surveyEdit.multiLingualGreetings;
    let multiLingualClosingMessage =
      getState().surveyEdit.multiLingualClosingMessage;
    let modifiedMultiLingualQuestions =
      getState().surveyEdit.modifiedMultiLingualQuestions;
    let languages = getState().surveyEdit.data.thirdPartySettings.languages;
    // let updateHistory = getState().surveyEdit.updateHistory;
    let surveyEdit = getState().surveyEdit;

    if (surveyEditData.isMultilingual && surveyEdit.step === 2) {
      dispatch({
        type: SAVE_QUESTION_LABEL,
      });
    }
    dispatch(
      updatePublishableStatus(
        surveyEditData.drupalId,
        surveyEditData.thirdPartySettings.is_publishable
      )
    );

    return saveSurvey(
      surveyEditData.drupalId,
      surveyEditData,
      multiLingualQuestions,
      multiLingualGreetings,
      multiLingualClosingMessage,
      modifiedMultiLingualQuestions,
      //updateHistory,
      lang,
      languages
    )
      .then((surveyData) => {
        getState().surveyEdit.data.modified = surveyData.modified;
        dispatch({
          type: SAVE_SUCCESS,
        });
        surveyData.mine = true;
      })
      .catch((errorResponse) => {
        const trackMessage =
          "Error while saving survey id - " + surveyEditData.drupalId;
        if (errorResponse == "surveydata_not_latest") {
          dispatch(
            trackAndShowError(
              "ERROR_SURVEY_UPDATE",
              trackMessage,
              errorResponse,
              "Survey has been updated by someone else while you were working on it."
            )
          );
        } else {
          dispatch(
            trackAndShowError(
              "ERROR_SURVEY_UPDATE",
              trackMessage,
              errorResponse
            )
          );
        }
      });
  },
  400,
  {
    leading: true,
    trailing: false,
  }
);

export const save = (survey, lang) => {
  return (dispatch, getState) => {
    return debouncedSave(dispatch, getState, lang);
  };
};

export const saveSurveyData = (survey, lang) => {
  return (dispatch, getState) => {
    return debouncedSaveSurvey(dispatch, getState, lang);
  };
};

export const updateTitle = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_TITLE,
      data: data,
    });
  };
};

const debouncedUpdateSettings = _.debounce(
  (dispatch, getState, lang) => {
    if (getState().surveyEdit.thirdPartySettingsModified) {
      dispatch({
        type: SURVEY_UPDATE_SETTINGS,
      });
      let surveyEditState = getState().surveyEdit;

      let surveyEditData = surveyEditState.data;
      let updateHistory = surveyEditState.updateHistory;

      dispatch(
        updatePublishableStatus(
          surveyEditData.drupalId,
          surveyEditData.thirdPartySettings.is_publishable
        )
      );
      let thirdPartySettings = _.clone(surveyEditData.thirdPartySettings);
      //merging invites list to collaborator list
      thirdPartySettings.collaboraters =
        thirdPartySettings.collaboraters.concat(
          surveyEditData.collaboratorInvites
        );

      if (
        !(
          thirdPartySettings.viewers_uid &&
          thirdPartySettings.viewers_uid.length > 0
        )
      ) {
        thirdPartySettings.viewers_uid = [];
      }
      if (
        !(
          thirdPartySettings.co_owners_uid &&
          thirdPartySettings.co_owners_uid.length > 0
        )
      ) {
        thirdPartySettings.co_owners_uid = [];
      }
      if (
        !(
          thirdPartySettings.collaboraters &&
          thirdPartySettings.collaboraters.length > 0
        )
      ) {
        thirdPartySettings.collaboraters = [];
      }

      thirdPartySettings.modified_on = surveyEditData.modified;

      //TODO:Should be removed once validation fixed from service side
      thirdPartySettings.thanks_head = "Thank You!";
      thirdPartySettings.thanks_theme = 1;
      thirdPartySettings.welcome_head = "Welcome";
      thirdPartySettings.welcome_theme = 1;
      thirdPartySettings.selected_policy =
        thirdPartySettings.selected_policy || {
          policy_id: "230",
          policy_name: "Walmart",
          policy_link: "http://corporate.walmart.com/privacy-security",
        };
      return updateThirdPartySettings(
        surveyEditData.drupalId,
        thirdPartySettings,
        updateHistory,
        lang
      )
        .then((response) => {
          getState().surveyEdit.data.modified = response.modified_on;
          dispatch({
            type: SURVEY_UPDATE_SETTINGS_SUCCESS,
          });
        })
        .catch((response) => {
          const trackMessage =
            "Error in updating survey id - " +
            surveyEditData.drupalId +
            " third party settings";
          if (response == "surveydata_not_latest") {
            dispatch(
              trackAndShowError(
                "ERROR_SURVEY_TITLE_UPDATE",
                trackMessage,
                response,
                "Survey has been updated by someone else while you were working on it."
              )
            );
          } else {
            dispatch(
              trackAndShowError(
                "ERROR_THIRD_PARTY_SETTINGS_UPDATE",
                trackMessage,
                response
              )
            );
          }
        });
    }
  },
  1500,
  {
    leading: true,
    trailing: false,
  }
);

export const updateSettings = (lang = i18next.language) => {
  return (dispatch, getState) => {
    /*dispatch({
            type: SURVEY_UPDATE_SETTINGS
        });*/
    let surveyEditState = getState().surveyEdit;
    /*
            Do not attempt to save the survey settings when the update questions is in progress or pending.
            Update survey settings will be attempted after completion of update questions call.
            This check is to avoid the two parallel update calls from the same page/screen. 

        */

    // passing default lang for update settings instead of i18n lang
    let defaultLang = surveyEditState.data?.thirdPartySettings?.defaultLang;
    if (surveyEditState.thirdPartySettingsModified) {
      return debouncedUpdateSettings(
        dispatch,
        getState,
        defaultLang ? defaultLang : lang
      );
    }
  };
};

export const reOrderQuestions = (data) => {
  return (dispatch) => {
    dispatch({
      type: REORDER_QUESTIONS,
      data,
    });
  };
};

export const toggleAnon = (
  anonymous_access,
  win_access,
  anonymize,
  allowMultipleSubmissions
) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_SSO,
      anonymous_access,
      win_access,
      anonymize,
      allowMultipleSubmissions,
    });
  };
};

export const toggleAnonDownload = (anonymize, win_access) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_ANONYMOUS,
      anonymize,
      win_access,
    });
  };
};

export const toggleAllowMultipleSubmissions = (allowMultipleSubmissions) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_MULTIPLE_SUBMISSIONS,
      allowMultipleSubmissions,
    });
  };
};

export const toggleWinCollect = (win_access, anonymize) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_WIN_COLLECT,
      win_access,
      anonymize,
    });
  };
};

export const toggleCustomerIDCollect = (campaign_enabled) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_CUSTOMER_ID_COLLECT,
      campaign_enabled,
    });
  };
};

export const nextStep = () => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_STEP_FORWARD,
    });
  };
};

export const removeQuestions = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_QUESTION,
    });
  };
};

export const previousStep = () => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_STEP_BACK,
    });
  };
};

export const setStep = (step) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_SET_STEP,
      data: step,
    });
  };
};

export const setStepsValidity = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_SET_STEPS_VALIDITY,
      data: data,
    });
  };
};

export const changeWelcome = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_CHANGE_WELCOME,
      data: data,
    });
  };
};

export const changeThanks = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_CHANGE_THANKS,
      data: data,
    });
  };
};

export const updateWelcomeHead = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_WELCOME_HEAD,
      data: data,
    });
  };
};

export const updateWelcomeSubHead = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_WELCOME_SUB_HEAD,
      data: data,
    });
  };
};

export const updateThanksHead = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_THANKS_HEAD,
      data: data,
    });
  };
};

export const updateThanksSubHead = (data) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_THANKS_SUB_HEAD,
      data: data,
    });
  };
};

export const publishOrUnpublish = (id, publish, modified_on, completed) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_PUBLISH_REQUEST_INPROGRESS,
    });
    return publishSurvey(id, publish, modified_on, completed)
      .then((response) => {
        if (publish !== "inTranslation") {
          dispatch({
            type: SURVEY_PUBLISHED,
            status: "published",
            modified_on: response.modified_on,
            completed: completed,
          });

          dispatch(
            surveyListPublishOrUnpublish(
              id,
              publish,
              response.modified_on,
              completed
            )
          );
        }
      })
      .then(() => true)
      .catch((response) => {
        const trackMessage = "Error in publishing survey id - " + id;
        dispatch(
          trackAndShowError("ERROR_PUBLISH_SURVEY", trackMessage, response)
        );
      });
  };
};

export const updatePolicySettings = (data) => {
  let policy = data.map((policy) => ({
    policy_name: policy.label,
    policy_link: policy.link,
    policy_id: policy.value,
  }));
  return (dispatch) => {
    dispatch({
      type: SURVEY_UPDATE_POLICY_SETTINGS,
      data: policy,
    });
  };
};

export const downloadCSV = (id, requestId, name, isRecursiveCall) => {
  return (dispatch) => {
    if (!isRecursiveCall) {
      validDownloadRetryId[requestId] = requestId; //Just a dummy value as a place holder
      dispatch({
        type: REQUEST_DOWNLOAD,
      });
    }
    return download(id, name)
      .then((data) => {
        if (validDownloadRetryId[requestId] !== undefined) {
          // let resultStatusIndex = resultRequestStatuses.indexOf(data.status);
          if (data && data.status) {
            let retryAfter = config.API_RETRY_INTERVAL
              ? +config.API_RETRY_INTERVAL
              : 30000;
            var promise = new Promise(function (resolve) {
              //capturing the set timeout id to use it for cancelling it
              validDownloadRetryId[requestId] = setTimeout(
                function () {
                  return resolve(
                    dispatch(downloadCSV(id, requestId, name, true))
                  );
                }.bind(this),
                retryAfter
              );
            });
            return promise;
          } else {
            dispatch(stopFetchingDownloadResult(requestId));
          }
        }
      })
      .catch((response) => {
        const trackMessage = "Error in downloading csv - survey id - " + id;
        dispatch(
          trackAndShowError("ERROR_DOWNLOADING_CSV", trackMessage, response)
        );
      });
  };
};

export const updateQuestionsValidationStatus = (isValid) => {
  return (dispatch) => {
    dispatch({
      type: QUESTIONS_DATA_SAVEABLE,
      isValid,
    });
  };
};

export const clearData = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR,
    });
};

export const updateCoowner = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_CO_OWNER,
      data: data,
    });
};

export const updateViewer = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_VIEWER,
      data: data,
    });
};

export const updateCollab = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_COLLAB,
      data,
    });
};

export const updateCollabInvites = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_COLLAB_INVITES,
      data,
    });
};

export const updateDataSources = (dataSources) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_DATA_SOURCES,
      data: dataSources,
    });
};

export const updateSourceFiles = (files) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_SOURCE_FILES,
      data: files,
    });
};

export const updateAudience = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_AUDIENCE,
      data: data,
    });
};

export const updateTheme = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_THEME,
      data: data,
    });
};

export const updateLanguages = (data, lang) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_LANGUAGES,
      data: data,
      lang: lang,
    });
};

export const updateModifiedQuesList = (
  questionId,
  remove = false,
  modified = false
) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_MODIFIED_QUES_LIST,
      data: questionId,
      remove,
      modified,
    });
};
export const updateExperience = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_EXPERIENCE,
      data: data,
    });
};
export const updateShowThemeHeaderNLogo = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_SHOW_THEME_HEADERNLOGO,
      data: data,
    });
};
export const updateShowProgressBar = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_SHOW_PROGRESSBAR,
      data: data,
    });
};

export const updateGreetings = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_GREETINGS,
      data: data,
    });
};

export const updateClosingMessage = (data) => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_UPDATE_CLOSING_MESSAGE,
      data: data,
    });
};

export const surveyEditContentUpload = () => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_CONTENT_UPLOAD_START,
    });
};

export const surveyEditContentUploadSuccess = () => {
  return (dispatch) =>
    dispatch({
      type: SURVEY_CONTENT_UPLOAD_SUCCESS,
    });
};

export const updateSurveyNotification = (notification) => {
  return (dispatch) => {
    dispatch({
      type: SURVEY_NOTIFICATION_UPDATE,
      data: notification,
    });
  };
};

export const updateQuestionParentId = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PARENTID_OF_QUESTION,
      data,
    });
  };
};

export const addNewQuestionParentId = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PARENTID_TO_NEW_QUESTION,
      data,
    });
  };
};

export const deleteQuestion = (questionId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_QUESTION,
      data: {
        id: questionId,
      },
    });
  };
};

export const fetchSourceFileValues = (fileIdentifier) => {
  return async (dispatch, getState) => {
    const {
      currentLang,
      data: { drupalId },
    } = getState().surveyEdit;

    try {
      const uploadedFileData = await previewSourceFile(
        drupalId,
        fileIdentifier,
        currentLang
      );

      dispatch({
        type: UPDATE_SOURCE_FILE_VALUES,
        payload: {
          currentLang,
          identifier: fileIdentifier,
          values: uploadedFileData.slice(1), // first row is column headers so we are removing them
        },
      });
    } catch (e) {
      window.console.error(e);
    }
  };
};

export const updateSourceVariableSourceInfo = (
  sourceVariableId,
  sourceInfo
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SOURCE_INFO,
      payload: {
        sourceVariableId,
        sourceInfo,
      },
    });
  };
};

export const actions = {
  surveyCreate: create,
  surveyEditOpen: open,
  surveyGetTemplate: getTemplate,
  surveyEditModify: modify,
  surveyEditSave: save,
  surveyListReOrder: reOrderQuestions,
  surveyEditToggleAnon: toggleAnon,
  surveyEditToggleAllowWinCollect: toggleWinCollect,
  surveyEditToggleAllowMultipleSubmissions: toggleAllowMultipleSubmissions,
  surveyEditToggleAnonDownload: toggleAnonDownload,
  surveyNextStep: nextStep,
  surveyRemoveQuestions: removeQuestions,
  surveyPreviousStep: previousStep,
  surveyChangeWelcome: changeWelcome,
  surveyChangeThanks: changeThanks,
  surveyEditUpdateTitle: updateTitle,
  surveyEditUpdateSettings: updateSettings,
  surveyEditUpdateWelcomeHead: updateWelcomeHead,
  surveyEditUpdateWelcomeSubHead: updateWelcomeSubHead,
  surveyEditUpdateThanksHead: updateThanksHead,
  surveyEditUpdateThanksSubHead: updateThanksSubHead,
  surveyEditSetStep: setStep,
  surveyEditSetStepsValidity: setStepsValidity,
  surveyEditClear: clear,
  surveyEditGetResults: getSurveyResults,
  surveyEditStopFetchingResult: stopFetchingResult,
  surveyEditStopFetchingDownloadResult: stopFetchingDownloadResult,
  surveyEditPublishOrUnpublish: publishOrUnpublish,
  surveyEditUpdatePolicySettings: updatePolicySettings,
  surveyResultsDownloadCSV: downloadCSV,
  surveyEditUpdateValidationStatus: updateQuestionsValidationStatus,
  surveyEditClearData: clearData,
  surveyEditUpdateCoowner: updateCoowner,
  surveyEditUpdateViewer: updateViewer,
  surveyEditUpdateCollab: updateCollab,
  surveyEditUpdateCollabInvites: updateCollabInvites,
  surveyEditUpdateDataSources: updateDataSources,
  surveyEditUpdateSourceFiles: updateSourceFiles,
  surveyEditAudience: updateAudience,
  surveyEditTheme: updateTheme,
  surveyEditLanguages: updateLanguages,
  surveyEditModifiedQuesList: updateModifiedQuesList,
  surveyEditExperience: updateExperience,
  surveyEditShowThemeHeaderNLogo: updateShowThemeHeaderNLogo,
  surveyEditShowProgressBar: updateShowProgressBar,
  surveyEditGreetings: updateGreetings,
  surveyEditClosingMessage: updateClosingMessage,
  surveyEditShowDataSourceError: showDataSourceError,
  surveyEditTrackAndShowError: trackAndShowError,
  surveyEditShowDynamicChoicesInfo: showDynamicChoicesInfo,
  surveyContentUpload: surveyEditContentUpload,
  surveyContentUploadSuccess: surveyEditContentUploadSuccess,
  surveyEditSetMultiLingualQuestions: setMultiLingualQuestions,
  surveyEditResetFromDrawer: resetFromDrawer,
  surveyEditUpdateNotification: updateSurveyNotification,
  surveyEditAddNewQuestionParentId: addNewQuestionParentId,
  surveyEditUpdateQuestionParentId: updateQuestionParentId,
  surveyEditDeleteQuestion: deleteQuestion,
  surveyEditSaveSurvey: saveSurveyData,
  surveyEditFetchSourceFileValues: fetchSourceFileValues,
  surveyEditUpdateSourceVariableSourceInfo: updateSourceVariableSourceInfo,
};
