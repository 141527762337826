import { combineReducers } from "redux";
import survey from "./survey";
import surveyEdit from "./surveyEdit";
import surveyList from "./surveyList";
import submissionEdit from "./submissionEdit";
import dialog from "./dialog";
import auth from "./auth";
import pageInfo from "./pageInfo";
import policies from "./policies";
import releaseNotes from "./releaseNotes";
import themeImages from "./themeImages";
import userInfo from "./userInfo";
import templateList from "./templateList";
import questionBank from "./questionBank";
import questionOptions from "./questionOptions";
import autoSave from "./autoSave";

export default combineReducers({
  auth,
  survey,
  surveyEdit,
  surveyList,
  submissionEdit,
  dialog,
  pageInfo,
  policies,
  releaseNotes,
  themeImages,
  userInfo,
  templateList,
  questionBank,
  questionOptions,
  autoSave,
});
