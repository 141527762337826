import i18next from "../locales";

const t = i18next.t;

export const SURVEY_UPDATED_ERROR_MESSAGE = t("common:surveyUpdatedError");
export const SURVEY_DELETED_ERROR_MESSAGE = t("common:surveyDeletedError");
export const SURVEY_SAVE_FAILED_ERROR_MESSAGE = t(
  "common:surveySaveFailedError"
);
export const SURVEY_DEACTIVATED_ERROR_MESSAGE = t(
  "common:surveyDeactivatedError"
);
export const SURVEY_SOMETHING_WRONG_MESSAGE = t(
  "common:surveySomethingWrongError"
);

export const SURVEY_INVALID_PARAMS_MSG = (msg) => {
  if (msg && msg.includes("No Active waves found")) {
    return t("common:invalidParamErrorMsgWave");
  }
  return t("common:invalidParamErrorMsg");
};
