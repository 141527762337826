import config from "../../config/config";

export const shortAnswerMaxCount = 250;
export const longAnswerMaxCount = 6500;
export const surveyTitleMaxCount = 100;
export const containerScrollingOffset = 6;

export const SSO_LOGIN_URL = config.SSO_LOGIN_URL;
//export const SSO_LOGIN_URL = 'http://localhost:7071/api/authenticate?RelayState=http://localhost:7071/api/Login';
export const SSO_LOGOUT_URL = config.SSO_LOGOUT_URL;
export const CACHE_SURVEY_RESPONSE_KEY = "surveyResponse";
export const EQUATION = "equation";
