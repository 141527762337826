import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { SSO_LOGIN_URL } from "../constants";
import { parseJwtToken } from "../commons";
//check if token exists
export const checkIfTokenExists = () => {
  let token = cookie.load("accessToken");
  if (!token) {
    return false;
  }
  return true;
};

//check if token
export const returnTokenOrForceReAuthentication = () =>
  new Promise((resolve, reject) => {
    //check if stored
    if (checkIfTokenExists() === false) {
      sessionStorage.setItem("redirectUri", "/");
      return reject("token-expired");
    }
    resolve(cookie.load("accessToken"));
  });

export const redirectUserToSSO = () => {
  cookie.remove("accessToken");
  sessionStorage.setItem(
    "redirectUri",
    window.location.pathname + window.location.search
  );
  window.location.href = SSO_LOGIN_URL;
};

export const saveAuthToken = (queryParams) => {
  if (queryParams.accessToken && !cookie.load("accessToken")) {
    const cookies = new Cookies();
    //if query has access token then store it in cookie and redirect user to the homepage
    let expires = new Date();
    expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000);
    let options = {
      path: "/",
      expires: expires,
      secure: true,
      sameSite: "none",
    };
    cookies.set("accessToken", queryParams.accessToken, options);

    let payload = parseJwtToken(queryParams.accessToken);
    cookies.set("userid", payload.userid, options);

    if (window.location.href.indexOf("?") > 0) {
      window.history.replaceState(null, null, window.location.pathname);
      return;
    }
  }
};

export const setUserIdToCookie = () => {
  const payload = parseJwtToken();
  if (payload !== "") {
    const cookies = new Cookies();
    const expires = new Date();
    expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000);
    const options = {
      path: "/",
      expires: expires,
      secure: true,
      sameSite: "none",
    };
    const userId = payload.userid;
    cookies.set("userid", userId, options);
  }
};
