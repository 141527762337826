const config = {
  dev: {
    MAINTENANCE_MODE_ON: "false",
    API_SERVER_URL: "https://api.feedbackally.dev.walmart.com",
    API_ENDPOINT: "https://api.feedbackally.dev.walmart.com/api",
    API_ENDPOINT_UFMS: "https://api.ufms.dev.walmart.com/ufms/v1",
    SSO_LOGIN_URL:
      "https://pfeddev.wal-mart.com/idp/startSSO.ping?PartnerSpId=internalsurveyplatform-dev&ACSIdx=6",
    SSO_LOGOUT_URL: "https://pfeddev.wal-mart.com/ext/fedlogout",
    ANALYTICS_ENABLED: true,
    ANALYTICS_TRACKING_CODE: "UA-133189125-3",
    ANALYTICS_APP_VERSION: "v2.0.0",
    HELP_LINK:
      "https://jira.walmart.com/servicedesk/customer/portal/6866/create/16327",
    API_RETRY_INTERVAL: 3000,
  },
  dev2: {
    MAINTENANCE_MODE_ON: "false",
    API_SERVER_URL: "https://api.feedbackally.dev2.walmart.com",
    API_ENDPOINT: "https://api.feedbackally.dev2.walmart.com/api",
    API_ENDPOINT_UFMS: "https://api.ufms.dev.walmart.com/ufms/v1",
    SSO_LOGIN_URL:
      "https://pfeddev.wal-mart.com/idp/startSSO.ping?PartnerSpId=internalsurveyplatform-dev&ACSIdx=7",
    SSO_LOGOUT_URL: "https://pfeddev.wal-mart.com/ext/fedlogout",
    ANALYTICS_ENABLED: true,
    ANALYTICS_TRACKING_CODE: "UA-133189125-3",
    ANALYTICS_APP_VERSION: "v2.0.0",
    HELP_LINK:
      "https://jira.walmart.com/servicedesk/customer/portal/6866/create/16327",
    API_RETRY_INTERVAL: 3000,
  },
  stage: {
    MAINTENANCE_MODE_ON: "false",
    API_SERVER_URL: "https://api.feedbackally.stage.walmart.com",
    API_ENDPOINT: "https://api.feedbackally.stage.walmart.com/api",
    API_ENDPOINT_UFMS: "https://api.ufms.dev.walmart.com/ufms/v1",
    SSO_LOGIN_URL:
      "https://pfedcert.wal-mart.com/idp/startSSO.ping?PartnerSpId=internalsurveyplatform-stage&ACSIdx=6",
    SSO_LOGOUT_URL: "https://pfedcert.wal-mart.com/ext/fedlogout",
    ANALYTICS_ENABLED: true,
    ANALYTICS_TRACKING_CODE: "UA-133189125-2",
    ANALYTICS_APP_VERSION: "v2.0.0",
    HELP_LINK:
      "https://jira.walmart.com/servicedesk/customer/portal/6866/create/16327",
    API_RETRY_INTERVAL: 3000,
  },
  uat: {
    MAINTENANCE_MODE_ON: "false",
    API_SERVER_URL: "https://api.feedbackally.uat.walmart.com",
    API_ENDPOINT: "https://api.feedbackally.uat.walmart.com/api",
    API_ENDPOINT_UFMS: "https://api.ufms.stage.walmart.com/ufms/v1",
    SSO_LOGIN_URL:
      "https://pfedcert.wal-mart.com/idp/startSSO.ping?PartnerSpId=internalsurveyplatform-stage&ACSIdx=8",
    SSO_LOGOUT_URL: "https://pfedcert.wal-mart.com/ext/fedlogout",
    ANALYTICS_ENABLED: true,
    ANALYTICS_TRACKING_CODE: "UA-133189125-2",
    ANALYTICS_APP_VERSION: "v2.0.0",
    HELP_LINK:
      "https://jira.walmart.com/servicedesk/customer/portal/6866/create/16327",
    API_RETRY_INTERVAL: 3000,
  },
  prod: {
    MAINTENANCE_MODE_ON: "false",
    API_SERVER_URL: "https://api.feedbackally.walmart.com",
    API_ENDPOINT: "https://api.feedbackally.walmart.com/api",
    API_ENDPOINT_UFMS: "https://api.ufms.prod.walmart.com/ufms/v1",
    SSO_LOGIN_URL:
      "https://pfedprod.wal-mart.com/idp/startSSO.ping?PartnerSpId=surveysays&ACSIdx=4",
    SSO_LOGOUT_URL: "https://pfedprod.wal-mart.com/ext/fedlogout",
    ANALYTICS_ENABLED: true,
    ANALYTICS_TRACKING_CODE: "UA-133189125-1",
    ANALYTICS_APP_VERSION: "v2.0.0",
    HELP_LINK:
      "https://jira.walmart.com/servicedesk/customer/portal/6866/create/16327",
    API_RETRY_INTERVAL: 3000,
  },
};

export default config;
