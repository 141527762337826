import { AUTOSAVE_FIELDS } from "../../components/constants";
import {
  AUTO_SAVE_,
  AUTO_SAVE_THROTTLE_TIME,
} from "../../components/constants";
import { autoSaveResponse, getAutoSaveResponse } from "../../services/api";
import { throttle } from "lodash";
import _ from "lodash";
const REQUEST = "survey/autosave/REQUEST";
const REQUEST_SUCCESS = "survey/autosave/REQUEST_SUCCESS";
const DELETE_SUCCESS = "survey/autosave/DELETE_SUCCESS";

const initialState = {
  requestInProgress: false,
  deleteInProgress: false,
  requestCompleted: false,
  dataExist: false,
  data: {
    spent_time: 0,
  },
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST:
      state.requestInProgress = true;
      state.requestCompleted = false;
      state.dataExist = false;
      break;
    case REQUEST_SUCCESS:
      state = { ...state, ...action.data };
      state.requestInProgress = false;
      state.requestCompleted = true;
      state.dataExist = state.data?.answers ? true : false;
      break;
    case DELETE_SUCCESS:
      state.data = initialState;
      state.dataExist = false;
      state.requestInProgress = false;
      state.requestCompleted = true;
      break;
    default:
      break;
  }
  return state;
};

export const requestData = (surveyId, campaignDetails) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST,
      surveyId,
    });
    const localData = JSON.parse(localStorage.getItem(AUTO_SAVE_ + surveyId));
    const request = getAutoSaveResponse(surveyId, campaignDetails);
    return request
      .then((data) => {
        let autoSavedData = data;
        // cross-browser/device sync
        if (
          localData?.modified_on &&
          localData.modified_on > data.modified_on
        ) {
          autoSavedData = { ...data, data: localData.data };
        }
        return dispatch(requestSuccess(autoSavedData));
      })
      .catch((err) => {
        window.console.error(err);
      });
  };
};

const throttledSaveResponse = throttle(
  autoSaveResponse,
  AUTO_SAVE_THROTTLE_TIME
);

function formatDataToSave(data, state) {
  const formattedData = {};
  formattedData[AUTOSAVE_FIELDS.DATA] = data;
  formattedData[AUTOSAVE_FIELDS.MODIFIED_ON] = new Date();
  formattedData[AUTOSAVE_FIELDS.STARTED_ON] = state[AUTOSAVE_FIELDS.STARTED_ON];
  formattedData[AUTOSAVE_FIELDS.SUBMITTED_BY] =
    state[AUTOSAVE_FIELDS.SUBMITTED_BY];
  return formattedData;
}

export const saveData = (surveyId, data, campaignDetails) => {
  return (_, getState) => {
    const formattedData = formatDataToSave(data, getState().autoSave);
    localStorage.setItem(AUTO_SAVE_ + surveyId, JSON.stringify(formattedData));
    try {
      throttledSaveResponse(surveyId, formattedData, campaignDetails);
    } catch (err) {
      window.console.error(err);
    }
  };
};

export const deleteData = (surveyId) => {
  return (dispatch) => {
    localStorage.removeItem(AUTO_SAVE_ + surveyId);
    dispatch({
      type: DELETE_SUCCESS,
      surveyId,
    });
  };
};

export const requestSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_SUCCESS,
      data,
    });

    return data;
  };
};

export const actions = {
  requestData: requestData,
  deleteData: deleteData,
  requestSuccess: requestSuccess,
  saveData: saveData,
};
