export default {
  primary: "#0071ce",
  primaryLight: "#5c9fff",
  primaryDark: "#004f9a",
  primaryContrastText: "#fff",
  primaryPink: "#dd1385",
  primaryGreen: "#08F27B",
  deeperPink: "#c6157d",

  secondary: "#ffc220",
  secondaryLight: "#fff45b",
  secondaryDark: "#c79200",
  secondaryContrastText: "#000",

  lightest: "#f2f2f2",
  lighter: "#ebebeb",
  light: "#757575",
  dark: "#4a4a4a",
  darker: "#212529",

  greyLine: "#979797",
  bodyBlack: "rgba(0, 0, 0, 0.8)",
  backgroundBlue: "#e1edf9",
  backgroundWhite: "#fff",
  blueGrey: "#667b9b",
  trashRed: "#cb2022",
  blueInk: "#041f41",
  cancelGrey: "#757575",
  walmartBlue: "#0071ce",
  barColor: "#6ebceb",
  barDisabled: "#f1f1f1",
  sideBarBorder: "#e5f1fa",
  helpTextColor: "#28282891",
  incompleteGrey: "#8FA0B8",
  thumbColor: "#6d6e71",
};
