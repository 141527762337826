import _ from "lodash";
import { getSurveys } from "../../services/api";
import { requestSuccess as surveyRequestSuccess } from "../survey";
import { trackAndShowError } from "../dialog";
import { parseJwtToken } from "../../services/commons";
import {
  MY_FEEDBACK,
  SHARED_WITH_ME,
  SURVEY_SORT_BY_NAME,
  SURVEY_SORY_BY_LAST_UPDATED,
  AUDIENCE_ALL,
  AUDIENCE_ASSOCIATE,
  AUDIENCE_CUSTOMER,
  OPEN_SURVEYS,
  CLOSED_SURVEYS,
  INACTIVE_SURVEYS,
  INTRANSLATION_SURVEYS,
} from "../../components/constants";

const ADD_TOAST_MESSAGE = "surveyList/ADD_TOAST_MESSAGE";
const REQUEST = "surveyList/REQUEST";
const REQUEST_SUCCESS = "surveyList/REQUEST_SUCCESS";
const REQUEST_SERIES_COMPLETE = "surveyList/REQUEST_SERIES_COMPLETE";
const REMOVE = "surveyList/REMOVE";
const ADD = "surveyList/ADD";
const FILTER = "surveyList/FILTER";
const CLEAR_FILTERS = "surveyList/CLEAR_FILTERs";
const CHANGE_CATEGORY = "surveyList/CHANGE_CATEGORY";
const CLEAR_REDIRECT = "surveyList/CLEAR_REDIRECT";
const PUBLISH_SURVEY = "surveyList/PUBLISH_SURVEY";
const UNPUBLISH_SURVEY = "surveyList/UNPUBLISH_SURVEY";
const UPDATE_HIDDEN_QUESTION_STATUS =
  "surveyList/UPDATE_HIDDEN_QUESTION_STATUS";
const SET_PAGE_NO = "surveyList/SET_PAGE_NO";
const UPDATE_SORT_COLUMN = "surveyList/UPDATE_SORT_COLUMN";
const UPDATE_PUBLISHABLE_STATUS = "surveyList/UPDATE_PUBLISHABLE_STATUS";
const UPDATE_RESPONSE_COUNT = "surveyList/UPDATE_RESPONSE_COUNT";
const SET_SEARCH_STRING = "surveyList/SET_SEARCH_STRING";
export const FILTER_BY_AUDIENCE = "surveyList/FILTER_BY_AUDIENCE";
export const SORT_BY_LAST_UPDATED = "surveyList/SORT_BY_LAST_UPDATED";
export const SORT_BY_NAME = "surveyList/SORT_BY_NAME";
export const REMOVE_ALL_LIST_FILTERS = "surveyList/REMOVE_ALL_LIST_FILTERS";
export const MY_FEEDBACK_LIST = "surveyList/MY_FEEDBACK_LIST";
export const FEEDBACK_SHARED_WITH_ME = "surveyList/FEEDBACK_SHARED_WITH_ME";
export const SET_SURVEY_LIST_TYPE = "surveyList/SET_SURVEY_LIST_TYPE";
export const NEXT_PAGE_DATA = "surveyList/NEXT_PAGE_DATA";
export const PAGE_SIZE_CHANGE = "surveyList/PAGE_SIZE_CHANGE";
const PUBLISHED = "published";
const FILTERED = "filtered";
const FILTERED_SURVEYS = "filteredSurveys";

const DRAFTS = "drafts",
  INACTIVE = "inactive";

const defaultRequestBody = {
  page: 1,
  size: 10,
  status: "open",
  type: MY_FEEDBACK,
  sort: [
    {
      attribute: SURVEY_SORY_BY_LAST_UPDATED,
      order: "desc",
    },
  ],
};

const initialState = {
  requestInProgress: false,
  requestSeriesInProgress: false,
  drafts: [],
  published: [],
  inactive: [],
  inTranslation: [],
  totalCount: null,
  filtered: false,
  currentCategory: PUBLISHED,
  currentPage: 1,
  sortInfo: {
    published: {
      sortColumn: 1,
      asc: false,
    },
    drafts: {
      sortColumn: 1,
      asc: false,
    },
    inactive: {
      sortColumn: 1,
      asc: false,
    },
  },
  searchString: "",
  requestBody: defaultRequestBody,
  requestCount: 0,
  toastMessage: "",
};

function dynamicSort(property, secondaryProperty, sortOrder = 1) {
  return (a, b) => {
    let sortResult;
    sortResult = a[property].localeCompare(b[property], undefined, {
      numeric: true,
      sensitivity: "base",
    });
    if (secondaryProperty && sortResult === 0) {
      sortResult = a[secondaryProperty].localeCompare(
        b[secondaryProperty],
        undefined,
        { numeric: true, sensitivity: "base" }
      );
    }
    return sortResult * sortOrder;
  };
}

function findSurveyIndexById(surveys, id) {
  if (surveys) {
    for (let surveyIndex in surveys) {
      if (surveys[surveyIndex].id === id) {
        return surveyIndex;
      }
    }
  }
  return -1;
}

function removeFromFilteredList(filteredSurveys, id) {
  if (filteredSurveys) {
    _.each(filteredSurveys, (v) => {
      if (v) {
        if (v.id === id) {
          let index = filteredSurveys.indexOf(v);
          if (index != -1) {
            filteredSurveys.splice(index, 1);
          }
        }
      }
    });
  }
}

function updateSurveysPageInfo(surveys) {
  if (surveys && surveys.length != 0) {
    let itemCount = 0;
    let pageCount = 1;
    _.each(surveys, (survey) => {
      if (itemCount == 7) {
        pageCount++;
        itemCount = 0;
      }
      survey.page = pageCount;
      itemCount++;
    });
    return pageCount;
  }
  return 0;
}

function updatePagination(state, category, doUpdateCurrentPage) {
  let categoryPageKey = category + "Pages";
  let surveyListKey = category === FILTERED ? FILTERED_SURVEYS : category;
  let pages = updateSurveysPageInfo(state[surveyListKey]);
  if (doUpdateCurrentPage && state.currentPage > pages) {
    state.currentPage--;
  }
  state[categoryPageKey] = pages;
}

function doSorting(state, category, doFilteredSurveysSort) {
  let sortInfo = state.sortInfo;

  let sortColumn = state.sortInfo[category].sortColumn;
  let sortOrder = sortInfo[category]["asc"] === true ? 1 : -1;
  let surveyList = state[category];

  let sorter = dynamicSort("modified", "created", sortOrder);
  if (sortColumn === 0) {
    sorter = dynamicSort("title", "modified", sortOrder);
  }
  surveyList.sort(sorter);
  state[category + "Pages"] = updateSurveysPageInfo(surveyList);

  if (doFilteredSurveysSort && state.filtered === true) {
    let filteredSurveys = state["filteredSurveys"];
    filteredSurveys.sort(sorter);
    updateSurveysPageInfo(filteredSurveys);
  }
}

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST:
      state.requestInProgress = true;
      state.requestSeriesInProgress = true;
      break;
    case REQUEST_SERIES_COMPLETE:
      state.requestSeriesInProgress = false;
      break;
    case REQUEST_SUCCESS:
      return {
        ...state,
        requestInProgress: false,
        totalCount: action.totalCount,
        published: action.published,
        drafts: action.drafts,
        inactive: action.inactive,
        inTranslation: action.inTranslation,
      };
    case REMOVE:
      state.filtered = false;
      state.filteredSurveys = [];
      state[action.category] = removeSurvey(action.id, action.surveyList);
      updatePagination(state, action.category, true);
      break;
    case ADD:
      state.drafts.unshift(action.data);
      state.currentCategory = DRAFTS;
      state.currentPage = state.currentPage === 0 ? 1 : state.currentPage;
      doSorting(state, state.currentCategory, false);
      state.redirect = action.data.id;
      break;
    case FILTER:
      state.filtered = true;
      state.filteredSurveys = action.data;
      state.filteredPages = action.pages;
      state.currentPage = 1;
      break;
    case CLEAR_FILTERS:
      state.filtered = false;
      state.filteredSurveys = [];
      state.filteredPages = 0;
      break;
    case CHANGE_CATEGORY:
      state.currentCategory = action.category;
      break;
    case CLEAR_REDIRECT:
      state.redirect = null;
      break;
    case PUBLISH_SURVEY: {
      let toPublish;
      for (let draft in state.drafts) {
        if (state.drafts[draft].id === action.id) {
          state.drafts[draft].modified = action.modified_on;
          toPublish = state.drafts[draft];
          state.drafts.splice(draft, 1);
          updatePagination(state, DRAFTS, !state.filtered);
          break;
        }
      }
      //if not in draft look for inactive surveys
      if (!toPublish) {
        for (let inactive in state.inactive) {
          if (state.inactive[inactive].id === action.id) {
            state.inactive[inactive].modified = action.modified_on;
            toPublish = state.inactive[inactive];
            state.inactive.splice(inactive, 1);
            updatePagination(state, INACTIVE, !state.filtered);
            break;
          }
        }
      }
      //to avoid undefined val gets inserted
      if (toPublish) {
        state.published.push(toPublish);
        doSorting(state, PUBLISHED, false);
        removeFromFilteredList(state.filteredSurveys, toPublish.id);

        if (state.filtered) {
          updatePagination(state, FILTERED, true);
        }
      }
      break;
    }
    case UNPUBLISH_SURVEY: {
      let toInactive;
      for (let pub in state.published) {
        if (state.published[pub].id === action.id) {
          state.published[pub].modified = action.modified_on;
          toInactive = state.published[pub];
          state.published.splice(pub, 1);

          updatePagination(state, PUBLISHED, !state.filtered);
          break;
        }
      }
      //to avoid undefined val gets inserted
      if (toInactive) {
        state.inactive.push(toInactive);
        doSorting(state, INACTIVE, false);
        removeFromFilteredList(state.filteredSurveys, toInactive.id);

        if (state.filtered) {
          updatePagination(state, FILTERED, true);
        }
      }
      break;
    }
    case UPDATE_HIDDEN_QUESTION_STATUS: {
      let isUpdatedHiddenStatus = false;
      for (let pub in state.published) {
        if (state.published[pub].id === action.id) {
          state.published[pub].modified = action.modified_on;
          state.published[pub].hasHiddenQuestion = action.hasHiddenQuestion;
          isUpdatedHiddenStatus = true;
          break;
        }
      }
      if (!isUpdatedHiddenStatus) {
        for (let inactive in state.inactive) {
          if (state.inactive[inactive].id === action.id) {
            state.inactive[inactive].modified = action.modified_on;
            state.inactive[inactive].hasHiddenQuestion =
              action.hasHiddenQuestion;
            isUpdatedHiddenStatus = true;
            break;
          }
        }
      }

      if (!isUpdatedHiddenStatus) {
        for (let draft in state.drafts) {
          if (state.drafts[draft].id === action.id) {
            state.drafts[draft].modified = action.modified_on;
            state.drafts[draft].hasHiddenQuestion = action.hasHiddenQuestion;
            break;
          }
        }
      }
      break;
    }
    case SET_PAGE_NO:
      state.currentPage = action.pageNo;
      break;
    case UPDATE_SORT_COLUMN:
      state.sortInfo = action.sortInfo;
      doSorting(state, state.currentCategory, true);
      break;
    case UPDATE_PUBLISHABLE_STATUS:
      if (state.drafts && state.drafts.length > 0) {
        let index = findSurveyIndexById(state.drafts, action.surveyId);
        if (index != -1) {
          if (state.drafts[index].is_publishable !== true) {
            state.drafts[index].is_publishable = action.is_publishable;
          }
        }
      } else if (state.inactive && state.inactive.length > 0) {
        let index = findSurveyIndexById(state.inactive, action.surveyId);
        if (index != -1) {
          if (state.inactive[index].is_publishable !== true) {
            state.inactive[index].is_publishable = action.is_publishable;
          }
        }
      }
      break;
    case UPDATE_RESPONSE_COUNT:
      if (action.isPublished === false) {
        let index = findSurveyIndexById(state.drafts, action.surveyId);
        if (index != -1) {
          state.drafts[index].responses = action.responses;
        }
      } else if (action.isPublished === true) {
        let index = findSurveyIndexById(state.published, action.surveyId);
        if (index != -1) {
          state.published[index].responses = action.responses;
        }
      }
      if (state.filtered === true) {
        let index = findSurveyIndexById(state.filteredSurveys, action.surveyId);
        if (index != -1) {
          state.filteredSurveys[index].responses = action.responses;
        }
      }
      break;
    case SET_SEARCH_STRING:
      state.searchString = action.searchString;
      state.requestBody = {
        ...state.requestBody,
        keyword: action.searchString,
      };
      break;
    case FILTER_BY_AUDIENCE: {
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          audience_type: action.payload,
        },
        requestCount: state.requestCount + 1,
      };
    }
    case SORT_BY_LAST_UPDATED:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          sort: [
            {
              attribute: SURVEY_SORY_BY_LAST_UPDATED,
              order: action.payload,
            },
          ],
        },
        requestCount: state.requestCount + 1,
      };
    case SORT_BY_NAME:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          sort: [
            {
              attribute: SURVEY_SORT_BY_NAME,
              order: action.payload,
            },
          ],
        },
        requestCount: state.requestCount + 1,
      };
    case MY_FEEDBACK_LIST:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          page: 1,
          type: MY_FEEDBACK,
        },
        requestCount: state.requestCount + 1,
      };
    case FEEDBACK_SHARED_WITH_ME:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          page: 1,
          type: SHARED_WITH_ME,
        },
        requestCount: state.requestCount + 1,
      };
    case REMOVE_ALL_LIST_FILTERS:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: defaultRequestBody,
        requestCount: state.requestCount + 1,
      };
    case SET_SURVEY_LIST_TYPE:
      return {
        ...state,
        drafts: [],
        published: [],
        inactive: [],
        inTranslation: [],
        requestBody: {
          ...state.requestBody,
          page: 1,
          status: action.payload,
        },
        requestCount: state.requestCount + 1,
      };
    case NEXT_PAGE_DATA:
      return {
        ...state,
        requestBody: {
          ...state.requestBody,
          page: action.payload,
        },
      };
    case PAGE_SIZE_CHANGE:
      return {
        ...state,
        requestBody: {
          ...state.requestBody,
          size: action.payload,
        },
      };
    case ADD_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload || "",
      };
    default:
      break;
  }
  return state;
};

let debouncedRequestAction = _.debounce((callback) => callback(), 500);

export const requestAction = (requestBody) => (dispatch) => {
  dispatch({
    type: REQUEST,
  });

  return getSurveys(requestBody)
    .then((res) => {
      const { message: surveys, totalCount } = res;

      let drafts = [],
        published = [],
        inactive = [],
        inTranslation = [],
        draftPages = 1,
        pubPages = 1,
        inactivePages = 1,
        inTranslationPages = 1;
      surveys.map((survey) => {
        dispatch(surveyRequestSuccess(survey));
        if (survey.status === "open") {
          // if(pubCount >= 7){
          //     pubPages++;
          //     pubCount = 0;
          // }
          // pubCount++;

          published.push({
            audience: survey.audience,
            campaign_enabled: survey.campaign_enabled,
            id: survey.id,
            title: survey.title,
            page: pubPages,
            created: survey.created_on,
            modified: survey.modified_on,
            published: survey.published_on,
            responses: survey.submissions_count,
            is_publishable: survey.is_publishable,
            hasHiddenQuestion: survey.hasHiddenQuestion,
            owner_uid: survey.owner_uid,
            co_owners_uid: survey.co_owners_uid || [],
            collaboraters: survey.collaboraters || [],
            totalNoOfQuestions: survey.totalNoOfQuestions,
            status: survey.status,
            win_access: survey.win_access,
            isSaved: survey.isSaved ? survey.isSaved : false,
          });
        } else if (survey.status === "closed") {
          // if(draftCount >= 7){
          //     draftPages++;
          //     draftCount = 0;
          // }
          // draftCount++;

          drafts.push({
            audience: survey.audience,
            campaign_enabled: survey.campaign_enabled,
            id: survey.id,
            title: survey.title,
            page: draftPages,
            created: survey.created_on,
            modified: survey.modified_on,
            responses: survey.submissions_count,
            is_publishable: survey.is_publishable,
            hasHiddenQuestion: survey.hasHiddenQuestion
              ? survey.hasHiddenQuestion
              : false,
            owner_uid: survey.owner_uid,
            co_owners_uid: survey.co_owners_uid || [],
            collaboraters: survey.collaboraters || [],
            totalNoOfQuestions: survey.totalNoOfQuestions,
            status: survey.status,
            win_access: survey.win_access,
            isSaved: survey.isSaved ? survey.isSaved : false,
          });
        } else if (survey.status === "inTranslation") {
          inTranslation.push({
            audience: survey.audience,
            campaign_enabled: survey.campaign_enabled,
            id: survey.id,
            title: survey.title,
            page: inTranslationPages,
            created: survey.created_on,
            modified: survey.modified_on,
            responses: survey.submissions_count,
            is_publishable: survey.is_publishable,
            hasHiddenQuestion: survey.hasHiddenQuestion
              ? survey.hasHiddenQuestion
              : false,
            owner_uid: survey.owner_uid,
            co_owners_uid: survey.co_owners_uid || [],
            collaboraters: survey.collaboraters || [],
            totalNoOfQuestions: survey.totalNoOfQuestions,
            status: survey.status,
            win_access: survey.win_access,
            isSaved: survey.isSaved ? survey.isSaved : false,
          });
        } else {
          // if(inactiveCount >= 7){
          //     inactivePages++;
          //     inactiveCount = 0;
          // }
          // inactiveCount++;

          inactive.push({
            audience: survey.audience,
            campaign_enabled: survey.campaign_enabled,
            id: survey.id,
            title: survey.title,
            page: inactivePages,
            created: survey.created_on,
            modified: survey.modified_on,
            responses: survey.submissions_count,
            is_publishable: survey.is_publishable,
            isMultilingual: survey.isMultilingual,
            hasHiddenQuestion: survey.hasHiddenQuestion
              ? survey.hasHiddenQuestion
              : false,
            owner_uid: survey.owner_uid,
            co_owners_uid: survey.co_owners_uid || [],
            collaboraters: survey.collaboraters || [],
            totalNoOfQuestions: survey.totalNoOfQuestions,
            status: survey.status,
            win_access: survey.win_access,
            isSaved: survey.isSaved ? survey.isSaved : false,
          });
        }
      });

      dispatch({
        type: REQUEST_SUCCESS,
        drafts,
        published,
        inactive,
        inTranslation,
        totalCount,
        // draftPages,
        // pubPages,
        // inactivePages,
      });
      dispatch({
        type: REQUEST_SERIES_COMPLETE,
      });
    })
    .catch((response) => {
      const trackMessage = "Error in getting list of surveys";
      dispatch(
        trackAndShowError("ERROR_FETCH_SURVEY_LIST", trackMessage, response)
      );
    });
};

export const request = (requestBody) => (dispatch) =>
  debouncedRequestAction(() => requestAction(requestBody)(dispatch));

export const requestSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_SUCCESS,
      data,
    });
  };
};

const removeSurvey = (id, surveys) => {
  for (let survey in surveys) {
    if (surveys[survey].id === id) {
      surveys.splice(survey, 1);
    }
  }

  return surveys;
};

export const remove = (id, surveyList, category) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE,
      id,
      surveyList,
      category,
    });
  };
};

export const add = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD,
      data,
    });
  };
};

export const clearRedirect = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REDIRECT,
    });
  };
};

export const changeCategory = (category) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_CATEGORY,
      category,
    });
  };
};

export const search = (string, surveyList, category) => {
  return (dispatch) => {
    let data = [];

    for (let index in surveyList) {
      if (
        surveyList[index].title
          .toString()
          .toLowerCase()
          .indexOf(string.toLowerCase()) >= 0
      ) {
        data.push({
          id: surveyList[index].id,
          title: surveyList[index].title.toString(),
          created: surveyList[index].created,
          modified: surveyList[index].modified,
          responses: surveyList[index].responses,
          is_publishable: surveyList[index].is_publishable,
        });
      }
    }

    let pages = updateSurveysPageInfo(data);
    dispatch({
      type: FILTER,
      data,
      pages,
      category,
    });
  };
};

export const clearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_FILTERS,
    });
  };
};

export const publishOrUnpublish = (id, status, modified_on, completed = []) => {
  return (dispatch) => {
    if (status === "open") {
      dispatch({
        type: PUBLISH_SURVEY,
        id,
        modified_on: modified_on,
        completed: completed,
      });
    } else {
      dispatch({
        type: UNPUBLISH_SURVEY,
        id,
        modified_on: modified_on,
        completed: completed,
      });
    }
  };
};

export const updateHiddenQuestionStatus = (
  id,
  hasHiddenQuestion,
  modified_on
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_HIDDEN_QUESTION_STATUS,
      id,
      hasHiddenQuestion: hasHiddenQuestion,
      modified_on: modified_on,
    });
  };
};

export const setPageNo = (pageNo) => {
  return (dispatch) => {
    dispatch({
      type: SET_PAGE_NO,
      pageNo,
    });
  };
};

export const updateSortColumn = (sortInfo, category, sortColumn) => {
  return (dispatch) => {
    let sortInfoKey = category;
    //if the previous sortColumn and current sortColumn is same then flip the asc value else set true
    sortInfo[sortInfoKey]["asc"] =
      sortInfo[sortInfoKey]["sortColumn"] === sortColumn
        ? !sortInfo[sortInfoKey]["asc"]
        : true;
    sortInfo[sortInfoKey]["sortColumn"] = sortColumn;
    dispatch({
      type: UPDATE_SORT_COLUMN,
      sortInfo,
    });
  };
};

export const updatePublishableStatus = (surveyId, is_publishable) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PUBLISHABLE_STATUS,
      surveyId,
      is_publishable,
    });
  };
};

export const updateSurveySubmissionCount = (
  surveyId,
  isPublished,
  responses
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_RESPONSE_COUNT,
      surveyId,
      isPublished,
      responses,
    });
  };
};

export const setSearchString = (searchString) => {
  return (dispatch) =>
    dispatch({
      type: SET_SEARCH_STRING,
      searchString,
    });
};

export const setFilterByAudience = (audienceType) => (disptach) => {
  switch (audienceType) {
    case AUDIENCE_ALL:
      disptach({ type: FILTER_BY_AUDIENCE, payload: "" });
      break;
    case AUDIENCE_ASSOCIATE:
      disptach({
        type: FILTER_BY_AUDIENCE,
        payload: `${AUDIENCE_ASSOCIATE}`.toLowerCase(),
      });
      break;
    case AUDIENCE_CUSTOMER:
      disptach({
        type: FILTER_BY_AUDIENCE,
        payload: `${AUDIENCE_CUSTOMER}`.toLowerCase(),
      });
      break;
    default:
      break;
  }
};

const getUserDetails = () => {
  const { userId, fullName } = parseJwtToken();
  return {
    userId,
    fullName,
  };
};

export const setSortByLastUpdated = (value) => (dispatch) => {
  dispatch({ type: SORT_BY_LAST_UPDATED, payload: value });
};

export const setSortByName = (value) => (dispatch) => {
  dispatch({ type: SORT_BY_NAME, payload: value });
};

export const removeAllListFilters = () => (dispatch) => {
  dispatch({ type: REMOVE_ALL_LIST_FILTERS });
};

export const showMyFeedback = () => (dispatch) => {
  const user = getUserDetails();
  dispatch({ type: MY_FEEDBACK_LIST, payload: user });
};

export const showSharedFeedback = () => (dispatch) => {
  const user = getUserDetails();
  dispatch({ type: FEEDBACK_SHARED_WITH_ME, payload: user });
};

export const getNextPageData = (pageNumber) => (dispatch) => {
  dispatch({ type: NEXT_PAGE_DATA, payload: pageNumber + 1 });
};

export const changePageSize = (rowSize) => (dispatch) => {
  dispatch({ type: PAGE_SIZE_CHANGE, payload: rowSize });
};

export const setFeedbackCurrentCategory = (value) => (dispatch) => {
  switch (value) {
    case OPEN_SURVEYS:
      dispatch({ type: SET_SURVEY_LIST_TYPE, payload: OPEN_SURVEYS });
      break;
    case CLOSED_SURVEYS:
      dispatch({ type: SET_SURVEY_LIST_TYPE, payload: CLOSED_SURVEYS });
      break;
    case INACTIVE_SURVEYS:
      dispatch({ type: SET_SURVEY_LIST_TYPE, payload: INACTIVE_SURVEYS });
      break;
    case INTRANSLATION_SURVEYS:
      dispatch({ type: SET_SURVEY_LIST_TYPE, payload: INTRANSLATION_SURVEYS });
      break;
    default:
      break;
  }
};

export const addToastMessage = (message) => (dispatch) => {
  dispatch({ type: ADD_TOAST_MESSAGE, payload: message });
};

export const actions = {
  surveyListRequest: request,
  surveyListRequestSuccess: requestSuccess,
  surveyListRemove: remove,
  surveyListAdd: add,
  surveyListSearch: search,
  surveyListClearFilters: clearFilters,
  surveyListChangeCategory: changeCategory,
  surveyListClearRedirect: clearRedirect,
  surveyListPublishOrUnpublish: publishOrUnpublish,
  surveyListAudience: setFilterByAudience,
  surveyListLastUpdated: setSortByLastUpdated,
  surveyListSortByName: setSortByName,
  surveyListRemoveFilters: removeAllListFilters,
  surveyListShowMyFeedback: showMyFeedback,
  surveyListShowSharedFeedback: showSharedFeedback,
  surveyListNextPageData: getNextPageData,
  surveyListPageSizeChange: changePageSize,
  setFeedbackCurrentCategory,
  setPageNumber: setPageNo,
  updateSortColumn: updateSortColumn,
  updatePublishableStatus: updatePublishableStatus,
  updateSurveySubmissionCount: updateSurveySubmissionCount,
  setSearchString: setSearchString,
  updateHiddenQuestionStatus: updateHiddenQuestionStatus,
  surveyListAddToastMessage: addToastMessage,
};
