import cookie from "react-cookies";
import i18next from "../../locales";
import {
  HIDDEN_VARIABLE,
  QUESTION_TYPES,
  NONE,
  OTHER,
  CONDITIONS,
  MEDIAUPLOAD,
} from "../../components/constants";
import { calculateEquation, validate } from "../../utils/equationVarUtils";
import {
  isBlockEmpty,
  isDeactivated,
} from "../../services/blockUtils/blockUtils";
import { EQUATION } from "../constants";
import getRandomNumber from "../../utils/randomNumber";
import _ from "lodash";

const t = i18next.t;

const SeverityLevel = {
  Verbose: 0,
  Information: 1,
  Warning: 2,
  Error: 3,
  Critical: 4,
};

const endpoints = {
  default: {
    label: t("common:chooseEndpoints"),
    left: "",
    right: "",
    disabled: true,
  },
  agree: {
    left: t("common:stronglyDisagree"),
    right: t("common:stronglyAgree"),
  },
  likely: { left: "common:notAtAllLikely", right: "common:extremelyLikely" },
  satisfied: {
    left: t("common:notAtAllSatisfied"),
    right: t("common:verySatisfied"),
  },
  poor_excellent: { left: t("common:poor"), right: t("common:excellent") },
  worse_better: { left: t("common:muchWorse"), right: t("common:muchBetter") },
  custom: { label: t("common:customEndpoints"), left: "", right: "" },
};

const rankingEndpoints = {
  default: {
    label: "common:chooseEndpoints",
    left: "",
    right: "",
    disabled: true,
  },
  worse_better: { left: "common:best", right: "common:worst" },
  likely: { left: "common:most", right: "common:least" },
  custom: { label: "common:customEndpoints", left: "", right: "" },
};

export const NPS_DEFAULT_ENDPOINT = "likely";

export const npsRecommendationsTo = [
  {
    label: "common:npsRecommendations.friendOrColleague",
    value: "common:npsRecommendations.friendOrColleague",
    default: true,
  },
  {
    label: "common:npsRecommendations.colleague",
    value: "common:npsRecommendations.colleague",
  },
  {
    label: "common:npsRecommendations.walmart",
    value: "common:npsRecommendations.walmart",
  },
  {
    label: "common:npsRecommendations.placeToWork",
    value: "common:npsRecommendations.placeToWork",
  },
];

export const getDefaultRecommendationsTo = () => {
  return npsRecommendationsTo.filter((v) => v.default === true)[0].value;
};

export const getEndpoint = (type, endpointName) => {
  return endpoints[type][endpointName];
};
export const getEndpointsOptions = () => {
  let options = [];
  for (let endpoint in endpoints) {
    let option = {};
    option.label = endpoints[endpoint].label
      ? endpoints[endpoint].label
      : endpoints[endpoint].left + " - " + endpoints[endpoint].right;
    option.value = endpoint;
    option.description = "";
    option.disabled = endpoints[endpoint].disabled ? true : false;
    options.push(option);
  }
  return options;
};

export const getRankingEndpoint = (type, endpointName) => {
  return rankingEndpoints[type][endpointName];
};

export const getRankingEndpointsOptions = () => {
  let options = [];
  for (let endpoint in rankingEndpoints) {
    let option = {};
    option.label = rankingEndpoints[endpoint].label;
    // ? rankingEndpoints[endpoint].label
    // : rankingEndpoints[endpoint].left +
    //   " - " +
    // rankingEndpoints[endpoint].right;
    option.left = rankingEndpoints[endpoint].left;
    option.right = rankingEndpoints[endpoint].right;
    option.value = endpoint;
    option.description = "";
    option.disabled = rankingEndpoints[endpoint].disabled ? true : false;
    options.push(option);
  }
  return options;
};

export const isEmpty = (val) => {
  return val ? val.trimLeft().length === 0 : true;
};

export const validateQuestion = (q, questions = []) => {
  q.isLabelEmpty === undefined && (q.isLabelEmpty = false);
  q.isLabelUnique === undefined && (q.isLabelUnique = true);
  let questionValidity = true;
  if (
    isEmpty(q.question) &&
    q.type !== "variable" &&
    q.type !== EQUATION &&
    q.type !== QUESTION_TYPES.BLOCK &&
    q.type !== QUESTION_TYPES.SOURCE_FILE_VARIABLE
  ) {
    questionValidity = false;
  } else if (q.isLabelEmpty) {
    questionValidity = false;
  } else if (!q.isLabelUnique) {
    questionValidity = false;
  } else if (q.isDescriptionEnabled === true && isEmpty(q.help)) {
    questionValidity = false;
  } else if (
    q.type == "hidden" &&
    (isEmpty(q.jsonKey) || !isValidJsonKey(q.jsonKey))
  ) {
    questionValidity = false;
  } else if (q.type === "variable") {
    q?.choices?.forEach((item) => {
      if (item?.isError === true) {
        questionValidity = false;
      } else {
        for (let choice in q.choices) {
          if (isEmpty("" + q.choices[choice]?.label)) {
            questionValidity = false;
            break;
          }
        }
      }
    });
  } else if (
    q.isEndpointEnabled === true &&
    (q.endpoint === "default" ||
      isEmpty(q.endpointLeft) ||
      isEmpty(q.endpointRight))
  ) {
    questionValidity = false;
  } else if (
    q.type === "range" &&
    q.choices?.length < 2 &&
    q.max !== 10 &&
    q.max !== 5
  ) {
    questionValidity = false;
  } else if (
    q.type === "rangeNumeric" &&
    (isEmpty(q.endpointLeft) || isEmpty(q.endpointRight) || !q.scale)
  ) {
    questionValidity = false;
  } else if (
    q.type === "radios" ||
    q.type === "checkboxes" ||
    q.type === "select"
  ) {
    let choicesType = q.choicesType;
    if (choicesType != "dynamic") {
      if (
        q.choices.length < 2 &&
        (!q.importedSourceVariables || q.importedSourceVariables?.length === 0)
      ) {
        questionValidity = false;
      } else {
        for (let choice in q.choices) {
          if (isEmpty(q.choices[choice].label)) {
            questionValidity = false;
            break;
          }
        }
      }
    } else {
      questionValidity = false;
      // q.criteria && q.criteria.clauses;
      // if (questionValidity) {
      //   if (
      //     q.criteria.source &&
      //     q.criteria.source != "-1" &&
      //     q.criteria.sourceColumn &&
      //     q.criteria.sourceColumn != "-1"
      //   ) {
      //     for (let index = 0; index < q.criteria.clauses.length; index++) {
      //       const clause = q.criteria.clauses[index];
      //       if (
      //         !clause.column ||
      //         clause.column === "-1" ||
      //         !clause.value ||
      //         clause.value === "-1" ||
      //         q.criteria.sourceColumn === clause.column
      //       ) {
      //         questionValidity = false;
      //         break;
      //       }
      //     }
      //   } else {
      //     questionValidity = false;
      //   }
      // }
    }
  } else if (q.type === "matrix") {
    if (!q.matrixRow.length || !q.matrixColumn.length) {
      questionValidity = false;
    } else {
      for (let row in q.matrixRow) {
        if (isEmpty(q.matrixRow[row].label)) {
          questionValidity = false;
          break;
        }
      }
      for (let column in q.matrixColumn) {
        if (isEmpty(q.matrixColumn[column].label)) {
          questionValidity = false;
          break;
        }
      }
    }
  } else if (q.type === "ranking") {
    if (
      q.rankingOptions.length < 2 &&
      (!q.importedSourceVariables || q.importedSourceVariables?.length === 0)
    ) {
      questionValidity = false;
    } else if (isEmpty(q.endpointLeft) || isEmpty(q.endpointRight)) {
      questionValidity = false;
    } else {
      for (let rankingOption in q.rankingOptions) {
        if (isEmpty(q.rankingOptions[rankingOption].label)) {
          questionValidity = false;
          break;
        }
      }
    }
  } else if (q.type === "contact_form") {
    if (q.contactForms.length < 1) {
      questionValidity = false;
    }
  } else if (q.type === "image") {
    if (q.choices.length < 2) {
      questionValidity = false;
    } else {
      for (let choice in q.choices) {
        if (
          isEmpty(q.choices[choice].label) ||
          isEmpty(q.choices[choice].imageUrl)
        ) {
          questionValidity = false;
          break;
        }
      }
    }
  } else if (q.type === "number") {
    if (
      q.range.min == undefined ||
      q.range.min === "" ||
      q.range.max === undefined ||
      q.range.max === "" ||
      Number(q.range.min) >= Number(q.range.max) ||
      q.stepSize == undefined ||
      q.stepSize === "" ||
      q.stepSize <= 0
    ) {
      questionValidity = false;
    }
  } else if (q.type === "content") {
    if (
      (q.videoLink == undefined || q.videoLink === "") &&
      (q.videoUrl == undefined || q.videoUrl === "") &&
      (q.imageUrl == undefined || q.imageUrl === "")
    ) {
      questionValidity = false;
    }
  } else if (q.type === QUESTION_TYPES.BLOCK && isBlockEmpty(q.id, questions)) {
    questionValidity = false;
  } else if (q.type === EQUATION && validate(q.equations, questions)) {
    questionValidity = false;
  } else if (
    q.type === MEDIAUPLOAD &&
    (q.checkedFileTypes == undefined ||
      q.checkedFileTypes.length == 0 ||
      q.maxFile == undefined ||
      q.maxFile == 0)
  ) {
    questionValidity = false;
  } else if (
    q.type === QUESTION_TYPES.SOURCE_FILE_VARIABLE &&
    (!q.sourceInfo || q.sourceInfoMissing)
  ) {
    questionValidity = false;
  }
  return questionValidity;
};

export const isAllQuestionsValid = (questions) => {
  let allQuestionsValid = true,
    hasNonHiddenQuestion = false,
    jsonKeys = [],
    hasUniqueJsonKeys = true;
  let invalidQuestions = [];
  let isSurveyValid = true;

  // check length validation on only active questions
  const activeQuestions = questions?.filter(
    (q) => !isDeactivated(q.id, questions)
  );
  if (activeQuestions && activeQuestions.length > 0) {
    const contentQuestion = questions.filter((q) => q.type === "content");
    const mediaQuestions = activeQuestions.filter(
      (q) => q.type === MEDIAUPLOAD
    );
    let totalMaxFileLength = 0,
      totalFileSizeLength = 0,
      i;
    for (i = 0; i < mediaQuestions.length; i++) {
      if (
        mediaQuestions[i].type === MEDIAUPLOAD &&
        mediaQuestions[i].maxFile !== "undefined" &&
        mediaQuestions[i].fileSizeType !== "undefined"
      ) {
        totalMaxFileLength += parseInt(mediaQuestions[i].maxFile);
        totalFileSizeLength += parseInt(mediaQuestions[i].fileSizeType);
      }
    }
    if (questions.length === contentQuestion.length) {
      allQuestionsValid = false;
    } else if (contentQuestion && contentQuestion.length > 5) {
      allQuestionsValid = false;
    }
    if (totalMaxFileLength > 10) {
      allQuestionsValid = false;
      isSurveyValid = false;
    }
    if (totalFileSizeLength > 10) {
      allQuestionsValid = false;
      isSurveyValid = false;
    }
    if (mediaQuestions && mediaQuestions.length > 3) {
      allQuestionsValid = false;
      isSurveyValid = false;
    }

    for (let i in questions) {
      if (!validateQuestion(questions[i], questions)) {
        allQuestionsValid = false;
        invalidQuestions = [...invalidQuestions, questions[i]];
      }
      if (
        questions[i].type !== "hidden" &&
        !questions[i].formattingType &&
        questions[i].type !== "variable" &&
        questions[i].type !== EQUATION &&
        questions[i].type !== QUESTION_TYPES.SOURCE_FILE_VARIABLE
      ) {
        hasNonHiddenQuestion = true;
      }
      if (questions[i].type == "hidden") {
        if (jsonKeys.indexOf(questions[i].jsonKey) >= 0) {
          hasUniqueJsonKeys = false;
        } else {
          jsonKeys.push(questions[i].jsonKey);
        }
      }
      if (questions[i].type === QUESTION_TYPES.BLOCK) {
        const blockQuestions = questions.filter(
          (q) => q.parentId === questions[i].id
        );
        // block should not be empty
        if (blockQuestions.length === 0) {
          allQuestionsValid = false;
        }
      }
      if (questions[i].type === QUESTION_TYPES.TERMINATE) {
        // terminate question should not have empty condtions
        if (questions[i][CONDITIONS]?.terminateConditions?.length < 1) {
          allQuestionsValid = false;
        }
      }
    }
  } else {
    allQuestionsValid = false;
  }
  return {
    isAllQuestionsValid:
      allQuestionsValid && hasNonHiddenQuestion && hasUniqueJsonKeys,
    invalidQuestions: invalidQuestions,
    isSurveyValid: isSurveyValid,
  };
};

export const getValidationErrorMessage = (q) => {
  let questionValidityMessage = "";
  if (isEmpty(q.question)) {
    questionValidityMessage = t("common:validationErrors.questionRequired");
  } else if (
    q.type == "hidden" &&
    (isEmpty(q.jsonKey) || !isValidJsonKey(q.jsonKey))
  ) {
    questionValidityMessage = t("common:validationErrors.notValidJSONKey");
  } else if (
    q.isEndpointEnabled === true &&
    (q.endpoint === "default" ||
      isEmpty(q.endpointLeft) ||
      isEmpty(q.endpointRight))
  ) {
    questionValidityMessage = t(
      "common:validationErrors.customEndpointsMissing"
    );
  } else if (q.type === "range" && q.choices?.length < 2 && q.max !== 10) {
    questionValidityMessage = t(
      "common:validationErrors.minimumOfTwoOptionsRequired"
    );
  } else if (
    q.type === "rangeNumeric" &&
    (isEmpty(q.endpointLeft) || isEmpty(q.endpointRight) || !q.scale)
  ) {
    questionValidityMessage = "Please fill out all the fields";
  } else if (
    q.type === "radios" ||
    q.type === "checkboxes" ||
    q.type === "select"
  ) {
    let choicesType = q.choicesType;
    if (choicesType != "dynamic") {
      if (q.choices.length < 2) {
        questionValidityMessage = t(
          "common:validationErrors.minimumOfTwoOptionsRequired"
        );
      } else if (q.choices.find((item) => item.label == "")) {
        questionValidityMessage = t(
          "common:validationErrors.optionsCanNotBeEmpty"
        );
      }
    } else {
      if (q.criteria && q.criteria.clauses) {
        if (
          q.criteria.source &&
          q.criteria.source != "-1" &&
          q.criteria.sourceColumn &&
          q.criteria.sourceColumn != "-1"
        ) {
          for (let index = 0; index < q.criteria.clauses.length; index++) {
            const clause = q.criteria.clauses[index];
            if (
              !clause.column ||
              clause.column === "-1" ||
              !clause.value ||
              clause.value === "-1" ||
              q.criteria.sourceColumn === clause.column
            ) {
              questionValidityMessage = t(
                "common:validationErrors.notAValidCriteriaForDynamicOptions"
              );
              break;
            }
          }
        } else {
          questionValidityMessage = t(
            "common:validationErrors.notAValidCriteriaForDynamicOptions"
          );
        }
      }
    }
  } else if (q.type === "matrix") {
    if (!q.matrixRow.length || !q.matrixColumn.length) {
      questionValidityMessage = t(
        "common:validationErrors.minimumOfOneRowColumnRequired"
      );
    } else if (q.matrixRow.find((item) => item.label == "")) {
      questionValidityMessage = t(
        "common:validationErrors.rowItemsCantBeEmpty"
      );
    } else if (q.matrixColumn.find((item) => item.label == "")) {
      questionValidityMessage = t(
        "common:validationErrors.columnItemsCantBeEmpty"
      );
    }
  } else if (q.type === "ranking") {
    if (q.rankingOptions.length < 2) {
      questionValidityMessage = t(
        "common:validationErrors.minimumOfTwoRankingItemsRequired"
      );
    } else if (q.rankingOptions.find((item) => item.label == "")) {
      questionValidityMessage = t(
        "common:validationErrors.rankingItemsCantBeEmpty"
      );
    }
  }

  return questionValidityMessage;
};

export const hasUniqueJsonKeys = (questions) => {
  let hasUniqueJsonKeys = true,
    jsonKeys = [];
  questions.forEach((q) => {
    if (q.type === "hidden") {
      if (jsonKeys.indexOf(q.jsonKey) < 0) {
        jsonKeys.push(q.jsonKey);
      } else {
        hasUniqueJsonKeys = false;
      }
    }
  });
  return hasUniqueJsonKeys;
};

export const formatNumber = (num) => {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
};

export const sorter = (property, sortOrder = 1) => {
  return (a, b) => {
    let v1 = a[property].toString();
    let v2 = b[property].toString();
    let sortResult = v1.localeCompare(v2, undefined, {
      numeric: true,
      sensitivity: "base",
    });
    return sortResult * sortOrder;
  };
};

export function trackTrace(eventName, message, payload) {
  let eventProperties = {
    eventName: eventName,
    payload: payload,
  };
  window.appInsights.trackTrace(message, eventProperties, SeverityLevel.Error);
  return;
}

export function parseJwtToken(token) {
  try {
    if (!token) {
      token = cookie.load("accessToken");
    }
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return "";
  }
}

export const isCurrentUserTranslator = (isMultilingual, translators) => {
  const payload = parseJwtToken();
  return (
    isMultilingual &&
    translators &&
    translators.some(
      (translator) =>
        translator.email?.toLowerCase() === payload?.userid?.toLowerCase() ||
        translator.email?.toLowerCase() === payload?.emailId?.toLowerCase()
    )
  );
};

export function isValidEmail(mail) {
  let emailRegex =
    /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.)?(\w{2,3})$/;
  return emailRegex.test(mail) ? true : false;
}

export function isValidADGroupName(groupName) {
  if (groupName.length >= 3 && groupName.length <= 64) {
    // eslint-disable-next-line no-useless-escape
    let adGroupNameRegex = /^([_a-zA-Z0-9]+([\.\s-]?[_a-zA-Z0-9]+)*)$/;
    return !groupName.startsWith("jet-") && adGroupNameRegex.test(groupName)
      ? true
      : false;
  } else {
    return false;
  }
}

export function validateCoownerValue(value) {
  return isValidEmail(value);
}

export function validateViewerValue(value) {
  return isValidADGroupName(value) || isValidEmail(value);
}

export function isValidJsonKey(value) {
  let jsonRegex = /(^[a-zA-Z0-9_.+-]+)$/;
  if (
    value &&
    value.length >= 3 &&
    value.length <= 10 &&
    jsonRegex.test(value)
  ) {
    return true;
  }
  return false;
}

export const UV_SCREENS = {
  INITIAL: "initial",
  NPS: "nps",
  IDEA: "idea",
  ERROR: "error",
  NPS_SUCCESS: "npsSuccess",
  IDEA_SUCCESS: "ideaSuccess",
};

export function trackPageView(viewName) {
  window.appInsights.trackPageView(viewName);
  return;
}

export function trackEvent(eventName) {
  window.appInsights.trackEvent(eventName);
  return;
}

export function getDataSourceWithIdentifier(dataSources, dataSourceIdentifier) {
  const filteredList = dataSources.filter((source) => {
    return source.identifier === dataSourceIdentifier;
  });
  return filteredList && filteredList.length > 0 ? filteredList[0] : undefined;
}

export function getOptionVal(answer, options, max) {
  if (options && max != 10 && max != 5) return options[answer - 1];
  return `${answer}`;
}

export function getConditiontags(condtn, answer, questions, answers) {
  switch (condtn.questionType) {
    case "webform_rating":
      if (answer == "5") {
        return "Thumbs Up";
      } else if (answer == "1") {
        return "Thumbs Down";
      }
      return "";
    case "faces":
      switch (`${answer}`) {
        case "1":
          return "Very Dissatisfied";
        case "2":
          return "Dissatisfied";
        case "3":
          return "Just Ok";
        case "4":
          return "Satisfied";
        case "5":
          return "Very Satisfied";
      }
      break;
    case "range": {
      const que = questions.find((data) => data.id === condtn.questionId);
      return getOptionVal(answer, que.optionsList, que.max);
    }
    case "rangeNumeric":
    case "number":
    case "textfield":
    case "textarea":
    case "NPS":
    case "radios":
      return !checkIsEmpty(answer) ? `${answer}` : "";
    case "datetime":
      if (answer.date) {
        let dateVal = answer.date;
        if (answer.time) {
          return dateVal + " & " + answer.time;
        }
        return dateVal;
      } else {
        return answer.time;
      }
    case EQUATION: {
      const response = calculateEquation(
        questions.find((data) => data.id === condtn.questionId).equations,
        answers,
        false
      );
      try {
        let result = Function("return " + response)();
        if (`${result}`.includes("Infinity")) {
          result = "error";
        }
        return `${result || 0}`;
      } catch (e) {
        return "";
      }
    }
    default:
      return "";
  }
}

export const checkIsEmpty = (val) => {
  return val === "" || (!val && val !== 0);
};

export function fetchChoicesFromPrevResponses(
  question,
  answers,
  questions,
  options
) {
  question.choices.map((data) => {
    if (data.label === "Response from" && data.source) {
      const label = getConditiontags(
        {
          questionId: data?.source?.parentId,
          questionType: data?.source?.parentType,
        },
        answers[data?.source?.parentId],
        questions,
        answers
      );
      options.push({ ...data, label, value: `Value_${label}` });
    } else {
      options.push(data);
    }
  });
  return options;
}

export function getStaticOptions(question, answers, questions) {
  const haveDynamicCVOption = question.choices.filter((data) => {
    return data.label === "Response from";
  });
  if (haveDynamicCVOption && haveDynamicCVOption.length > 0) {
    const data = fetchChoicesFromPrevResponses(
      question,
      answers,
      questions,
      []
    );

    return filterEmptyAndDuplicateChoices(data);
    // return Array.prototype.concat.apply([], data); // flatten recursive array
  }
  return filterEmptyAndDuplicateChoices(question.choices);
}

export const filterEmptyAndDuplicateChoices = (data) => {
  return data.filter(
    (value, index, array) =>
      !checkIsEmpty(value.label) &&
      array.findIndex((val) => val.label === value.label) === index
  );
};

export function getChoices(survey, question, answers, questions) {
  if (["select", "radios", "checkboxes"].includes(question.type)) {
    if (question.choicesType !== "dynamic") {
      return getStaticOptions(question, answers, questions);
    } else {
      return getDynamicChoices(survey, question, answers);
    }
  }
  if (question.type === "range" && question.optionsList) {
    return question.optionsList.map((item, index) => ({
      label: item,
      value: index + 1,
    }));
  } else {
    return [];
  }
}

export function getRankingOptions(question, answers, questions) {
  const haveDynamicCVOption = question.rankingOptions.filter((data) => {
    return data.label === "Response from";
  });
  if (haveDynamicCVOption && haveDynamicCVOption.length > 0) {
    const data = fetchChoicesFromPrevResponses(
      { ...question, choices: question.rankingOptions },
      answers,
      questions,
      []
    );
    return data.filter(
      (value, index, array) =>
        array.findIndex((val) => val.label === value.label) === index
    );
    // return Array.prototype.concat.apply([], data); // flatten recursive array
  }
  return question.rankingOptions;
}

export function getDynamicChoices(survey, question, answers) {
  let choices = [];
  if (
    survey &&
    survey.thirdPartySettings &&
    survey.thirdPartySettings.dataSources
  ) {
    let dataSource = getDataSourceWithIdentifier(
      survey.thirdPartySettings.dataSources,
      question.criteria.source
    );

    if (dataSource) {
      let columnIndex = dataSource.data[0].indexOf(
        question.criteria.sourceColumn
      );

      if (columnIndex != -1) {
        let data = dataSource.data;
        for (let i = 1; i < data.length; i++) {
          let isValid = true;
          for (
            var index = 0;
            index < question.criteria.clauses.length;
            index++
          ) {
            const clause = question.criteria.clauses[index];
            const clauseColumnIndex = dataSource.data[0].indexOf(clause.column);
            let isValueMatched = data[i][clauseColumnIndex] === clause.value;
            if (clause.valueSource !== "datasource") {
              const answer = answers[clause.value];
              if (answer instanceof Array) {
                isValueMatched = answer.includes(
                  data[i][clauseColumnIndex]?.trim()?.toString()
                );
              } else {
                isValueMatched = data[i][clauseColumnIndex]?.trim() === answer;
              }
            }
            if (clause.modifier === "where" || clause.modifier === "and") {
              isValid = isValid && isValueMatched;
            } else {
              isValid = isValid || isValueMatched;
            }
          }
          if (
            isValid &&
            data[i][columnIndex] &&
            data[i][columnIndex].trim() != ""
          ) {
            choices.push(data[i][columnIndex]);
          }
        }
      }
    }
  }
  choices =
    choices.length > 0 ? [...new Set(choices.map((x) => x.trim()))] : [];
  return choices.map((x) => (x = { label: x.trim(), value: x.trim() }));
}

export const extractContent = (s) => {
  const span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const removeIfExists = (arr, value) => {
  let index = arr.indexOf(value);
  if (index !== -1) {
    arr.splice(index, 1);
  }
};

export const addIfNotExists = (arr, value) => {
  let index = arr.indexOf(value);
  if (index === -1) {
    arr.push(value);
  }
};

export function moveArray(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export const formStringArrayFromQuestion = (str) => {
  let prevStart = 0;
  let start = 0;
  let braketStarted = false;
  const strArray = [];

  if (str.match(/\[[^\]]*]/g)) {
    for (let i = 0; i < str.length; i++) {
      if (str[i] == "[") {
        braketStarted = true;
        start = i;
      }

      if (braketStarted && str[i] == "]") {
        braketStarted = false;
        strArray.push(str.slice(prevStart, start));
        strArray.push(str.slice(start, i + 1));
        prevStart = i + 1;
      } else if (i === str.length - 1) {
        strArray.push(str.slice(prevStart, i + 1));
      }
    }
  } else {
    strArray.push(str);
  }
  return strArray;
};

const checkStringExist = (conditionArray, answer, type) => {
  if (answer) {
    for (let i = 0; i < conditionArray.length; i++) {
      const existFlag = answer
        .toLowerCase()
        .includes(conditionArray[i].toLowerCase().trim());
      if (existFlag) {
        return type === "contains" ? true : false;
      }
    }
  }
  return false;
};

export const determineConditionValidity = (condition, answer) => {
  // let answer = answers[condition.question];

  if (!answer) {
    if (
      condition.type === "isEmpty" ||
      condition.type === "doesNotContain" ||
      condition.type === "isNotSelected"
    ) {
      return true;
    } else {
      return false;
    }
  } else if (condition.type === "isNotEmpty") {
    return true;
  }

  if (!condition.answer) {
    return false;
  }

  let conditionAns = condition.answer;
  if (conditionAns.includes(".")) {
    conditionAns = conditionAns.split(".")[1]?.trim();
  }
  switch (condition.type) {
    case "contains":
      return checkStringExist(conditionAns, answer, "contains");
    case "doesNotContain":
      return checkStringExist(conditionAns, answer, "doesNotContain");
    case "isSelected":
      //incase of checkbox response check
      if (answer instanceof Array) {
        return answer.includes(conditionAns);
      } else {
        return answer == conditionAns;
      }
    case "isNotSelected":
      //incase of checkbox response check
      if (answer instanceof Array) {
        return !answer.includes(conditionAns);
      } else {
        return answer != conditionAns;
      }
    case "isGreaterThan":
      return Number(answer) > Number(conditionAns);
    case "isGreaterThanOrEqual":
      return Number(answer) >= Number(conditionAns);
    case "isLessThan":
      return Number(answer) < Number(conditionAns);
    case "isLessThanOrEqual":
      return Number(answer) <= Number(conditionAns);
    case "equals":
      return answer.toLowerCase() === String(conditionAns).toLowerCase();
    case "notEquals":
      return answer.toLowerCase() !== String(conditionAns).toLowerCase();
    case "isPromoter":
      return Number(answer) > 8;
    case "isDetractor":
      return Number(answer) < 7;
    case "isPassive":
      return Number(answer) === 7 || Number(answer) === 8;
    case "isNotPromoter":
      return Number(answer) <= 8;
    case "isNotDetractor":
      return Number(answer) >= 7;
    case "isNotPassive":
      return Number(answer) !== 7 && Number(answer) !== 8;
    default:
      return false;
  }
};

export const fetchCustomVariableOptionsFromAnswers = (
  survey,
  surveyId,
  ans
) => {
  const answers = _.cloneDeep(ans);
  survey?.[surveyId]?.questions?.forEach((question) => {
    if (question.type === "variable") {
      let varOptions = [];
      question.choices.forEach((choice) => {
        const val =
          choice.value && choice.value.indexOf("Value_") !== -1
            ? choice.value.split("Value_")[1]
            : choice.value;
        if (choice.label === "Response from") {
          if (choice.source) {
            if (choice.source.parentType === EQUATION) {
              varOptions.push(
                fetchResult(
                  survey?.[surveyId]?.questions.find(
                    (data) => data.id === choice.source.parentId
                  ).equations,
                  ans
                )
              );
            } else {
              varOptions.push(`${ans[choice.source.parentId]}`);
            }
          }
        } else if (choice.logic?.check === "alwaysTrue") {
          varOptions.push(val);
        } else if (choice.logic?.check === "trueWithCondition") {
          let conditionSucceeds = false;
          const conditionsLength = choice.logic?.conditions?.length;
          for (let index = 0; index < conditionsLength; index++) {
            const condition = choice.logic.conditions[index];
            if (condition.modifier === "or") {
              if (
                determineConditionValidity(condition, ans[condition.question])
              ) {
                conditionSucceeds = true;
                break;
              }
              if (index === choice.logic.conditions?.length - 1) {
                conditionSucceeds = false;
              }
            } else {
              if (
                !determineConditionValidity(condition, ans[condition.question])
              ) {
                conditionSucceeds = false;
                break;
              }
              if (index === choice.logic.conditions?.length - 1) {
                conditionSucceeds = true;
              }
            }
          }
          if (conditionSucceeds) {
            varOptions.push(val);
          }
        } else if (choice.logic?.check === "falseWithCondition") {
          let conditionSucceeds = true;
          const conditionsLength = choice.logic?.conditions?.length;
          for (let index = 0; index < conditionsLength; index++) {
            const condition = choice.logic.conditions[index];
            if (condition.modifier === "or") {
              if (
                determineConditionValidity(condition, ans[condition.question])
              ) {
                conditionSucceeds = false;
                break;
              }
              if (index === choice.logic.conditions?.length - 1) {
                conditionSucceeds = true;
              }
            } else {
              if (
                !determineConditionValidity(condition, ans[condition.question])
              ) {
                conditionSucceeds = true;
                break;
              }
              if (index === choice.logic.conditions?.length - 1) {
                conditionSucceeds = false;
              }
            }
          }
          if (conditionSucceeds) {
            varOptions.push(val);
          }
        }
        if (varOptions.length > 0) {
          answers[question.id] = varOptions;
        } else {
          if (Object.prototype.hasOwnProperty.call(ans, question.id)) {
            delete answers[question.id];
          }
        }
      });
    }
  });
  return answers;
};

export const fetchEquationVariableOptionsFromAnswers = (
  survey,
  surveyId,
  ans
) => {
  const answers = _.cloneDeep(ans);
  survey?.[surveyId]?.questions?.forEach((question) => {
    if (question.type === EQUATION) {
      answers[question.id] = `${fetchResult(question.equations, ans)}`;
    }
  });
  return answers;
};

export const fetchPTLOptionsFromAnswers = (
  questions = [],
  answers = {},
  queAnswers
) => {
  const ans = _.cloneDeep(queAnswers);
  questions.forEach((qstn) => {
    if (qstn.formattingType === "passThroughLink") {
      ans[qstn.id] = answers[qstn.id];
    }
  });
  return ans;
};

export const fetchResult = (equations, ans) => {
  const response = calculateEquation(equations, ans, false);
  try {
    let result = Function("return " + response)();
    if (`${result}`.includes("Infinity")) {
      result = "error";
    }
    return `${result || 0}`;
  } catch (error) {
    window.console.error("Response validate: ", error);
    return "error";
  }
};

export const checkEqDependent = (questions, id) => {
  return questions.some((que) => {
    return que.equations.some((eq) => {
      if (
        eq.type === "function" &&
        eq.questions.some((q) => q.type === "question" && q.value == id)
      ) {
        return true;
      } else if (
        eq.type === "question" &&
        eq.questions.some((q) => q.type === "question" && q.value == id)
      ) {
        return true;
      }
    });
  });
};

export const checkCurretEqDependent = (questions, equations) => {
  return questions.some((ques) => {
    return equations.some((eq) => {
      if (
        eq.type === "function" &&
        eq.questions.some((q) => q.type === "question" && q.value == ques.id)
      ) {
        return true;
      } else if (
        eq.type === "question" &&
        eq.questions.some((q) => q.type === "question" && q.value == ques.id)
      ) {
        return true;
      }
    });
  });
};
export const checkSurveyHasEquationVariable = (survey, surveyId) => {
  const questions = survey?.[surveyId]?.questions || [];
  for (const item of questions) {
    if (item.type === EQUATION) {
      return true;
    }
  }
};

export const checkSurveyHasAnswerOptionLogic = (survey, surveyId) => {
  let setHasAnswerOptionLogic = false;
  for (let i = 0; i < survey?.[surveyId]?.questions?.length; i++) {
    if (survey?.[surveyId]?.questions[i]?.choices) {
      for (let j = 0; j < survey?.[surveyId].questions[i].choices.length; j++) {
        if (
          survey?.[surveyId]?.questions[i]?.choices[j]?.logic?.conditions
            .length > 0 ||
          survey?.[surveyId]?.questions[i]?.choices[j]?.logic?.check ===
            "alwaysFalse" ||
          survey?.[surveyId]?.questions[i]?.choices[j]?.source
        ) {
          setHasAnswerOptionLogic = true;
        }
      }
    }
  }
  return setHasAnswerOptionLogic;
};

export const findChoicesIndex = (questions, condtn) => {
  const question = questions?.find((data) => data.id === condtn.question);
  const choicesIndex = question?.choices?.findIndex(
    (item) =>
      item.value === "Value_" + condtn.answer || item.value == condtn.answer
  );
  return { choicesIndex, questionLabel: question?.questionLabel };
};

export const conditionMsg = (condition, tag) => {
  switch (condition) {
    case "isSelected":
      return "is True/Selected";
    case "isNotSelected":
      return "is False/Unselected";
    case "isTrue":
      return "is True";
    case "isFalse":
      return "is False";
    case "doesNotContain":
      return t("surveyBuilder:logicScreen.noContains");
    case "isEmpty":
      return t("surveyBuilder:logicScreen.isEmpty");
    case "isNotEmpty":
      return t("surveyBuilder:logicScreen.isNotEmpty");
    case "contains":
      return t("surveyBuilder:logicScreen.Contains");
    case "equals":
      return t("surveyBuilder:logicScreen.Equals");
    case "isGreaterThan":
      return t("surveyBuilder:logicScreen.isGreaterThan");
    case "isGreaterThanOrEqual":
      return t("surveyBuilder:logicScreen.isGreaterThanOrEqual");
    case "isLessThan":
      return t("surveyBuilder:logicScreen.isLessThan");
    case "isLessThanOrEqual":
      return t("surveyBuilder:logicScreen.isLessThanOrEqual");
    case "isPromoter":
      return t("surveyBuilder:logicScreen.isPromoter");
    case "isDetractor":
      return t("surveyBuilder:logicScreen.isDetractor");
    case "isPassive":
      return t("surveyBuilder:logicScreen.isPassive");
    case "isNotPromoter":
      return t("surveyBuilder:logicScreen.isNotPromoter");
    case "isNotDetractor":
      return t("surveyBuilder:logicScreen.isNotDetractor");
    case "isNotPassive":
      return t("surveyBuilder:logicScreen.isNotPassive");
    case "notEquals":
      return t("surveyBuilder:logicScreen.notEquals");
    default:
      return tag;
  }
};

export const getLogicTag = (choice, questions) => {
  let tag = "";
  const appliedConditions = choice?.logic?.conditions;
  if (appliedConditions?.length > 0) {
    if (appliedConditions?.length > 1) {
      if (choice?.logic?.check === "trueWithCondition") {
        tag = "True with Multiple Conditions";
      } else if (choice?.logic?.check === "falseWithCondition") {
        tag = "False with Multiple Conditions";
      }
    } else {
      appliedConditions.map((condtn, idx) => {
        let answer;
        if (condtn.questionType === "webform_rating") {
          if (condtn.answer === "5") {
            answer = "Thumbs Up";
          } else if (condtn.answer === "1") {
            answer = "Thumbs Down";
          }
        } else if (condtn.questionType === "faces") {
          if (condtn.answer === "1") {
            answer = "Very Dissatisfied";
          } else if (condtn.answer === "2") {
            answer = "Dissatisfied";
          } else if (condtn.answer === "3") {
            answer = "Just Ok";
          } else if (condtn.answer === "4") {
            answer = "Satisfied";
          } else if (condtn.answer === "5") {
            answer = "Very Satisfied";
          }
        } else {
          answer = Array.isArray(condtn.answer)
            ? condtn.answer[idx]
            : condtn.answer;
        }
        const { choicesIndex, questionLabel } = findChoicesIndex(
          questions,
          condtn
        );
        if (choice?.logic?.check === "trueWithCondition") {
          const type = conditionMsg(condtn.type, condtn.type);
          if (
            condtn.questionType === "radios" ||
            condtn.questionType === "checkboxes" ||
            condtn.questionType === "ranking" ||
            condtn.questionType === "select" ||
            condtn.questionType === "variable"
          ) {
            tag = `True when "${questionLabel || condtn.questionLabel} - c${
              choicesIndex + 1
            } ${type}"`;
          } else {
            tag = `True when "${
              questionLabel || condtn.questionLabel
            } ${type} ${answer}"`;
          }
        } else if (choice?.logic?.check === "falseWithCondition") {
          const type = conditionMsg(condtn.type, condtn.type);
          if (
            condtn.questionType === "radios" ||
            condtn.questionType === "ranking" ||
            condtn.questionType === "checkboxes" ||
            condtn.questionType === "select" ||
            condtn.questionType === "variable"
          ) {
            tag = `False when "${questionLabel || condtn.questionLabel} - c${
              choicesIndex + 1
            } ${type}`;
          } else {
            tag = `False when "${
              questionLabel || condtn.questionLabel
            } ${type} ${answer}"`;
          }
        }
      });
    }
  } else {
    if (choice?.logic?.check === "alwaysTrue") {
      tag = HIDDEN_VARIABLE.ALWAYS_TRUE;
    } else if (choice?.logic?.check === "alwaysFalse") {
      tag = HIDDEN_VARIABLE.ALWAYS_FALSE;
    }
  }
  return choice?.logic?.tag ? tag : choice?.logic?.check;
};

export const shuffle = (array) => {
  const choices = array.filter(
    (data) => data.value !== OTHER && data.value !== NONE
  );
  for (let i = choices.length - 1; i > 0; i--) {
    let j = Math.floor(getRandomNumber() * (i + 1));
    [choices[i], choices[j]] = [choices[j], choices[i]];
  }
  const indexOfOther = array.findIndex((item) => item.value === OTHER);
  const indexOfNone = array.findIndex((item) => item.value === NONE);
  if (indexOfOther !== -1) choices.push(array[indexOfOther]);
  if (indexOfNone !== -1) {
    choices.push(array[indexOfNone]);
  }
  return choices;
};
export const fetchPTLOptionsFromHiddenAnswers = (
  questions = [],
  hiddenAnswers,
  queAnswers
) => {
  const ans = _.cloneDeep(queAnswers);
  questions.forEach((qstn) => {
    if (qstn.formattingType === "passThroughLink") {
      if (hiddenAnswers[qstn.questionLabel] !== undefined) {
        ans[qstn.id] = hiddenAnswers[qstn.questionLabel];
      }
    }
  });
  return ans;
};
