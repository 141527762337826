function getCampaignParams(campaignDetails) {
  const params =
    (campaignDetails?.campaignID?.length > 0
      ? `?campaign_enabled=true&campaign_id=${campaignDetails.campaignID}`
      : "") +
    (campaignDetails?.customerID?.length > 0
      ? `&customer_id=${campaignDetails.customerID}`
      : "") +
    (campaignDetails?.waveID?.length > 0
      ? `&wave_id=${campaignDetails.waveID}`
      : "");
  return params;
}

export default getCampaignParams;
