import React from "react";
import {
  PeopleAltOutlined as PeopleAltIcon,
  ShoppingCartOutlined as ShoppingCartIcon,
  QuestionAnswerOutlined as ResponseIcon,
  Add as AddIcon,
  Person2Outlined as VendorIcon,
} from "@mui/icons-material";
import i18next from "../locales";

import GLOBAL_TECHNOLOGY_LOGO from "../images/surveyThemes/globalTechnology.svg";
import INTERNATIONAL_LOGO from "../images/surveyThemes/international.svg";
import SAMS_CLUB_LOGO from "../images/surveyThemes/samsClub.svg";
import WALMART_CLASSIC_LOGO from "../images/surveyThemes/walmartClassic.svg";
import CLASSIC_EXPERIENCE_LOGO from "../images/icons/classic.svg";
import GUIDED_EXPERIENCE_LOGO from "../images/icons/guided.svg";

import BURMESE_POLICY from "../assets/pdf/policy_burmese.pdf";
import MANDARIN_POLICY from "../assets/pdf/policy_mandarin.pdf";

const t = i18next.t;

export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const MEDIAUPLOAD = "mediaUpload";

export const MAX_MIDDESKTOP_SIZE = 1024;
export const MAX_MOBILE_SIZE = 768;
export const ALREADY_SUBMITTED = "ALREADY_SUBMITTED";
export const NPS_TITLE = "common:npsTitle";
export const CONDITIONS = "#conditions";
export const CHOICES_TYPE = "#choicesType";
export const PIPING_FROM = "pipingFrom";
export const RESUME = "resume";
export const START_OVER = "startOver";

export const HIDDEN_VARIABLE = {
  ALWAYS_TRUE: "Always True",
  ALWAYS_FALSE: "Always False",
};

export const TRACK = {
  PAGE: {
    DASHBOARD: "dashboard",
    ACTIVE_SURVEYS: "active surveys",
    DRAFT_SURVEYS: "draft surveys",
    INACTIVE_SURVEYS: "inactive surveys",
    INTRANSLATION_SURVEYS: "inTranslation surveys",
    SURVEY_SETTINGS: "survey_settings",
    SURVEY_CREATE_QUESTIONS: "survey_create_questions",
    SURVEY_PREVIEW: "survey_preview",
    SURVEY_SEND: "survey_send",
    SURVEY_REVIEW_RESULTS: "survey_review_results",
    SURVEYTAKER_OPENED: "surveytaker_opened",
    SURVEYTAKER_QUESTION_DISPLAYED: "surveytaker_question_displayed",
    SURVEYTAKER_THANK_YOU: "surveytaker_thank_you",
    SURVEYTAKER_DUPLICATE_RESPONSE: "surveytaker_duplicate_response",
  },
  EVENT: {
    DASHBOARD_CREATE_SURVEY: "dashboard_create_survey",
    DASHBOARD_CREATE_SURVEY_CONFIRM: "dashboard_create_survey_confirm",
    DASHBOARD_CREATE_SURVEY_CANCEL: "dashboard_create_survey_cancel",
    DASHBOARD_ACTIVE_SENDSURVEY: "dashboard_active_sendsurvey",
    DASHBOARD_ACTIVE_RESULTS: "dashboard_active_results",
    DASHBOARD_INACTIVE_RESULTS: "dashboard_inactive_results",
    DASHBOARD_ACTIVE_PREVIEW: "dashboard_active_preview",
    DASHBOARD_INACTIVE_PREVIEW: "dashboard_inactive_preview",
    DASHBOARD_ACTIVE_EDIT: "dashboard_active_edit",
    DASHBOARD_INACTIVE_EDIT: "dashboard_inactive_edit",
    DASHBOARD_ACTIVE_DUPLICATE: "dashboard_active_duplicate",
    DASHBOARD_INACTIVE_DUPLICATE: "dashboard_inactive_duplicate",
    DASHBOARD_ACTIVE_DUPLICATE_CONFIRM: "dashboard_active_duplicate_confirm",
    DASHBOARD_INACTIVE_DUPLICATE_CONFIRM:
      "dashboard_inactive_duplicate_confirm",
    DASHBOARD_ACTIVE_DUPLICATE_CANCEL: "dashboard_active_duplicate_cancel",
    DASHBOARD_INACTIVE_DUPLICATE_CANCEL: "dashboard_inactive_duplicate_cancel",
    DASHBOARD_ACTIVE_DEACTIVATE: "dashboard_active_deactivate",
    DASHBOARD_ACTIVE_DELETE: "dashboard_active_delete",
    DASHBOARD_INACTIVE_DELETE: "dashboard_inactive_delete",
    DASHBOARD_ACTIVE_DELETE_CONFIRM: "dashboard_active_delete_confirm",
    DASHBOARD_INACTIVE_DELETE_CONFIRM: "dashboard_inactive_delete_confirm",
    DASHBOARD_ACTIVE_DELETE_CANCEL: "dashboard_active_delete_cancel",
    DASHBOARD_INACTIVE_DELETE_CANCEL: "dashboard_inactive_delete_cancel",
    DASHBOARD_ACTIVE_MORE: "dashboard_active_more",
    DASHBOARD_INACTIVE_MORE: "dashboard_inactive_more",
    DASHBOARD_ACTIVE_PAGINATION_PREV: "dashboard_active_pagination_prev",
    DASHBOARD_INACTIVE_PAGINATION_PREV: "dashboard_inactive_pagination_prev",
    DASHBOARD_ACTIVE_PAGINATION_NEXT: "dashboard_active_pagination_next",
    DASHBOARD_INACTIVE_PAGINATION_NEXT: "dashboard_inactive_pagination_next",
    DASHBOARD_SEND_COPY: "dashboard_send_copy",
    DASHBOARD_SEND_CLOSE: "dashboard_send_close",
    DASHBOARD_DRAFT_EDIT: "dashboard_draft_edit",
    DASHBOARD_DRAFT_PREVIEW: "dashboard_draft_preview",
    DASHBOARD_DRAFT_PUBLISH: "dashboard_draft_publish",
    DASHBOARD_INACTIVE_PUBLISH: "dashboard_inactive_publish",
    DASHBOARD_DRAFT_DUPLICATE: "dashboard_draft_duplicate",
    DASHBOARD_DRAFT_DUPLICATE_CONFIRM: "dashboard_draft_duplicate_confirm",
    DASHBOARD_DRAFT_DUPLICATE_CANCEL: "dashboard_draft_duplicate_cancel",
    DASHBOARD_DRAFT_DELETE: "dashboard_draft_delete",
    DASHBOARD_DRAFT_DELETE_CONFIRM: "dashboard_draft_delete_confirm",
    DASHBOARD_DRAFT_DELETE_CANCEL: "dashboard_draft_delete_cancel",
    DASHBOARD_DRAFT_MORE: "dashboard_draft_more",
    DASHBOARD_DRAFT_PAGINATION_PREV: "dashboard_draft_pagination_prev",
    DASHBOARD_DRAFT_PAGINATION_NEXT: "dashboard_draft_pagination_next",
    DASHBOARD_INTRANSLATION_DELETE: "dashboard_intranslation_delete",
    DASHBOARD_INTRANSLATION_MORE: "dashboard_intranslation_more",
    SURVEY_TEMPLATE_LIST_CHANGE_QUESTIONS_CONFIRM:
      "survey_template_list_change_questions_confirm",
    SURVEY_TEMPLATE_LIST_CHANGE_QUESTIONS_CANCEL:
      "survey_template_list_change_questions_cancel",
    LOGOUT: "logout",
  },
};

export const CHOICES_TYPES = {
  DYNAMIC: "dynamic",
  STATIC: "static",
};

export const DIALOG_TYPE = {
  ACCESS_TOKEN: "access_token",
  COPY_LINK: "copy_link",
  COPY_REDIRECT: "copy_redirect",
  RELEASE_LOG: "release_log",
  ERROR: "error",
  CREATE_SURVEY: "create_survey",
  LOGIN_ERROR: "loginError",
  USER_ROLE_ERROR: "userRoleError",
  CONFIRM_MOVE_QUESTION: "confirm_move_question",
  DELETE_ACTIVE_SURVEY: "delete_active_survey",
  DELETE_DRAFT_SURVEY: "delete_draft_survey",
  DELETE_INACTIVE_SURVEY: "delete_inactive_survey",
  DUPLICATE_ACTIVE_SURVEY: "duplicate_active_survey",
  DUPLICATE_DRAFT_SURVEY: "duplicate_draft_survey",
  DUPLICATE_INACTIVE_SURVEY: "duplicate_inactive_survey",
  DATASOURCE_INFO: "datasource_info",
  DYNAMIC_CHOICES_INFO: "dynamic_choices_info",
  CHANGE_QUESTIONS: "changes_questions",
};

export const SIDE_PANEL_TYPES = {
  FORMATING: "Formating",
  OTHER: "Other",
};

export const FORMATING_TYPE = {
  PASS_THROUGH_LINK: "passThroughLink",
  BLOCK: "block",
  TERMINATE: "terminate",
};

export const QUESTION_LABELLING_PREFIX = {
  PASS_THROUGH_LINK: "PTL",
};

export const UPDATED_MESSAGES = {
  TITLE: "Survey Title updated",
  REQUIRE_SSO: "Updated Require SSO checkbox",
  MAKE_RESPONSES_ANONYMOUS: "Updated Survey Responses Anonymous checkbox",
  ALLOW_MULTIPLE_SUBMISSIONS: "Updated multiple submissions settings",
  WELCOME_THEME: "Welcome theme updated",
  THANKYOU_THEME: "Thankyou theme updated",
  WELCOME_HEADLINE: "Welcome page Headline updated",
  WELCOME_SUB_HEADLINE: "Welcome page Subheadline updated",
  THANKYOU_HEADLINE: "Thankyou page Headline updated",
  THANKYOU_SUB_HEADLINE: "Thankyou page Subheadline updated",
  POLICY: "Survey policy updated",
  QUESTION_UPDATED: "Questions Added/Updated",
  QUESTION_REORDERED: "Questions Reordered",
  CO_OWNER_UPDATED: "Co-owners added/updated",
  VIEWER_UPDATED: "Viewers added/updated",
  COLLAB_UPDATED: "Collaboraters added/updated",
  IS_PUBLISHABLE_UPDATED: "Updated Survey Publishable status",
  DATA_SOURCES_UPDATED: "Updated Survey data sources",
  SOURCE_FILE_UPDATED: "Updated Survey Source Files",
  ALLOW_WIN_COLLECT: "Updated survey WIN access",
  ALLOW_CUSTOMER_ID_COLLECT: "Updated customer id collect",
};

export const ROLE = {
  OWNER: "owner",
  CO_OWNER: "coowner",
  VIEWER: "viewer",
};

export const CHARACTER_LIMITS = {
  QUESTION: 3000,
};
export const CHARACTER_LIMIT_WARNING = "common:characterLimit";

export const CK_EDITOR_CONFIG = {
  toolbar: ["bold", "italic", "underline", "link", "removeFormat"],
  link: {
    defaultProtocol: "https://",
    addTargetToExternalLinks: true,
  },
  // extraPlugins: [PipingHandler],
};

export const CK_EDITOR_CONTENT_CONFIG = {
  toolbar: [
    "bold",
    "italic",
    "underline",
    "fontSize",
    "alignment",
    "bulletedList",
    "numberedList",
    "link",
    "removeFormat",
  ],
  link: {
    defaultProtocol: "https://",
    addTargetToExternalLinks: true,
  },
};

export const RANKING_MAX_COUNT = 10;

export const DROPDOWN_MAX_OPTIONS_COUNT = 250;

export const OTHER = t("common:other");
export const NONE = t("common:none");
export const OTHER_LABEL = t("common:otherLabel");
export const NONE_LABEL = t("common:noneOfAbove");
export const OTHER_LABEL_OLD = t("common:otherLabelOld");
export const VALUE_PREFIX = "Value_";

export const renderNoneByLang = (lang) => {
  switch (lang) {
    case "en":
      return "None of the above";
    case "es":
      return "Ninguna de las anteriores";
    case "fr":
      return "Aucune de ces réponses";
    case "my":
      return "အထက်ဖော်ပြပါတစ်ခုမှမဟုတ်ပါ";
    case "zh":
      return "以上都不是";
    default:
      return NONE_LABEL;
  }
};

export const renderOtherByLang = (lang) => {
  switch (lang) {
    case "en":
      return "Other, if so please tell us more";
    case "es":
      return "Otro, si es así por favor cuéntanos más";
    case "fr":
      return "Autre, si oui merci de nous en dire plus";
    case "my":
      return "အခြား၊ ကျေးဇူးပြု၍ ကျွန်ုပ်တို့ကို ထပ်မံပြောပြပါ။";
    case "zh":
      return "其他，如果是，請告訴我們更多";
    default:
      return OTHER_LABEL;
  }
};

export const AUDIENCE_LIST = [
  {
    displayName: t("surveyBuilder:custom_audience.associate"),
    value: "associate",
    icon: () => <PeopleAltIcon />,
  },
  {
    displayName: t("surveyBuilder:custom_audience.customer"),
    value: "customer",
    icon: () => <ShoppingCartIcon />,
  },
  {
    displayName: t("surveyBuilder:custom_audience.vendor"),
    value: "vendor",
    icon: () => <VendorIcon />,
  },
  // { displayName: "Product", value: "product", icon: () => <PhoneAndroidOutlinedIcon /> },
  {
    displayName: t("surveyBuilder:custom_audience.other"),
    value: "other",
    icon: () => <AddIcon />,
  },
];

export const EXPERIENCE_LIST = [
  {
    displayName: t("surveyBuilder:exp_list.classicText"),
    value: "classic",
    icon: () => <img src={CLASSIC_EXPERIENCE_LOGO} alt={"classic-icon"} />,
  },
  {
    displayName: t("surveyBuilder:exp_list.guidedText"),
    value: "guided",
    icon: () => <img src={GUIDED_EXPERIENCE_LOGO} alt={"guided-icon"} />,
  },
];

export const POLICY_LIST = [
  {
    policy_id: "230",
    policy_name: t("surveyBuilder:policy_list.walmart"),
    policy_link: "http://corporate.walmart.com/privacy-security",
  },
  {
    policy_id: "231",
    policy_name: t("surveyBuilder:policy_list.canada-english"),
    policy_link: "https://www.walmartcanada.ca/privacy-notices",
  },
  {
    policy_id: "232",
    policy_name: t("surveyBuilder:policy_list.canada-french"),
    policy_link: "https://www.walmartcanada.ca/privacy-notices",
  },
  {
    policy_id: "233",
    policy_name: t("surveyBuilder:policy_list.us-english"),
    policy_link:
      "https://corporate.walmart.com/privacy-security#privacy-policies",
  },
  {
    policy_id: "234",
    policy_name: t("surveyBuilder:policy_list.us-spanish"),
    policy_link:
      "https://corporate.walmart.com/privacy-security/politica-de-privacidad-de-walmart",
  },
  {
    policy_id: "235",
    policy_name: t("surveyBuilder:policy_list.us-burmese"),
    policy_link: BURMESE_POLICY,
  },
  {
    policy_id: "236",
    policy_name: t("surveyBuilder:policy_list.mexico-spanish"),
    policy_link: "https://www.walmartmexico.com/aviso-de-privacidad",
  },
  {
    policy_id: "237",
    policy_name: t("surveyBuilder:policy_list.central-america-spanish"),
    policy_link: "https://www.walmartcentroamerica.com/privacidad",
  },
  {
    policy_id: "238",
    policy_name: t("surveyBuilder:policy_list.south-africa"),
    policy_link:
      "https://www.massmart.co.za/wp-content/uploads/2021/06/Customer-Privacy-Policy_Final-V2.pdf",
  },
  {
    policy_id: "239",
    policy_name: t("surveyBuilder:policy_list.china-mandarin"),
    policy_link: MANDARIN_POLICY,
  },
  {
    policy_id: "240",
    policy_name: t("surveyBuilder:policy_list.walmartAssociate"),
    policy_link:
      "https://corporate.walmart.com/privacy-security/walmart-associate-information-privacy-policy",
  },
  {
    policy_id: "241",
    policy_name: t("surveyBuilder:policy_list.walmartSupplier"),
    policy_link:
      "https://corporate.walmart.com/privacy-security/walmart-supplier-privacy-policy",
  },
  {
    policy_id: "242",
    policy_name: t("surveyBuilder:policy_list.walmartMarketplace"),
    policy_link:
      "https://corporate.walmart.com/privacy-security/walmart-marketplace-seller-privacy-policy",
  },
];

export const THEME_GLOBAL_TECHNOLOGY = "global_technology";
export const THEME_WALMART_CLASSIC = "walmart_classic";
export const THEME_SAM_CLUB = "sams_club";
export const THEME_INTERNATIONAL = "international";

const getThemeImage = (name) => {
  let themeObj = {};
  switch (name) {
    case THEME_GLOBAL_TECHNOLOGY:
      themeObj["src"] = GLOBAL_TECHNOLOGY_LOGO;
      themeObj["alt"] = THEME_GLOBAL_TECHNOLOGY;
      break;
    case THEME_WALMART_CLASSIC:
      themeObj["src"] = INTERNATIONAL_LOGO;
      themeObj["alt"] = THEME_WALMART_CLASSIC;
      break;
    case THEME_SAM_CLUB:
      themeObj["src"] = SAMS_CLUB_LOGO;
      themeObj["alt"] = THEME_SAM_CLUB;
      break;
    case THEME_INTERNATIONAL:
      themeObj["src"] = WALMART_CLASSIC_LOGO;
      themeObj["alt"] = THEME_INTERNATIONAL;
      break;
    default:
      themeObj["src"] = INTERNATIONAL_LOGO;
      themeObj["alt"] = THEME_WALMART_CLASSIC;
      break;
  }

  return <img src={themeObj.src} alt={themeObj.alt} />;
};

export const THEME_LIST = [
  {
    displayName: t("surveyBuilder:theme_list.globalTech"),
    value: THEME_GLOBAL_TECHNOLOGY,
    icon: () => getThemeImage(THEME_GLOBAL_TECHNOLOGY),
  },
  {
    displayName: t("surveyBuilder:theme_list.walmart_classic"),
    value: THEME_WALMART_CLASSIC,
    icon: () => getThemeImage(THEME_WALMART_CLASSIC),
  },
  {
    displayName: t("surveyBuilder:theme_list.sam_club"),
    value: THEME_SAM_CLUB,
    icon: () => getThemeImage(THEME_SAM_CLUB),
  },
  {
    displayName: t("surveyBuilder:theme_list.international"),
    value: THEME_INTERNATIONAL,
    icon: () => getThemeImage(THEME_INTERNATIONAL),
  },
];

export const BOTTOM_NAV_ITEMS = [
  {
    id: "Settings",
    name: "Settings",
    valid: true,
  },
  {
    id: "design",
    name: "Design",
    valid: true,
  },
  {
    id: "preview",
    name: "Preview",
    valid: false,
  },
  {
    id: "launch",
    name: "Launch",
    valid: false,
  },
];

export const BOTTOM_NAV_LABEL = [
  "surveyBuilder:design",
  "surveyBuilder:preview",
  "surveyBuilder:launch",
];

export const MY_FEEDBACK = "myFeedback";
export const SHARED_WITH_ME = "sharedWithMe";
export const SURVEY_SORT_BY_NAME = "title";
export const SURVEY_SORT_BY_STATUS = "status";
export const SURVEY_SORY_BY_LAST_UPDATED = "modified_on";
export const AUDIENCE_ALL = "All";
export const AUDIENCE_ASSOCIATE = "Associate";
export const AUDIENCE_CUSTOMER = "Customer";
export const AUDIENCE_PRODUCT = "product";
export const OPEN_SURVEYS = "open";
export const CLOSED_SURVEYS = "closed"; // these are drafts
export const INACTIVE_SURVEYS = "inactive";
export const INTRANSLATION_SURVEYS = "inTranslation";

const dashboardTableHeader = [
  {
    id: "typeApp",
    label: "dashboard:table.type",
    filter: true,
    value: [AUDIENCE_ALL, AUDIENCE_ASSOCIATE, AUDIENCE_CUSTOMER],
  },
  {
    id: "name",
    label: "dashboard:table.name",
    align: "left",
    sorting: true,
    sortBy: SURVEY_SORT_BY_NAME,
  },
  {
    id: "status",
    label: "dashboard:table.status",
    align: "left",
    sortBy: SURVEY_SORT_BY_STATUS,
  },
  { id: "questions", label: "dashboard:table.questions" },
  {
    id: "responses",
    label: "dashboard:table.responses",
    icon: () => <ResponseIcon style={{ fontSize: 14 }} />,
  },
  {
    id: "lastUpdated",
    label: "dashboard:table.lastUpdated",
    sorting: true,
    sortBy: SURVEY_SORY_BY_LAST_UPDATED,
  },
  { id: "editTabs", label: "" },
];

// { id: "typeApp", label: "Type All", filter: true, type: FILTER_BY_AUDIENCE, value: filters.audience, onSelect: handleFilter },

export const LIST_TABLE_MAPPING = {
  desktop: dashboardTableHeader,
  tablet: dashboardTableHeader,
  mobile: [
    {
      id: "name",
      label: "dashboard:table.name",
      align: "left",
      sorting: true,
      sortBy: SURVEY_SORT_BY_NAME,
    },
    {
      id: "responses",
      label: "dashboard:table.responses",
      icon: () => <ResponseIcon style={{ fontSize: 14 }} />,
    },
    { id: "editTabs", label: "" },
  ],
};

export const PRIVACY_POLICY =
  "https://surveysays.walmart.com/static/media/privacy.4bce7616.pdf";

export const CONTENT_TYPE_LABEL = t("surveyBuilder:instruction");

export const QUESTION_TYPE_LIST = [
  {
    label: t("surveyBuilder:questionType.shortLabel"),
    value: "textfield",
    description: t("surveyBuilder:questionType.shortDesc"),
  },
  {
    label: t("surveyBuilder:questionType.longLabel"),
    value: "textarea",
    description: t("surveyBuilder:questionType.longDesc"),
  },
  {
    label: t("surveyBuilder:questionType.singleLabel"),
    value: "radios",
    description: t("surveyBuilder:questionType.singleDesc"),
  },
  {
    label: t("surveyBuilder:questionType.multiLabel"),
    value: "checkboxes",
    description: t("surveyBuilder:questionType.multiDesc"),
  },
  {
    label: t("surveyBuilder:questionType.dropdownLabel"),
    value: "select",
    description: t("surveyBuilder:questionType.dropdownDesc"),
  },
  {
    label: t("surveyBuilder:questionType.thumbsupLabel"),
    value: "webform_rating",
    description: t("surveyBuilder:questionType.thumbsDesc"),
  },
  {
    label: t("surveyBuilder:questionType.facesLabel"),
    value: "faces",
    description: t("surveyBuilder:questionType.facesDesc"),
  },
  {
    label: t("surveyBuilder:questionType.matrixLabel"),
    value: "matrix",
    description: t("surveyBuilder:questionType.matrixDesc"),
  },
  {
    label: t("surveyBuilder:questionType.ratingLabel"),
    value: "range",
    description: t("surveyBuilder:questionType.ratingDesc"),
  },
  {
    label: t("surveyBuilder:questionType.ratingNumericLabel"),
    value: "rangeNumeric",
    description: t("surveyBuilder:questionType.ratingNumericDesc"),
  },
  {
    label: t("surveyBuilder:questionType.npsLabel"),
    value: "NPS",
    description: t("surveyBuilder:questionType.npsDesc"),
  },
  {
    label: t("surveyBuilder:questionType.rankingLabel"),
    value: "ranking",
    description: t("surveyBuilder:questionType.rankingDesc"),
  },
  {
    label: t("surveyBuilder:questionType.contentLabel"),
    value: "content",
    description: t("surveyBuilder:questionType.contentDesc"),
  },
  {
    label: t("surveyBuilder:questionType.mediaUploadLabel"),
    value: "mediaUpload",
    description: t("surveyBuilder:questionType.mediaUploadDesc"),
  },
  {
    label: t("surveyBuilder:questionType.contactFormLabel"),
    value: "contact_form",
    description: t("surveyBuilder:questionType.contactFormDesc"),
  },
  {
    label: t("surveyBuilder:questionType.numberLabel"),
    value: "number",
    description: t("surveyBuilder:questionType.numberDesc"),
  },
  {
    label: t("surveyBuilder:questionType.dateTimeLabel"),
    value: "datetime",
    description: t("surveyBuilder:questionType.dateTimeDesc"),
  },
  {
    label: t("surveyBuilder:questionType.imageSelectionLabel"),
    value: "image",
    description: t("surveyBuilder:questionType.imageSelectionDesc"),
  },
  //  Removing For GDVCVR-11741
  // {
  //   label: t("surveyBuilder:questionType.hiddenLabel"),
  //   value: "hidden",
  //   description: t("surveyBuilder:questionType.hiddenDesc"),
  // },
];

export const PTL_QUESTION_TYPE_LIST = [
  {
    label: t("surveyBuilder:questionType.shortLabel"),
    value: "textfield",
    description: t("surveyBuilder:questionType.shortDesc"),
  },
  {
    label: t("surveyBuilder:questionType.singleLabel"),
    value: "radios",
    description: t("surveyBuilder:questionType.singleDesc"),
  },
  {
    label: t("surveyBuilder:questionType.dropdownLabel"),
    value: "select",
    description: t("surveyBuilder:questionType.dropdownDesc"),
  },
];

export const FORMATTING_TYPES = {
  PASS_THROUGH_LINK: t("surveyBuilder:PTLFormattingType"),
};

export const NUMBER_INPUT_ERR_MSG = {
  RANGE_ERROR: "common:numberInputErrorMessages.rangeErrorMsg",
  STEPSIZE_ERROR: "common:numberInputErrorMessages.stepSizeError",
  VALIDNUM_ERROR: "common:numberInputErrorMessages.validNumError",
};

export const QUSTION_LIST_TAB_ITEMS = [
  { display: t("surveyBuilder:questionTypes"), value: 0 },
  { display: t("surveyBuilder:questionBank"), value: 1 },
];

export const VIEW_MORE_LIMIT = 11;

export const CONTACT_FORM_FIELDS = [
  {
    label: t("surveyBuilder:contactFormFields.name"),
    fieldType: "name",
    required: false,
    nameFieldType: 1,
  },
  {
    label: t("surveyBuilder:contactFormFields.dateOfBirth"),
    fieldType: "dateOfBirth",
    required: false,
    maskTextInput: false,
    dateFormat: "mm/dd/yyyy",
  },
  {
    label: t("surveyBuilder:contactFormFields.phone"),
    fieldType: "phone",
    required: false,
    maskTextInput: false,
  },
  {
    label: t("surveyBuilder:contactFormFields.email"),
    fieldType: "email",
    required: false,
    maskTextInput: false,
  },
  {
    label: t("surveyBuilder:contactFormFields.address"),
    fieldType: "address",
    required: false,
  },
];

export const DATE_TIME_OPTIONS = [
  {
    label: t("surveyBuilder:questionDate.dateOnly"),
    value: "date",
  },
  {
    label: t("surveyBuilder:questionDate.timeOnly"),
    value: "time",
  },
  {
    label: t("surveyBuilder:questionDate.date&Time"),
    value: "date&Time",
  },
];

export const DATE_FORMAT_OPTIONS = [
  {
    label: t("surveyBuilder:questionDate.dateFormat.mm/dd/yyyy"),
    value: "mm/dd/yyyy",
  },
  {
    label: t("surveyBuilder:questionDate.dateFormat.dd/mm/yyyy"),
    value: "dd/mm/yyyy",
  },
  {
    label: t("surveyBuilder:questionDate.dateFormat.yyyy/mm/dd"),
    value: "yyyy/mm/dd",
  },
  {
    label: t("surveyBuilder:questionDate.dateFormat.yyyy-mm-dd"),
    value: "yyyy-mm-dd",
  },
];

export const DAYS_OF_WEEK = [
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.mon"),
    value: 1,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.tue"),
    value: 2,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.wed"),
    value: 3,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.thu"),
    value: 4,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.fri"),
    value: 5,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.sat"),
    value: 6,
  },
  {
    label: t("surveyBuilder:questionDate.daysOfWeek.sun"),
    value: 0,
  },
];

export const datePickerError = {
  rangeErrorMsg: "common:rangeErrorMsg",
  invalidDateMsg: "common:invalidDateMsg",
};

const EQUALS = t("surveyBuilder:logicScreen.Equals"); // defining a variable due to multiple occurences

export const logicSectionTypes = {
  textfieldTypes: [
    {
      value: "contains",
      label: t("surveyBuilder:logicScreen.Contains"),
    },
    {
      value: "doesNotContain",
      label: t("surveyBuilder:logicScreen.noContains"),
    },
    {
      value: "equals",
      label: EQUALS,
    },
    {
      value: "isEmpty",
      label: t("surveyBuilder:logicScreen.isEmpty"),
    },
    {
      value: "isNotEmpty",
      label: t("surveyBuilder:logicScreen.isNotEmpty"),
    },
  ],
  npsTypes: [
    {
      value: "equals",
      label: EQUALS,
    },
    {
      value: "isGreaterThan",
      label: t("surveyBuilder:logicScreen.isGreaterThan"),
    },
    {
      value: "isGreaterThanOrEqual",
      label: t("surveyBuilder:logicScreen.isGreaterThanOrEqual"),
    },
    {
      value: "isLessThan",
      label: t("surveyBuilder:logicScreen.isLessThan"),
    },
    {
      value: "isLessThanOrEqual",
      label: t("surveyBuilder:logicScreen.isLessThanOrEqual"),
    },
    {
      value: "isPromoter",
      label: t("surveyBuilder:logicScreen.isPromoter"),
    },
    {
      value: "isDetractor",
      label: t("surveyBuilder:logicScreen.isDetractor"),
    },
    {
      value: "isPassive",
      label: t("surveyBuilder:logicScreen.isPassive"),
    },
    {
      value: "isNotPromoter",
      label: t("surveyBuilder:logicScreen.isNotPromoter"),
    },
    {
      value: "isNotDetractor",
      label: t("surveyBuilder:logicScreen.isNotDetractor"),
    },
    {
      value: "isNotPassive",
      label: t("surveyBuilder:logicScreen.isNotPassive"),
    },
  ],
  rangeNumericTypes: [
    {
      value: "equals",
      label: EQUALS,
    },
    {
      value: "notEquals",
      label: t("surveyBuilder:logicScreen.notEquals"),
    },
    {
      value: "isGreaterThan",
      label: t("surveyBuilder:logicScreen.isGreaterThan"),
    },
    {
      value: "isGreaterThanOrEqual",
      label: t("surveyBuilder:logicScreen.isGreaterThanOrEqual"),
    },
    {
      value: "isLessThan",
      label: t("surveyBuilder:logicScreen.isLessThan"),
    },
    {
      value: "isLessThanOrEqual",
      label: t("surveyBuilder:logicScreen.isLessThanOrEqual"),
    },
  ],
  variableTypes: [
    {
      value: "isTrue",
      label: "Is True",
    },
    {
      value: "isFalse",
      label: "Is False",
    },
  ],
  hiddenVariableTypes: [
    {
      value: "alwaysTrue",
      label: "Always True",
    },
    {
      value: "alwaysFalse",
      label: "Always False",
    },
    {
      value: "falseWithCondition",
      label: "False with condition",
    },
    {
      value: "trueWithCondition",
      label: "True with condition",
    },
  ],
};
export const QUESTION_TYPES = {
  TEXT_FIELD: "textfield",
  RADIOS: "radios",
  SINGLE_SELECT: "select",
  FACES: "faces",
  RANGE: "range",
  RANGE_NUMERIC: "rangeNumeric",
  NUMBER: "number",
  DATE_AND_TIME: "datetime",
  NPS: "NPS",
  CHECK_BOXES: "checkboxes",
  IMAGE: "image",
  OTHER: "Other",
  CONTENT: "content",
  UPLOAD_CONTENT: "mediaUpload",
  BLOCK: "block",
  TERMINATE: "terminate",
  VARIABLE: "variable",
  EQUATION: "equation",
  SOURCE_FILE_VARIABLE: "sourceFileVariable",
};
export const MULTI_SELECT_PIPING_QUESTIONS = new Set([
  QUESTION_TYPES.CHECK_BOXES,
  QUESTION_TYPES.IMAGE,
]);
export const PIPING_TO_UNSUPPORTED_QUESTIONS_TYPE = new Set([
  QUESTION_TYPES.NPS,
]);
export const PIPING_FROM_SUPPORTED_QUESTIONS_TYPE = new Set([
  QUESTION_TYPES.TEXT_FIELD,
  QUESTION_TYPES.RADIOS,
  QUESTION_TYPES.SINGLE_SELECT,
  QUESTION_TYPES.FACES,
  QUESTION_TYPES.RANGE,
  QUESTION_TYPES.RANGE_NUMERIC,
  QUESTION_TYPES.NUMBER,
  QUESTION_TYPES.DATE_AND_TIME,
  QUESTION_TYPES.NPS,
  QUESTION_TYPES.CHECK_BOXES,
  ...MULTI_SELECT_PIPING_QUESTIONS,
]);

export const FACES_VALUES = {
  1: "surveyTaker:stronglyDissatisfied",
  2: "surveyTaker:dissatisfied",
  3: "surveyTaker:justOk",
  4: "surveyTaker:satisfied",
  5: "surveyTaker:stronglySatisfied",
};

export const AUTOSAVE_FIELDS = {
  MODIFIED_ON: "modified_on",
  STARTED_ON: "started_on",
  SUBMITTED_BY: "submitted_by",
  DATA: "data",
};

export const DEFAULT_PARENT_ID = "root";
export const AUTO_SAVE_ = "autosave_";
export const AUTO_SAVE_THROTTLE_TIME = 30000;

export const PIPING_FROM_SUPPORTED_VARIABLE_TYPE = new Set([
  QUESTION_TYPES.TEXT_FIELD,
  QUESTION_TYPES.RADIOS,
  QUESTION_TYPES.SINGLE_SELECT,
]);
