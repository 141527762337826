const styles = () => ({
  container: {
    margin: "16% auto 0px",
    textAlign: "center",
  },
  details: {
    whiteSpace: "pre-wrap",
  },
  pre: {
    color: "#666",
    fontFamily: "monospace",
    fontSize: 15,
    lineHeight: 1.6,
    overflow: "auto",
    padding: 16,
    display: "block",
    wordWrap: "break-word",
  },
});

export default styles;
