const styles = () => ({
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  button: {
    textTransform: "none",
    borderRadius: 20,
  },
  dummyButton: {
    display: "none",
  },
});

export default styles;
