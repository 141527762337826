import React from "react";
import MuiDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MUIIconButton from "@mui/material/IconButton";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CSVLink } from "react-csv";
import { actions as dialogActions } from "../../../modules/dialog";
import noop from "../../../services/noop";
import PropTypes from "prop-types";
import s from "./style.module.scss";
import copy from "copy-to-clipboard";
import {
  shortAnswerMaxCount,
  surveyTitleMaxCount,
} from "../../../services/constants";
import Text from "../Text";
import samsTruckImg from "../../../images/errorPopup/samsTruck.svg";
import loginErrorImage from "../../../images/errorPopup/ManWalkingonCode@2x.png";
import { helpLink } from "../../../services/api";
import { SSO_LOGIN_URL } from "../../../services/constants";
import IconButton from "../IconButton";
import colors from "../../../style/colors.module.scss";
import { trackEvent } from "../../../services/commons";
import { TRACK, DIALOG_TYPE } from "../../constants";
import styles from "./styles";
import TokenDialogContent from "./TokenDialogContent";

export class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLinkCopy = this.handleLinkCopy.bind(this);
    this.executeTestingFunctions = this.executeTestingFunctions.bind(this);
    this.resize = this.resize.bind(this);
    this.state = {
      surveyTitle: "",
      copyButtonText: "COPY",
      yesButtonDisabled: true,
      windowWidth: window.innerWidth,
    };
    this.helpLink = helpLink;
  }

  handleClose = (e, type) => {
    if (
      (type === "backdropClick" || type === "escapeKeyDown") &&
      this.props.dialog.dialogType !== DIALOG_TYPE.ERROR
    )
      this.props.dialogClose(false);
    this.setState({ copyButtonText: "COPY" });
  };

  handleYes = () => {
    if (this.props.dialog.dialogType === DIALOG_TYPE.CREATE_SURVEY) {
      let surveyTitle = this.state.surveyTitle;
      this.props.dialogClose(true, surveyTitle);
      this.setState({ surveyTitle: "", yesButtonDisabled: true });
    } else if (this.props.dialog.dialogType === DIALOG_TYPE.ACCESS_TOKEN) {
      this.handleLinkCopy();
    } else {
      this.props.dialogClose(true);
    }
  };

  handleNo = () => {
    this.props.dialogClose(false);
    this.setState({
      surveyTitle: "",
      yesButtonDisabled: true,
      copyButtonText: "COPY",
    });
  };

  handleChange = (value) => {
    let surveyTitle = value.trimLeft().substr(0, shortAnswerMaxCount);
    let yesButtonDisabled =
      surveyTitle && surveyTitle.length >= 3 ? false : true;
    this.setState({
      surveyTitle: surveyTitle,
      yesButtonDisabled: yesButtonDisabled,
    });
  };

  handleLinkCopy = () => {
    copy(this.props.dialog.text);
    this.setState({ copyButtonText: "COPIED" }, function () {
      setTimeout(
        function () {
          this.setState({ copyButtonText: "COPY" });
        }.bind(this),
        3000
      );
    });
    trackEvent(TRACK.EVENT.DASHBOARD_SEND_COPY);
  };

  componentDidUpdate(prevProps) {
    if (this.props.dialog.dialogType === DIALOG_TYPE.CREATE_SURVEY) {
      if (prevProps.dialog.surveyTitle !== this.props.dialog.surveyTitle) {
        this.setState({
          surveyTitle: this.props.dialog.surveyTitle,
          yesButtonDisabled: false,
        });
      } else if (
        !this.props.dialog.surveyTitle &&
        !this.state.yesButtonDisabled &&
        !this.state.surveyTitle
      ) {
        this.setState({ yesButtonDisabled: true });
      }
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  resize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  renderLoginErrorDialogContent = () => {
    return (
      <div className={s.dialogLoginErrorContentWrapper + " " + s.error}>
        <IconButton
          color={colors.walmartBlue}
          className={s.close}
          name="cancel"
          text=""
          handleClick={this.props.dialogClose.bind(this, false)}
          size="large"
        />
        <img className={s.loginErrorImage} src={loginErrorImage} alt="" />
        <DialogTitle id="alert-dialog-title" className={s.title}>
          {this.props.dialog.title}
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description" className={s.text}>
            <span className={s.wrap}> {this.props.dialog.text}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={s.actions}>
          <Button
            variant="contained"
            onClick={this.handleYes}
            className={this.props.classes.button}
            color="primary"
            data-testid="test-handle-yes"
          >
            {this.props.dialog.yesText}
          </Button>
        </DialogActions>
        <div className={s.helpLinkWrapper}>
          <a href={SSO_LOGIN_URL}>Try again</a>
        </div>
      </div>
    );
  };

  renderErrorDialogContent = () => {
    const correlationId = sessionStorage.getItem("reqCorrelationId");
    return (
      <div className={s.dialogContentWrapper + " " + s.error}>
        <MUIIconButton
          aria-label="close"
          onClick={this.props.dialogClose.bind(this, false)}
          className={s.closeIcon}
        >
          <CloseIcon />
        </MUIIconButton>

        <img src={samsTruckImg} alt="" />
        <DialogTitle id="alert-dialog-title" className={s.title}>
          <h3>{this.props.dialog.text}</h3>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <DialogContentText id="alert-dialog-description" className={s.text}>
            <span> Please try again later. </span>
            {correlationId && <div>Error Code - {correlationId}</div>}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={s.actions}>
          <Button
            variant="contained"
            onClick={this.handleYes}
            className={this.props.classes.button}
            color="primary"
          >
            {this.props.dialog.yesText}
          </Button>
        </DialogActions>
        <div className={s.helpLinkWrapper}>
          <a target="_blank" href={this.helpLink} rel="noopener noreferrer">
            Get Help
          </a>
        </div>
      </div>
    );
  };

  renderDatasourceInfoDialogContent = () => {
    const sampleSource = [
      ["Class", "Day", "Module"],
      ["Class 1", "Day 1", "Module 1"],
      ["Class 1", "Day 1", "Module 2"],
      ["Class 1", "Day 2", "Module 1"],
      ["Class 1", "Day 2", "Module 2"],
      ["Class 2", "Day 1", "Module 1"],
      ["Class 2", "Day 1", "Module 2"],
      ["Class 2", "Day 2", "Module 1"],
      ["Class 2", "Day 2", "Module 2"],
    ];
    return (
      <div style={{ textAlign: "justify" }} id="alert-dialog-description">
        In order for the multiple choice and dropdown choices to be dynamically
        generated the csv file has to be in a particular format. Click
        <CSVLink data={sampleSource} filename="sample_data_source.csv">
          <span> here </span>
        </CSVLink>{" "}
        to download a sample csv file.
      </div>
    );
  };

  renderDynamicChoicesInfoDialogContent = () => {
    return (
      <div style={{ textAlign: "justify" }} id="alert-dialog-description">
        <ul>
          <li>
            Static options are the choices that are provided by a survey
            creator.
          </li>
          <li>
            Dynamic options are generated based upon the data of a chosen column
            in a CSV.
          </li>
          <li>
            x Conditions can be added to a multiple-choice/selection and
            dropdown question with dynamic options to further customize the
            choices.
          </li>
          <li>
            Multiple conditions can be added to a multiple-choice/selection and
            dropdown question with dynamic options.
          </li>
          <li>
            To mark a multiple choice/selection or dropdown question with
            dynamic options and conditions as “required response” the survey
            creator needs to make sure that all the questions within the
            conditions will also need “required response” setting turned on.
          </li>
        </ul>
      </div>
    );
  };

  executeTestingFunctions(e) {
    this.props.dialogOpen({
      title: "",
      dialogType: DIALOG_TYPE.CREATE_SURVEY,
    });

    this.handleClose(e, "backdropClick");
    this.handleClose(e, "escapeKeyDown");
    this.handleYes();
    this.props.dialogOpen({
      title: "",
      dialogType: DIALOG_TYPE.ACCESS_TOKEN,
    });
    this.handleYes();
    this.handleNo();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div
          className={classes.dummyButton}
          data-testid="execute-testing-functions"
          onClick={(e) => this.executeTestingFunctions(e)}
        />
        <MuiDialog
          open={this.props.dialog.isOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          {this.props.dialog.dialogType === DIALOG_TYPE.LOGIN_ERROR ? (
            this.renderLoginErrorDialogContent()
          ) : this.props.dialog.dialogType === DIALOG_TYPE.ERROR ? (
            this.renderErrorDialogContent()
          ) : (
            <div className={s.dialogContentWrapper}>
              <DialogTitle id="alert-dialog-title">
                {this.props.dialog.title}
                <MUIIconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={this.props.dialogClose.bind(this, false)}
                >
                  <CloseIcon />
                </MUIIconButton>
              </DialogTitle>
              <DialogContent style={{ textAlign: "center" }} dividers>
                {this.props.dialog.dialogType === DIALOG_TYPE.COPY_LINK ? (
                  <div
                    id="alert-dialog-description"
                    className={s.link + " " + s.wrap}
                    title={this.props.dialog.text}
                  >
                    <p className={s.linkLimited}>{this.props.dialog.text}</p>
                    <p className={s.copy} onClick={this.handleLinkCopy}>
                      {this.state.copyButtonText}
                    </p>
                  </div>
                ) : this.props.dialog.dialogType ===
                  DIALOG_TYPE.DATASOURCE_INFO ? (
                  this.renderDatasourceInfoDialogContent()
                ) : this.props.dialog.dialogType ===
                  DIALOG_TYPE.DYNAMIC_CHOICES_INFO ? (
                  this.renderDynamicChoicesInfoDialogContent()
                ) : this.props.dialog.dialogType ===
                  DIALOG_TYPE.ACCESS_TOKEN ? (
                  <TokenDialogContent
                    copyButtonText={this.state.copyButtonText}
                    token={this.props.dialog.text}
                  />
                ) : (
                  <DialogContentText
                    id="alert-dialog-description"
                    className={s.text}
                  >
                    <div
                      className={s[this.props.dialog.textWrap] + " " + s.wrap}
                    >
                      {" "}
                      {this.props.dialog.text}
                    </div>
                  </DialogContentText>
                )}
                {this.props.dialog.dialogType === DIALOG_TYPE.COPY_REDIRECT && (
                  <a href={this.props.dialog.copyRedirectURL}>
                    Generate Survey Link
                  </a>
                )}
                {this.props.dialog.dialogType === DIALOG_TYPE.CREATE_SURVEY && (
                  <Text
                    required
                    label="Survey Title"
                    handleChange={this.handleChange}
                    value={this.state.surveyTitle}
                    helperText=""
                    className={s.textField}
                    maxChar={surveyTitleMaxCount}
                  />
                )}
                {this.props.dialog.dialogType === DIALOG_TYPE.RELEASE_LOG && (
                  <div style={{ textAlign: "left" }}>
                    <IconButton
                      color={colors.walmartBlue}
                      className={s.closeFixed}
                      name="cancel"
                      text=""
                      handleClick={this.props.dialogClose.bind(this, false)}
                      size="large"
                    />

                    {this.props.releaseNotes.map(
                      (release) =>
                        release.notes &&
                        release.notes.length > 0 && (
                          <>
                            <p>
                              <b>{release.id}</b>
                            </p>
                            <ul>
                              {release.notes.map((note, index) => (
                                <li key={index}>{note}</li>
                              ))}
                            </ul>
                          </>
                        )
                    )}
                  </div>
                )}
              </DialogContent>
              {this.props.dialog.dialogType !== DIALOG_TYPE.RELEASE_LOG && (
                <DialogActions className={s.actions}>
                  {this.props.dialog.noText && (
                    <Button
                      data-testid="close-button"
                      onClick={this.handleNo}
                      className={classes.button}
                      autoFocus
                    >
                      {this.props.dialog.noText}
                    </Button>
                  )}
                  {this.props.dialog.yesText && (
                    <Button
                      variant={
                        this.props.dialog.dialogType ===
                        DIALOG_TYPE.ACCESS_TOKEN
                          ? "outlined"
                          : "contained"
                      }
                      onClick={this.handleYes}
                      data-testid="yes-button"
                      disabled={
                        this.props.dialog.dialogType ===
                        DIALOG_TYPE.CREATE_SURVEY
                          ? this.state.yesButtonDisabled
                          : false
                      }
                      className={classes.button}
                      color="primary"
                    >
                      {this.props.dialog.yesText}
                    </Button>
                  )}
                </DialogActions>
              )}
            </div>
          )}
        </MuiDialog>
      </div>
    );
  }
}

Dialog.propTypes = {
  handleClose: PropTypes.func,
};

Dialog.defaultProps = {
  handleClose: noop,
};

const mapStateToProps = (state) => ({
  dialog: state.dialog,
  survey: state.surveyEdit.data,
  releaseNotes: state.releaseNotes.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...dialogActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dialog));
