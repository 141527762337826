/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import config from "./index";
import getCurrentEnv from "../utils/env";

const configuration = () => {
  const path = `${
    typeof process !== "undefined" &&
    process.env &&
    process.env.REACT_APP_ENV_HOST
      ? process.env.REACT_APP_ENV_HOST
      : getCurrentEnv()
  }`;

  return config[path];
};

export default configuration();
