import _ from "lodash";
import { getQuestionOptions } from "../../services/api";

const GET = "questionOptions/GET";
const GET_COMPLETED = "questionOptions/GET_COMPLETED";

const initialState = {
  data: { en: {}, es: {}, fr: {}, zh: {} },
  requestInProgress: false,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case GET:
      state.requestInProgress = true;
      break;
    case GET_COMPLETED:
      state.requestInProgress = false;
      state.data = { ...state.data, ...action.data };
      break;
    default:
      break;
  }
  return state;
};

const requestQuestionOptions = (lang) => {
  return (dispatch) => {
    dispatch({
      type: GET,
    });
    const questionOptions = getQuestionOptions(lang);
    return questionOptions
      .then((data) => {
        return dispatch({
          type: GET_COMPLETED,
          data,
        });
      })
      .catch(() => {});
  };
};

const getRequestInProgress = (state) =>
  state.questionOptions?.requestInProgress;

const questionOptionsData = (state) => state.questionOptions?.data;

export const actions = {
  getQuestionOptions: requestQuestionOptions,
};

export const selectors = {
  getRequestInProgress,
  questionOptionsData,
};
