import _ from "lodash";
import { createSubmission } from "../../services/api";
import { trackAndShowError, trackAndShowErrorSubmitFailed } from "../dialog";
import {
  SURVEY_UPDATED_ERROR_MESSAGE,
  SURVEY_SAVE_FAILED_ERROR_MESSAGE,
  SURVEY_INVALID_PARAMS_MSG,
} from "../constants";
import { trackTrace } from "../../services/commons";
import { deleteData as autoSaveDeleteData } from "../autoSave";
const MODIFY = "submissionEdit/MODIFY";
const SAVE = "submissionEdit/SAVE";
const SAVE_SUCCESS = "submissionEdit/SAVE_SUCCESS";
const ERROR_SURVERY_DELETED = "submissionEdit/ERROR_SURVERY_DELETED";
const ERROR_SURVERY_DEACTIVATED = "submissionEdit/ERROR_SURVERY_DEACTIVATED";
const ERROR_SAVE_FAILED = "submissionEdit/ERROR_SAVE_FAILED";

const initialState = {
  saveInProgress: false,
  data: {},
  lastSavedId: null,
  surveyDeleted: false,
  surveyDeactivated: false,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case MODIFY:
      state.data = action.data;
      break;
    case SAVE:
      state.saveInProgress = true;
      break;
    case SAVE_SUCCESS:
      state.saveInProgress = false;
      state.lastSavedId = action.id;
      break;
    case ERROR_SAVE_FAILED:
      state.saveInProgress = false;
      break;
    case ERROR_SURVERY_DELETED:
      state.saveInProgress = false;
      state.surveyDeleted = true;
      break;
    case ERROR_SURVERY_DEACTIVATED:
      state.saveInProgress = false;
      state.surveyDeactivated = true;
      break;
    default:
      break;
  }
  return state;
};

export const modify = (data) => {
  return (dispatch) => {
    dispatch({
      type: MODIFY,
      data: data,
    });
  };
};

export const save = (lang = "") => {
  return (dispatch, getState) => {
    dispatch({
      type: SAVE,
    });
    let submissionTime = new Date().getTime() / 1000;
    return createSubmission(
      getState().submissionEdit.data.surveyId,
      getState().submissionEdit.data,
      submissionTime,
      getState().submissionEdit.data.anonymousAccess,
      getState().submissionEdit.data.modified_on,
      lang,
      getState().submissionEdit.data.campaignID,
      getState().submissionEdit.data.customerID,
      getState().submissionEdit.data.waveID
    )
      .then(() => {
        dispatch({
          type: SAVE_SUCCESS,
          id: getState().submissionEdit.data.surveyId,
        });
        dispatch(autoSaveDeleteData(getState().submissionEdit?.data?.surveyId));
      })
      .catch((error) => {
        error = JSON.parse(error);
        if (error.message.code === "SVSUB_E013") {
          //TODO handle when survey is closed while taking the survey
          const trackMessage =
            "Error Survey id " +
            getState().submissionEdit.data.surveyId +
            " submitted on " +
            submissionTime +
            "is closed while its been taken";
          trackTrace(
            "ERROR_CLOSED_SURVEY_TAKEN",
            trackMessage,
            JSON.stringify(error)
          );
          dispatch({
            type: ERROR_SURVERY_DEACTIVATED,
          });
        } else if (error.message.code === "token-expired") {
          //wait until redirecting to sso
          //by default loader will be shown
        } else if (error.message.code === "SVSUB_E011") {
          //Survey Modified while its been taken
          const trackMessage =
            "Error while Saving submission for the survey id " +
            getState().submissionEdit.data.surveyId +
            " submitted on";
          return dispatch(
            trackAndShowError(
              "ERROR_MODIFIED_SURVEY_TAKEN",
              trackMessage,
              JSON.stringify(error),
              SURVEY_UPDATED_ERROR_MESSAGE
            )
          );
        } else if (error.message.code === "SVSUB_E012") {
          //Survey deleted/unavailable while its been taken
          const trackMessage =
            "Error while Saving submission for the survey id " +
            getState().submissionEdit.data.surveyId +
            " submitted on";
          trackTrace(
            "ERROR_DELETED_SURVEY_TAKEN",
            trackMessage,
            JSON.stringify(error)
          );
          dispatch({
            type: ERROR_SURVERY_DELETED,
          });
        } else if (
          error === "Internal error" ||
          error.message.code === "Internal error"
        ) {
          // Survey Save Failed due to HTTP 500, 503 or > 500 errors from the API.
          // Don't lose the Submission Data. Prompt Survey Taker to resubmit.
          dispatch({
            type: ERROR_SAVE_FAILED,
          });

          const trackMessage =
            "Error while Saving submission for the survey id " +
            getState().submissionEdit.data.surveyId +
            " submitted on";
          return dispatch(
            trackAndShowErrorSubmitFailed(
              "ERROR_SURVEY_SUBMIT_FAILED",
              trackMessage,
              JSON.stringify(error),
              SURVEY_SAVE_FAILED_ERROR_MESSAGE
            )
          );
        } else if (error.message === "INVALID_PARAMS") {
          const trackMessage =
            "Error while Saving submission for the survey id " +
            getState().submissionEdit.data.surveyId +
            "submitted on";
          return dispatch(
            trackAndShowError(
              "ERROR_SURVEY_SUBMISSION",
              trackMessage,
              JSON.stringify(error),
              SURVEY_INVALID_PARAMS_MSG
            )
          );
        } else {
          //show error pop up
          const trackMessage =
            "Error while Saving submission for the survey id " +
            getState().submissionEdit.data.surveyId +
            " submitted on";
          return dispatch(
            trackAndShowError(
              "ERROR_SURVEY_SUBMISSION",
              trackMessage,
              JSON.stringify(error)
            )
          );
        }
      });
  };
};

export const actions = {
  submissionEditModify: modify,
  submissionEditSave: save,
};
