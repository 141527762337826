export const REQUEST = "survey/REQUEST";
export const REQUEST_SUCCESS = "survey/REQUEST_SUCCESS";
export const REQUEST_FAILED = "survey/REQUEST_FAILED";
export const ALREADY_TAKEN = "survey/ALREADY_TAKEN";
export const REQUEST_QUESTIONS_SUCCESS = "survey/REQUEST_QUESTIONS_SUCCESS";
export const DELETE = "survey/DELETE";
export const DELETE_SUCCESS = "survey/DELETE_SUCCESS";
export const CREATE = "survey/CREATE";
export const CREATE_SUCCESS = "survey/CREATE_SUCCESS";
export const CLONE = "survey/CLONE";
export const CLONE_SUCCESS = "survey/CLONE_SUCCESS";
export const SURVEY_PUBLISH = "survey/SURVEY_PUBLISH";
export const UPDATE_NAME = "survey/UPDATE_NAME";
export const CLEAR_SURVEY_ID = "survey/CLEAR_SURVEY_ID";
export const PUBLISHED = "published",
  DRAFTS = "drafts",
  INACTIVE = "inactive";
export const PREVIEW_AUTH = "PREVIEW_AUTH";
export const SET_SURVEY_THEME = "SET_SURVEY_THEME";
export const SET_CLOSING_MESSAGE = "SET_CLOSING_MESSAGE";
export const SET_GREETINGS = "SET_GREETINGS";
export const SET_GREETINGS_TRANSLATION = "SET_GREETINGS_TRANSLATION";
export const SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES";
export const SET_THEME_HEADER_AND_LOGO = "SET_THEME_HEADER_AND_LOGO";
export const SET_SURVEY_EXPERIENCE_TYPE = "SET_SURVEY_EXPERIENCE_TYPE";
