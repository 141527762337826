import colors from "../../../style/colors";

import { theme } from "../../../App";
const styles = () => ({
  surveyHeader: {
    display: "flex",
    flex: 1,
    height: 64,
    boxShadow: "0 8px 6px -6px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    position: "relative",
    zIndex: 99999,
  },
  headerLogoBox: {
    boxSizing: "border-box",
    marginLeft: 30,
  },
  headerLogo: {
    width: 40,
    height: 40,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    height: "60px",
    backgroundColor: colors.primaryContrastText,
    padding: "12px 16px 0",
    position: "relative",
    zIndex: 9999,
    [theme.breakpoints.up("sm")]: {
      padding: "12px 30px 0",
    },
  },
  headerBoxMenu: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  headerBoxLeftMenu: {
    padding: "5px 10px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 30px",
    },
  },
  headerBoxLeftMenuItems: {
    display: "flex",
    flexDirection: "row",
  },
  headerBoxMenuItem: {
    marginRight: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  headerBoxLeftLogo: {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  feedbackShareOptions: {
    marginTop: 23,
    display: "flex",
    alignItems: "flex-end",
    "& > span": {
      marginRight: 20,
      cursor: "pointer",
      "&:hover": {
        color: "#333",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "& > span": {
        marginRight: 24,
        cursor: "pointer",
      },
    },
  },
  accountMenu: {
    backgroundColor: "#fff",
    width: 40,
    height: 40,
    borderRadius: 20,
    textAlign: "center",
    lineHeight: 2.6,
    fontWeight: 600,
    color: "#000",
    marginTop: 12,
  },
  logo: {
    width: 36,
    height: 40,
    objectFit: "contain",
  },
  logoText: {
    marginLeft: 17,
    height: 33,
    fontFamily: "Bogle-Light",
    fontSize: "24px",
    fontWeight: "300",
    letterSpacing: "0.25px",
    textAlign: "left",
    color: "#333",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 24,
    },
  },
  createButtonDiv: {
    position: "fixed",
    right: 4,
    bottom: 54,
    zIndex: 1,
    marginRight: 0,
    marginTop: 0,

    [theme.breakpoints.up("sm")]: {
      position: "static",
      marginRight: 12,
      marginTop: 35,
    },
  },
  //   createButtonDiv: {
  //     position: 'absolute',
  //     right: 12,
  //     bottom: 24,
  //     zIndex: 1000,
  //     [theme.breakpoints.up('sm')]: {
  //       top: 154 - 28,
  //       right: 28
  //     }
  //     },
  activeSelection: {
    fontFamily: "Bogle-Regular",
    fontSize: "16px",
    color: "#333",
    display: "flex",
    flexDirection: "column",
  },
  activeSelectionBorder: {
    marginTop: 4,
    borderRadius: "100px 100px 0px 0px",
    height: "4px",
    backgroundColor: colors.primary,
    transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  inActive: {
    fontFamily: "Bogle-Light",
    fontSize: "16px",
    color: "#333",
    display: "flex",
    flexDirection: "column",
  },
  inActiveBorder: {
    marginTop: 4,
    borderRadius: "100px 100px 0px 0px",
    height: "4px",
    backgroundColor: colors.primaryContrastText,
  },
  userInitials: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
  headerLogoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default styles;
