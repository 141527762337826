import "core-js/stable";
import React from "react";
import App from "./App";
import { render } from "react-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "./locales";

const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);

render(<App />, document.getElementById("root"));

//Fix for IE
String.prototype.trimLeft =
  String.prototype.trimLeft ||
  function () {
    return this.replace(/^\s+/, "");
  };

String.prototype.trim =
  String.prototype.trim ||
  function () {
    return this.replace(/^\s+|\s+$/g, "");
  };

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(str, newStr);
    }
    // If a string
    return this.replace(new RegExp(str, "g"), newStr);
  };
}

// polyfill for requestIdleCallback
if (!window.requestIdleCallback) {
  window.requestIdleCallback = function (callback, options) {
    setTimeout(callback, options.timer);
  };
}
