import ReactGA from "react-ga";
import config from "../../config/config";

class Analytics {
  isAnalyticsEnabled = true;
  constructor() {
    let trackingEnabledStr = config.ANALYTICS_ENABLED;
    let trackingCode = config.ANALYTICS_TRACKING_CODE;
    let appVersion = config.ANALYTICS_APP_VERSION;
    this.isAnalyticsEnabled = trackingEnabledStr === true;
    if (this.isAnalyticsEnabled) {
      ReactGA.initialize(trackingCode, {
        testMode: process.env.NODE_ENV === "test",
      });
      ReactGA.set({
        appName: "surveysays",
        appId: "surveysays",
        appVersion: appVersion,
      });
    }
  }

  sendPageView(page) {
    if (this.isAnalyticsEnabled) {
      ReactGA.pageview(page);
    }
  }
}

export default new Analytics();
