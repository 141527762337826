import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import s from "./style.module.scss";
import getPath from "../../../services/getPath";
import { actions as authActions } from "../../../modules/auth";
import { actions as userInfoActions } from "../../../modules/userInfo";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AccountMenu from "../AccountMenu";
import { checkIfTokenExists } from "../../../services/sso";
import cookie from "react-cookies";
import style from "./styles";
import walmartClassicLogo from "../../../images/walmartHeader/classic@2x.png";
import samsClubLogo from "../../../images/walmartHeader/samsClub@2x.png";
import walmartGtsLogo from "../../../images/walmartHeader/walmartGts@2x.png";
import { THEME_GLOBAL_TECHNOLOGY, THEME_SAM_CLUB } from "../../constants";
import { Divider } from "@mui/material";

// import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
// import i18n from "../../../locales";

function getImageBasedOnTheme(themeName) {
  switch (themeName) {
    case THEME_GLOBAL_TECHNOLOGY:
      return walmartGtsLogo;
    case THEME_SAM_CLUB:
      return samsClubLogo;
    // case THEME_WALMART_CLASSIC:
    // case THEME_INTERNATIONAL:
    default:
      return walmartClassicLogo;
  }
}

const Header = (props) => {
  const { t } = useTranslation(["dashboard"]);
  const [hasMobileAuth, setHasMobileAuth] = useState(false);
  const { pathname } = window.location;

  // for these routes we will show the full Header
  const fullHeaderRoutes = ["/create"];
  const hiddenHeaderRoutes = ["/results", "/create"];

  // for these routes we will show the survey header
  const surveyHeaderRoutes = ["/survey", "/preview", "/done"];

  const useStyles = makeStyles((props) => style(props));
  const styleTheme = useTheme();
  const styles = useStyles({ ...props, styleTheme });
  const dispatch = useDispatch();

  /* these are coming from redux state */
  const { auth, survey } = useSelector((state) => state);
  const { visibleThemeHeaderNLogo, currentSurveyId } = survey;
  const { fontColor } = survey.currentTheme;
  // const { active} = pageInfo

  const anonymousAccess =
    Object.values(survey?.data)[0]?.thirdPartySettings?.anonymous_access ||
    false;
  useEffect(() => {
    if (checkIfTokenExists() === true) {
      dispatch(authActions.authSetToken(cookie.load("accessToken")));
    }
    const urlParams = new URLSearchParams(window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setHasMobileAuth(urlParams.get("hasMobileAuth"));
  }, []);

  useEffect(() => {
    if (pathname === "/" || pathname.startsWith("/create")) {
      dispatch(userInfoActions.getUserInfo());
    }
  }, [pathname, dispatch]);

  let logo = walmartClassicLogo;
  let surveyAnswerHeaderColor = "#fff";
  if (currentSurveyId && survey.data[currentSurveyId]) {
    const { theme } = survey.data[currentSurveyId].thirdPartySettings;
    logo = getImageBasedOnTheme(theme);
    if (theme === THEME_GLOBAL_TECHNOLOGY) {
      surveyAnswerHeaderColor = "#041f41";
    }
  }

  let hiddenHeader = false;
  let listHeader = false;
  let answerHeader = false;

  hiddenHeader =
    hiddenHeaderRoutes.filter((route) => pathname.includes(route)).length > 0;
  listHeader =
    fullHeaderRoutes.filter((route) => pathname.includes(route)).length > 0;
  answerHeader =
    surveyHeaderRoutes.filter((route) => pathname.includes(route)).length > 0;

  if (pathname === "/") {
    listHeader = true;
  }

  // const handleLanguageChange = (event) => {
  //   dispatch(userInfoActions.updateLanguagesPreference(event.target.value));
  // };

  if (hiddenHeader) {
    return null;
  }

  if (answerHeader) {
    return (
      <div
        id="survey-header"
        className={styles.surveyHeader}
        style={{ backgroundColor: surveyAnswerHeaderColor }}
      >
        {visibleThemeHeaderNLogo && (
          <div className={styles.headerLogoBox}>
            <img src={logo} alt="walmart logo" className={styles.headerLogo} />
          </div>
        )}
        {/* Terms and Conditions */}
        <div className={s.privacyPolicy}>
          {
            <AccountMenu
              hasMobileAuth={hasMobileAuth && hasMobileAuth === "true"}
              surveyTaker={true}
              anonymousAccess={anonymousAccess}
            />
          }
          <Divider />
        </div>
      </div>
    );
  } else if (listHeader) {
    return (
      <>
        <Grid
          container
          direction="column"
          className={styles.headerBox}
          style={{ backgroundColor: fontColor, color: fontColor }}
        >
          <div className={styles.headerLogoContainer}>
            <a
              href={getPath("/")}
              className={styles.headerBoxLeftLogo}
              data-testid="feedbackally"
              aria-label="Feedback Ally Logo"
            >
              <img className={styles.logo} src={logo} alt="logo" />
              <span className={styles.logoText}>{t("title")}</span>
            </a>

            <div className={styles.userInitials} data-testid="user-profile">
              {/* <LanguageDropdown    //commented this to remove static text due multilinual phase 2 release 
                value={i18n.language}
                onChange={handleLanguageChange}
              /> */}
              <div className={auth && auth.token ? "" : s.accountDiv}>
                {auth && auth.token && <AccountMenu />}
              </div>
            </div>
          </div>
        </Grid>
      </>
    );
  } else {
    return null;
  }
};

export default Header;
