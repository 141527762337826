import { QUESTION_TYPES } from "../../components/constants";

export const syncSourceFilesWithVariables = (
  sourceFiles = [],
  questions = []
) => {
  const sourceFileSet = new Set(
    sourceFiles.map(({ identifier }) => identifier)
  );

  const updatedQuestions = questions.map((question) => {
    if (
      question.type === QUESTION_TYPES.SOURCE_FILE_VARIABLE &&
      question.sourceInfo
    ) {
      return sourceFileSet.has(question.sourceInfo.file.identifier)
        ? question
        : {
            ...question,
            sourceInfoMissing: true,
          };
    }

    return question;
  });
  return updatedQuestions;
};

export const getFileIdentifiersToFetch = (questions = []) => {
  const fileIdentifiers = [];
  const importedSourceVariables = [];
  questions.forEach((question) => {
    if (supportsImportingSFLV(question.type)) {
      importedSourceVariables.push(...(question.importedSourceVariables || []));
    }
  });

  questions.forEach((question) => {
    if (
      question.type === QUESTION_TYPES.SOURCE_FILE_VARIABLE &&
      importedSourceVariables.includes(question.id)
    ) {
      fileIdentifiers.push(question.sourceInfo?.file?.identifier);
    }
  });

  return fileIdentifiers.filter(Boolean);
};

export const filterSFLVData = (sourceInfo = {}, sourceFileMap = {}) => {
  if (sourceFileMap[sourceInfo.file?.identifier]) {
    // first row is the column header hence removing it
    const fileData = sourceFileMap[sourceInfo.file?.identifier].slice(1);
    const values = [];
    const { logicGate = "and", filters = [], column } = sourceInfo;

    fileData.forEach((row) => {
      if (logicGate === "and") {
        if (
          row[column.idx] &&
          filters.every((filter) => checkFileTypeFilter(filter, row, fileData))
        ) {
          values.push(row[column.idx]);
        }
      } else if (logicGate === "or") {
        if (
          row[column.idx] &&
          filters.some((filter) => checkFileTypeFilter(filter, row, fileData))
        ) {
          values.push(row[column.idx]);
        }
      }
    });

    return values;
  }
  return [];
};

const checkFileTypeFilter = (filter, row, fileData) => {
  return (
    row[filter.column.idx] === fileData[filter.value.idx][filter.column.idx]
  );
};

export const supportsImportingSFLV = (type) => {
  return ["radios", "checkboxes", "select", "ranking"].includes(type);
};
