import _ from "lodash";
import noop from "../../services/noop";
import { helpLink } from "../../services/api";
import { trackTrace } from "../../services/commons";
import { DIALOG_TYPE } from "../../components/constants";
const OPEN = "dialog/OPEN";
const CLOSE = "dialog/CLOSE";

const initialState = {
  isOpen: false,
  title: "",
  text: "",
  yesText: "",
  noText: "",
  dialogType: "",
  category: "",
  targetAction: "",
  copyRedirectURL: "",
};

let handleYes;
let handleNo;

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case OPEN:
      state.isOpen = true;
      state.title = action.title;
      state.text = action.text;
      state.yesText = action.yesText;
      state.noText = action.noText;
      state.textWrap = action.textWrap;
      state.dialogType = action.dialogType;
      state.surveyTitle = action.surveyTitle;
      state.category = action.category;
      state.targetAction = action.targetAction;
      state.copyRedirectURL = action.copyRedirectURL;
      break;
    case CLOSE:
      state.isOpen = false;
      state.surveyTitle = "";
      break;
    default:
      break;
  }
  return state;
};

export const open = ({
  title = "Title",
  text = "",
  yesText = "OK",
  noText = "Cancel",
  onYes = noop,
  onNo = noop,
  textWrap = "",
  dialogType = "",
  surveyTitle = "",
  category = "",
  targetAction = "",
  copyRedirectURL = "",
} = {}) => {
  return (dispatch) => {
    dispatch({
      type: OPEN,
      title,
      text,
      yesText,
      noText,
      textWrap,
      dialogType,
      surveyTitle,
      category,
      targetAction,
      copyRedirectURL,
    });

    handleYes = onYes;
    handleNo = onNo;
  };
};

export const trackAndShowError = (
  trackEventId,
  trackMessage,
  err,
  errorMessage = "Something went wrong!!!"
) => {
  return (dispatch) => {
    trackTrace(trackEventId, trackMessage, err);
    window.console.error(err);
    let dialogType = DIALOG_TYPE.ERROR;
    let title = "Oh no!";
    if (err && typeof err === "string" && err.startsWith("403")) {
      dialogType = DIALOG_TYPE.USER_ROLE_ERROR;
      title = "Error";
      errorMessage =
        err.replace("403:", "") +
        " Please click refresh to perform some other operation.";
    }
    dispatch(
      open({
        title: title,
        text: errorMessage,
        yesText: "REFRESH",
        noText: "",
        dialogType: dialogType,
        onYes: () => {
          window.location.reload();
        },
      })
    );
  };
};

export const trackAndShowErrorSubmitFailed = (
  trackEventId,
  trackMessage,
  err,
  errorMessage = "Something went wrong!!!"
) => {
  return (dispatch) => {
    trackTrace(trackEventId, trackMessage, err);
    window.console.error(errorMessage);
    let title = "Oh no!";

    dispatch(
      open({
        title: title,
        text: errorMessage,
        yesText: "OK",
        dialogType: "error",
      })
    );
  };
};

export const trackAndShowErrorForUserVoice = (
  trackEventId,
  trackMessage,
  err
) => {
  return (dispatch) => {
    trackTrace(trackEventId, trackMessage, err);
    window.console.error(err);

    dispatch(
      open({
        title: "Oops!",
        text: "Sorry, we are having trouble sending your feedback. Please try again later.",
        yesText: "OK",
        noText: "",
        dialogType: "error",
      })
    );
  };
};

export const showLoginError = (err) => {
  return (dispatch) => {
    window.console.error(err);

    dispatch(
      open({
        title: "Single Sign On (SSO) Failed",
        text: "Contact the feedbackally support team for assistance",
        yesText: "CONTACT",
        noText: "",
        dialogType: DIALOG_TYPE.LOGIN_ERROR,
        onYes: () => {
          window.location.href = helpLink;
        },
      })
    );
  };
};

export const showTerms = () => {
  return (dispatch) => {
    dispatch(
      open({
        title: "Terms of Use",
        text: "Thank you for using the Walmart Survey Tool!\n\nYour contact details (if requested), including your name, phone number,\naddress and email address, may be used by Walmart to carry out the survey\nand/or to communicate with you about the survey.\n\nSurveys should never ask for highly sensitive or protected information.\nClick on more info for information on data classification",
        noText: "Close",
        yesText: "More info",
        onYes: () => {
          window.location.href =
            "https://one.walmart.com/content/uswire/en_us/work1/policies/non-people-policies/data-governance/global-data-governance-dg-01/global-data-governance-policy--dc-dg-01-.html";
        },
      })
    );
  };
};

export const showDataSourceError = (dependentQuestions) => {
  return (dispatch) => {
    let dialogText =
      "You have following questions dependent on this data source.";
    dependentQuestions.map((q) => {
      dialogText += "\n" + q;
    });
    dialogText += "\n\nPlease modify questions to remove this dependency.";
    dispatch(
      open({
        title: "Error",
        text: dialogText,
        textWrap: "lineBreak",
        noText: "Close",
        yesText: null,
      })
    );
  };
};

export const showDynamicChoicesInfo = (dialogType) => {
  return (dispatch) => {
    dispatch(
      open({
        title: "Information",
        textWrap: "lineBreak",
        dialogType: dialogType,
        noText: "Close",
        yesText: null,
      })
    );
  };
};

export const close = (yes, surveyTitle) => {
  return (dispatch) => {
    if (yes) handleYes(surveyTitle);
    else handleNo();

    dispatch({
      type: CLOSE,
    });
  };
};

export const actions = {
  dialogOpen: open,
  dialogClose: close,
  dialogShowError: trackAndShowError,
  dialogShowTerms: showTerms,
  trackAndShowError: trackAndShowError,
  dialogShowLoginError: showLoginError,
  dialogShowErrorForUserVoice: trackAndShowErrorForUserVoice,
};
