export const loadQuestionOptionsForCV = (choices, questions) => {
  return choices?.map((item) => {
    if (item.source) {
      const referencedQuestion = questions.find(
        (data) => data.id === item.source.parentId
      );
      if (referencedQuestion?.choices?.length > 0) {
        let referencedChoice = {};
        if (
          referencedQuestion.type === "image" ||
          referencedQuestion.type === "ranking"
        ) {
          referencedChoice = referencedQuestion.choices.find(
            (val) => `Value_${val.value}` === item.value
          );
        } else if (referencedQuestion.type === "range") {
          referencedChoice = referencedQuestion.choices.find(
            (val) => `Value_${val.value}` === item.value
          );
        } else {
          referencedChoice = referencedQuestion.choices.find(
            (val) => val.value === item.value
          );
        }
        return { ...item, label: referencedChoice?.label };
      }
      return item;
    }
    return item;
  });
};
