import dashboard from "./dashboard.json";
import surveyBuilder from "./surveyBuilder.json";
import surveyTaker from "./surveyTaker.json";
import common from "./common.json";

export default {
  common,
  dashboard,
  surveyBuilder,
  surveyTaker,
};
