const styles = () => ({
  privacyModal: {
    width: "100%",
    height: "240px",
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    background: "white",
    marginBottom: 0,
    borderTopRightRadius: "30px",
    borderTopLeftRadius: "30px",
    overflowY: "scroll",
  },

  heading: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
    padding: "16px 25px",
    position: "sticky",
    top: 0,
    background: "#ffffff",
    zIndex: 99,
  },

  alignIcons: {
    margin: "auto",
    display: "flex",
    borderBottomStyle: "solid",
    borderBottomColor: "lightgray",
    borderWidth: "1px",
  },
  alignIconsLogout: {
    margin: "auto",
    display: "flex",
    padding: "0px",
  },
  iconCenter: {
    marginTop: "11px",
    fontSize: 15,
  },

  openicon: {
    fontSize: 20,
    paddingLeft: "5px",
    alignSelf: "center",
  },

  iconCorners: {
    padding: 0,
    minWidth: "10px",
  },

  iconClose: {
    marginTop: "6px",
    float: "right",
    color: "#333333",
  },

  iconBack: {
    marginTop: "6px",
    float: "left",
    fontSize: 25,
    color: "#333333",
  },

  footerLinksTerms: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#333333",
    textTransform: "none",
    marginLeft: "10px",
    fontSize: 15,
    justifyContent: "space-between",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
      fontWeight: "700",
      backgroundColor: "none",
    },
  },

  footerLinksPrivacy: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#333333",
    marginTop: "6px",
    marginLeft: "17px",
    fontSize: 15,
    display: "flex",
    width: "100%",
    paddingBottom: "7px",
    justifyContent: "space-between",
    "&:hover": {
      textDecoration: "none",
      fontWeight: "700",
      backgroundColor: "none",
    },
  },

  privacyButton: {
    backgroundColor: "none",
    color: "#333333",
    cursor: "pointer",
    textDecoration: "none",
    textTransform: "none",
    marginLeft: "10px",
    fontSize: 15,
    justifyContent: "space-between",
    width: "100%",
    "&:hover": {
      textDecoration: "none",
      fontWeight: "700",
      backgroundColor: "none",
    },
  },

  footerLinksPrivacyLink: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#333333",
    fontSize: 15,
    "&:hover": {
      textDecoration: "none",
      color: "#000000",
    },
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottomColor: "lightgray",
    borderBottomStyle: "solid",
  },

  whiteicon: {
    color: "white",
  },

  blackicon: {
    color: "black",
  },
});

export default styles;
