import React from "react";
import s from "./style.module.scss";
import womanImage from "../../../images/maintenancePage/woman.png";
import womanImage2 from "../../../images/maintenancePage/woman@2x.png";
import womanImage3 from "../../../images/maintenancePage/woman@3x.png";
import spark from "../../../images/spark/spark_logo.svg";

class MaintenancePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={s.MaintenanceScreen}>
        <div className={s.header}>
          <img className={s.logo} src={spark} alt="logo" />
          <span className={s.title}>
            Feedback<b>ally</b>
          </span>
        </div>
        <div className={s.heading}>
          Big things are happening
          <div className={s.subHeading}>
            We’re currently down for maintenance. We’ll be back up shortly.
          </div>
        </div>
        <div className={s.imageContainer}>
          <img
            className={s.womanImage}
            src={womanImage}
            srcSet={[womanImage2, "2x,", womanImage3, "3x"].join(" ")}
            alt="maintenance"
          />
        </div>
      </div>
    );
  }
}

export default MaintenancePage;
