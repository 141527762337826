import colors from "../../../style/colors";

const styles = () => ({
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "100%",
    /* top: 0; */
    backgroundColor: "#fff",
    height: "80%",
  },
  flexDir: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    height: 8,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: "#dadce0",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: colors.primary,
  },
  loader: {
    margin: "24px 0 27px 0",
    width: 210,
  },
  conversion: {
    fontFamily: "Bogle",
    fontSize: 24,
    letterSpacing: 0.25,
    margin: "0 4px",
    color: "#333333",
  },
  logo: {
    height: 60,
    objectFit: "contain",
  },
  ally: {
    fontFamily: "Bogle",
    fontSize: 24,
    letterSpacing: 0.25,
    color: "#06f27b",
    margin: "0 4px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontSize: 30,
    fontFamily: "Bogle-Light",
    fontWeight: "normal",
  },
});

export default styles;
