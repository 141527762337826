import React from "react";
import { actions as dialogActions } from "../../../modules/dialog";
import { SSO_LOGOUT_URL } from "../../../services/constants";
import cookie from "react-cookies";
import { logout } from "../../../services/api";
import { parseJwtToken } from "../../../services/commons";
import { trackEvent } from "../../../services/commons";
import { DIALOG_TYPE, MAX_MOBILE_SIZE } from "../../constants";
import { TRACK } from "../../constants";
import config from "../../../config/config";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PrivacyPolicyDialog from "../PrivacyPolicyDialog";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

const USER_NAME_MAX_DISPLAY_LENGTH = 15;
const REPORT_AN_ISSUE_URL = config.HELP_LINK;

const AccountMenu = ({
  surveyTaker = false,
  anonymousAccess = false,
  hasMobileAuth = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  const payload = parseJwtToken();
  let userName = payload?.fullname;
  if (userName && userName.length > USER_NAME_MAX_DISPLAY_LENGTH) {
    userName = userName.substr(0, USER_NAME_MAX_DISPLAY_LENGTH) + "...";
  }

  const getInitials = (name) => {
    let initials = "";
    if (name) {
      let splitName = name.split(" ");
      if (splitName[0] && splitName[0][0]) {
        initials += splitName[0][0];
      }
      if (splitName[1] && splitName[1][0]) {
        initials += splitName[1][0];
      }
    }
    return initials.toUpperCase();
  };

  const handleGetAccesstoken = () => {
    handleClose();
    let token = cookie.load("accessToken");
    if (token) {
      dispatch(
        dialogActions.dialogOpen({
          title: t("modal:getToken.title"),
          dialogType: DIALOG_TYPE.ACCESS_TOKEN,
          text: token,
          yesText: t("modal:getToken.copy"),
          noText: t("modal:getToken.close"),
        })
      );
    }
  };

  const handleLogout = () => {
    trackEvent(TRACK.EVENT.LOGOUT);
    // wait for the token to be deactivated in DB before redirecting to SSO logout page
    logout().then(() => {
      cookie.remove("accessToken", {
        path: "/",
        domain: `.${window.location.hostname}`,
      });
      window.appInsights.clearAuthenticatedUserContext();
      window.location.href = SSO_LOGOUT_URL;
    });
  };

  const reportAnIssue = () => window.open(REPORT_AN_ISSUE_URL, "_blank");
  const isMobile = window.innerWidth < MAX_MOBILE_SIZE;

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-haspopup="true"
        aria-controls={open ? "account-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        data-testid="account-avatar"
        disableRipple={anonymousAccess && !isMobile}
        disabled={anonymousAccess && !isMobile}
      >
        <Avatar
          alt={userName}
          sx={{
            backgroundColor: "primary.main",
            height: "32px",
            width: "32px",
          }}
        >
          {anonymousAccess ? (
            <PermIdentityIcon fontSize="medium" data-testid="anonymous-icon" />
          ) : (
            getInitials(userName)
          )}
        </Avatar>
      </IconButton>
      {isMobile && surveyTaker ? (
        <div>
          <PrivacyPolicyDialog
            hasMobileAuth={hasMobileAuth}
            open={open}
            handleLogout={handleLogout}
            setOpen={setOpen}
            anonymousAccess={anonymousAccess}
          />
        </div>
      ) : (
        !anonymousAccess && (
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            data-testid="account-menu"
          >
            {userName !== "" && <MenuItem disabled> {userName}</MenuItem>}
            {!surveyTaker && (
              <MenuItem data-testid="get-token" onClick={handleGetAccesstoken}>
                {t("dashboard:getToken")}
              </MenuItem>
            )}
            {!hasMobileAuth && (
              <MenuItem data-testid="logout" onClick={handleLogout}>
                {t("dashboard:logout")}
              </MenuItem>
            )}
            {!surveyTaker && (
              <MenuItem data-testid="report-issue" onClick={reportAnIssue}>
                {t("dashboard:reportAnIssue")}
              </MenuItem>
            )}
          </Menu>
        )
      )}
    </Box>
  );
};

export default AccountMenu;
