import _ from "lodash";
import { getQuestionBank } from "../../services/api";
import { trackAndShowError } from "../dialog";

const QUESTION_BANK = "questionBank/QUESTION_BANK";
const REQUEST = "questionBank/QUESTION_BANK_REQUEST";
const COMPLETED = "questionBank/QUESTION_BANK_COMPLETED";

const initialState = {
  questionBank: [],
  questionBankInProgress: false,
  requestBody: {
    page: 1,
    size: 20,
  },
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST:
      state.questionBankInProgress = true;
      break;
    case COMPLETED:
      state.questionBankInProgress = false;
      break;
    case QUESTION_BANK:
      state.questionBank = [...state.questionBank, ...action.data];
      state.questionBankInProgress = false;
      break;
    default:
      break;
  }
  return state;
};

export const questionBank = (requestBody) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST,
    });
    return getQuestionBank(requestBody)
      .then((data) => {
        dispatch({
          type: QUESTION_BANK,
          data,
        });
        return {
          hasNewData: data?.length > 0,
        };
      })
      .catch((response) => {
        const trackMessage = "Error in loading question bank questions - ";
        dispatch({
          type: COMPLETED,
        });
        dispatch(
          trackAndShowError(
            "ERROR_QUESTION_BANK_LOADING",
            trackMessage,
            response
          )
        );
        return {
          hasNewData: false,
        };
      });
  };
};

export const actions = {
  getQuestionBank: questionBank,
};
