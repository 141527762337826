const getCurrentEnv = () => {
  const env = "prod";
  const isServer = !(typeof window !== "undefined" && window.document);

  if (!isServer) {
    const { host } = window.location;

    if (host.includes("dev.") || host.includes("localhost")) {
      return "dev";
    } else if (host.includes("qa.")) {
      return "qa";
    } else if (host.includes("stage.")) {
      return "stage";
    } else if (host.includes("uat.")) {
      return "uat";
    }
  }

  return env;
};

export default getCurrentEnv;
