import { DEFAULT_PARENT_ID } from "../components/constants";

export default function placeBlockQuestionsNearBlock(questions) {
  const cache = {};
  const lookup = {};
  if (questions) {
    questions.forEach((question) => {
      if (question) {
        question.parentId = question.parentId || DEFAULT_PARENT_ID;
        if (!cache[question.parentId]) {
          cache[question.parentId] = [];
        }
        cache[question.parentId].push(question);
      }
    });
    const modifiedQuestions = questions
      .map((q) => {
        if (q && cache[q.id]) {
          return [q, cache[q.id]];
        } else if (q.parentId === DEFAULT_PARENT_ID) {
          return q;
        }
      })
      .flat(3)
      .filter((q) => {
        if (!q) return false;
        if (lookup[q.id]) return false;
        lookup[q.id] = true;
        return true;
      });
    return modifiedQuestions;
  }
  return [];
}
