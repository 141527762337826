import _ from "lodash";
import { getThemeImageList } from "../../services/api";
import config from "../../config/config";

const THEME_IMAGE_PREFIX = `${config.API_SERVER_URL}/static/theme-images/`;

const REQUEST_THEME_IMAGE = "themeImagesList/PRE_REQUEST";
const REQUEST_THEME_IMAGE_LIST = "themeImagesList/REQUEST";

const initialState = {
  themeName: "",
  themeImageList: [],
  requestThemeImageListInProgress: false,
  requestMadeBefore: false,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case REQUEST_THEME_IMAGE:
      state.requestThemeImageListInProgress = true;
      state.requestMadeBefore = true;
      state.themeName = action.data;
      state.themeImageList = [];
      break;
    case REQUEST_THEME_IMAGE_LIST:
      state.themeImageList = action.data.map((themeImage) => {
        return `${THEME_IMAGE_PREFIX}${state.themeName}/${themeImage}`;
      });

      state.requestThemeImageListInProgress = false;
      break;
  }
  return state;
};

export const requestThemeImageList = (themeName) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_THEME_IMAGE,
      data: themeName,
    });
    const themeImageList = getThemeImageList(themeName);
    return themeImageList
      .then((data) =>
        dispatch({
          type: REQUEST_THEME_IMAGE_LIST,
          data,
        })
      )
      .catch(() => {});
  };
};

export const actions = {
  getThemeImageList: requestThemeImageList,
};
