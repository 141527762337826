import {
  DEFAULT_PARENT_ID,
  QUESTION_TYPES,
  CONDITIONS,
} from "../../components/constants";
import { EQUATION } from "../constants";

export function checkRandomizeConflict(blockId, questions = []) {
  const blockQuestions = questions.filter((q) => q.parentId === blockId);
  const cache = {};
  blockQuestions.forEach((q) => (cache[q.id] = q));

  const conditionAmong = blockQuestions.some((q) => {
    // if the question is deactivated then it does
    // not affect randomization condition
    if (q.deactivated) return false;

    const condition = q["#conditions"];
    // if any of the block question depend on another block question
    // it will return true hence randomize is not possible
    const displayConditionConflict = condition?.displayConditions?.some(
      (dc) => {
        // if condition question id is in cache
        // it means its inside same block
        return cache[dc.question];
      }
    );

    // if any of the block terminate question depend on another block question
    // it will return true hence randomize is not possible
    const terminateConditionConflict = condition?.terminateConditions?.some(
      (dc) => {
        // if condition question id is in cache
        // it means its inside same block
        return cache[dc.question];
      }
    );

    // if there atleast one skip condition that means randomize is not possible
    const skipConditionConflict = condition?.skipConditions?.length;

    // if there is any block questions that has piping dependency on
    // siblings then randomize is not possible
    const pipingConditionConflict =
      condition?.pipingConditions?.pipingFrom?.some((q) => cache[q.id]);

    return (
      displayConditionConflict ||
      skipConditionConflict ||
      terminateConditionConflict ||
      pipingConditionConflict
    );
  });
  return conditionAmong;
}

export function checkDeactivationConflict(questionId, questions = []) {
  return questions.some((q) => {
    // check if the question that is being
    // deactivated is not piped to any other question
    const pipingConflict = q.id === questionId && q.isPipedTo;

    const condition = q["#conditions"];

    // Checking for terminate conflict
    const surveyTerminateQuestions = questions.filter(
      (q) => q.type === QUESTION_TYPES.TERMINATE
    );

    const terminateQuesReferingToQuesIds = surveyTerminateQuestions.flatMap(
      (terminateQuestion) =>
        terminateQuestion[CONDITIONS].terminateConditions.map(
          (condition) => condition.question
        )
    );

    const terminateConflict =
      terminateQuesReferingToQuesIds.includes(questionId);

    const skipConflict = condition?.skipConditions?.some((condition) => {
      return condition.skipDestination === questionId;
    });

    const displayConflict = condition?.displayConditions?.some((condition) => {
      return condition.question === questionId;
    });

    const choiceConflict = checkChoiceDependency(questionId, q);

    const cvConflict = q.choices?.some((choice) => {
      return choice.source?.questionId === questionId;
    });

    const eqConflict =
      q.type === EQUATION &&
      q.equations?.some((eq) => {
        return (
          (eq.type === "question" &&
            eq.questions.some(
              (q) => q.type === "question" && q.value === questionId
            )) ||
          (eq.type === "function" &&
            eq.questions.some(
              (q) => q.type === "question" && q.value === questionId
            ))
        );
      });

    // check conflict for all the questions of a block
    const children = questions.filter((ques) => ques.parentId === questionId);
    const childrenConflict = children.some((child) =>
      checkDeactivationConflict(child.id, questions)
    );

    // this will check if the source file variable that is being
    // deactivated has been imported into any question
    const sourceFileVariableConflict =
      q.importedSourceVariables &&
      q.importedSourceVariables.includes(questionId);

    return (
      skipConflict ||
      displayConflict ||
      pipingConflict ||
      cvConflict ||
      childrenConflict ||
      choiceConflict ||
      eqConflict ||
      terminateConflict ||
      sourceFileVariableConflict
    );
  });
}

export function isDeactivated(questionId, questions = []) {
  const question = questions.find((q) => q.id === questionId);
  const parent = questions.find((q) => q.id === question?.parentId);
  if (question && question.deactivated) {
    return true;
  }
  if (parent && parent.deactivated) {
    return true;
  }
  return false;
}

function checkChoiceDependency(questionId, question) {
  return question?.choices?.some((choice) => {
    return choice?.logic?.conditions?.some(
      (condition) => condition?.question === questionId
    );
  });
}

export function separateBlockQuestionsOnAPage(
  activeGroupQuestions = [],
  hiddenGroupQuestions = []
) {
  const block = activeGroupQuestions.find(
    (q) => q.type === QUESTION_TYPES.BLOCK
  );
  // if block is not there then return the values as it is
  if (!block) {
    return {
      activeGroupQuestions,
      hiddenGroupQuestions,
    };
  }

  const blockIndex = activeGroupQuestions.findIndex((q) => q.id === block?.id);
  const blockQuestions = activeGroupQuestions.filter(
    (q) => q.parentId === block?.id
  );
  const questionsBeforeBlock = activeGroupQuestions.slice(0, blockIndex);
  const questionsAfterBlock = activeGroupQuestions.slice(
    blockIndex + blockQuestions.length + 1
  );

  // if there are some questions before block then show them and put others in hidden
  if (questionsBeforeBlock.length) {
    return {
      activeGroupQuestions: questionsBeforeBlock,
      hiddenGroupQuestions: [
        block,
        ...blockQuestions,
        ...questionsAfterBlock,
        ...hiddenGroupQuestions,
      ],
    };
  }

  // if there are block questions then show them and put other in hidden
  if (blockQuestions.length) {
    const newHiddenGroupQuestions = [
      ...questionsAfterBlock,
      ...hiddenGroupQuestions,
    ];
    return {
      activeGroupQuestions: blockQuestions,
      hiddenGroupQuestions: newHiddenGroupQuestions,
    };
  }

  // if there are some questions after block then show them
  if (questionsAfterBlock.length) {
    return {
      activeGroupQuestions: questionsAfterBlock,
      hiddenGroupQuestions,
    };
  }

  // if no condition matches then return values as it is
  return {
    activeGroupQuestions,
    hiddenGroupQuestions,
  };
}

export function separateLoopedBlockQuestions(questions = []) {
  const bucket = {};

  // this will group the questions along
  // with their clones in case of piping
  questions.forEach((q, idx) => {
    // if its a block question then we'll use pipingParentID to group looped questions
    // else we will use index to put them in separate buckets
    // if pipingParentID is not there it means its not a looped question
    // so we will use index to avoid putting all questions in same bucket because of undefined
    const key = `${q.parentId}_${
      q.parentId === DEFAULT_PARENT_ID ? idx : q.pipingParentID || idx
    }`;
    if (!bucket[key]) {
      bucket[key] = [];
    }
    bucket[key].push(q);
  });

  const currentPageQuestions = [];
  const nextPageQuestions = [];

  Object.values(bucket).forEach((slot) => {
    slot[0] && currentPageQuestions.push(slot[0]);
    nextPageQuestions.push(...slot.slice(1));
  });

  return { currentPageQuestions, nextPageQuestions };
}

export function removeEmptyBlocks(questions = []) {
  const parentsBucket = {};

  questions.forEach((q) => {
    parentsBucket[q.parentId] = true;
  });

  return questions.filter((q) => {
    if (q.type === QUESTION_TYPES.BLOCK && !parentsBucket[q.id]) {
      return false;
    }

    return true;
  });
}

export function isBlockEmpty(blockId, questions = []) {
  const blockQuestions = questions.filter((q) => q.parentId === blockId);
  const activeBlockQuestion = blockQuestions.filter((q) => !q.deactivated);
  return activeBlockQuestion.length === 0;
}

export const checkCVConflict = (questionId, questions = []) => {
  return questions.some((q) => {
    return (
      q.type === "variable" &&
      q.choices?.some((choice) => {
        return choice.source?.questionId === questionId;
      })
    );
  });
};

export const checkEVConflict = (questionId, questions = []) => {
  return questions.some((q) => {
    return (
      q.type === EQUATION &&
      q.equations?.some((eq) => {
        return (
          (eq.type === "question" &&
            eq.questions.some(
              (q) => q.type === "question" && q.value === questionId
            )) ||
          (eq.type === "function" &&
            eq.questions.some(
              (q) => q.type === "question" && q.value === questionId
            ))
        );
      })
    );
  });
};

export function checkDisplayConditionConflict(
  questionOne = {},
  questionTwo = {}
) {
  return (
    questionTwo[CONDITIONS] &&
    questionTwo[CONDITIONS].displayConditions?.some(
      (condition) => condition.question === questionOne.id
    )
  );
}
