import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createBrowserHistory as createHistory } from "history";
import rootReducer from "./modules";

export const history = createHistory();

const initialState = {};
const enhancers = [];
let middleware = [thunk];
window.process = {
  // Fix for process not defined
  ...window.process, // The problem is because we window.process variable when React hotloads, and process exists only on node, not the browser.
};
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
  middleware = [...middleware, logger];
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
