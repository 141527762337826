import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import fr from "./fr";
import es from "./es";
import zh from "./zh";
import my from "./my";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import dayjs from "dayjs";
require("dayjs/locale/zh");
require("dayjs/locale/es");
require("dayjs/locale/fr");
require("dayjs/locale/en");

export const languageMapping = {
  en: { label: "English", label_en: "English", index: 0, value: "en" },
  fr: { label: "Français", label_en: "French", index: 1, value: "fr" },
  es: { label: "Español", label_en: "Spanish", index: 2, value: "es" },
  zh: { label: "普通话", label_en: "Chinese", index: 3, value: "zh" },
  my: { label: "မြန်မာ", label_en: "Burmese", index: 4, value: "my" },
};

export const languages = [
  {
    label: "English",
    label_short: "EN",
    label_en: "English",
    value: "en",
  },
  {
    label: "Français",
    label_short: "FR",
    label_en: "French",
    value: "fr",
  },
  {
    label: "Español",
    label_short: "ESP",
    label_en: "Spanish",
    value: "es",
  },
  {
    label: "普通话",
    label_short: "普通话",
    label_en: "Chinese",
    value: "zh",
  },
  {
    label: "မြန်မာ",
    label_short: "မြန်မာ",
    label_en: "Burmese",
    value: "my",
  },
];

export const hasAnyLanguage = (items = {}) => Object.keys(items).length > 0;

export const hasMultipleLanguages = (items = {}) =>
  Object.keys(items).length > 1;

export const getLanguagesFullObjectArray = (items) => {
  return Object.entries(items).map(([key, value]) =>
    getLanguageDetails(key, value)
  );
};
{
  /*This fn will return true or false if hiddenQuestion exists */
}
export const checkHasHiddenQuestion = (items) => {
  return items.find((eachQuestion) => eachQuestion.type === "hidden")
    ? true
    : false;
};

const getLanguageDetails = (item, isTranslationCompleted) => {
  const language = languages.find((lang) => lang.value === item) || {
    value: item,
  };
  language.isTranslationCompleted = isTranslationCompleted;
  return language;
};

export const getLanguagesEnglishLabels = (items) =>
  Object.keys(items).map((item) => {
    const data = languages.find((lang) => lang.value === item);
    return data ? data.label_en : item;
  });

//const LANG_PREF_KEY = "i18nextLng";

const namespaces = Object.keys(en);

const resources = {
  en,
  fr,
  es,
  zh,
  my,
};

const getPreferredLanguage = () => {
  // Commenting this to fix GDVCVR-13982 UI change to other language

  // const storedLang = localStorage.getItem(LANG_PREF_KEY);
  // if (Object.keys(resources).includes(storedLang)) {
  //   return storedLang;
  // }
  return languages[0].value;
};

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: getPreferredLanguage(),
    fallbackLng: languages.map(({ value }) => value),
    ns: namespaces,
    defaultNS: "common",
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    debug: window.location.hostname === "localhost", // debug only if running in local
  });

document.documentElement.setAttribute("lang", i18next.language);
dayjs.locale(i18next.language);

i18next.on("languageChanged", (lang) => {
  document.documentElement.setAttribute("lang", lang);
  dayjs.locale(lang);
});

export const DEFAULT_LANGUAGE = languages.find(
  (ele) => ele.value === i18next.language
);

export default i18next;

export const getTranslation = (lang, key) => {
  const [ns, k] = key.split(":");
  return i18next.getResource(lang, ns, k);
};

/**
 * To Change language
 *
 * i18next.changeLanguage('en-US'); // For english
 *
 * i18next.changeLanguage('es-MX'); // For spanish
 *
 */
