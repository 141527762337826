import _ from "lodash";
import { getTemplateList } from "../../services/api";
import { trackAndShowError } from "../dialog";

const TEMPLATE_LIST = "templateList/TEMPLATE_LIST";
const SELECTED_TEMPLATE = "templateList/SELECTED_TEMPLATE";

const initialState = {
  templateList: {},
  selectedTemplateId: 0,
  templateListInProgress: true,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case TEMPLATE_LIST:
      state.templateList = action.data;
      state.templateListInProgress = false;
      break;
    case SELECTED_TEMPLATE:
      state.selectedTemplateId = action.data;
      break;
    default:
      break;
  }
  return state;
};

export const templateList = (lang) => {
  return (dispatch) => {
    return getTemplateList(lang)
      .then((data) =>
        dispatch({
          type: TEMPLATE_LIST,
          data,
        })
      )
      .catch((response) => {
        const trackMessage = "Error in loading template list for survey - ";
        dispatch(
          trackAndShowError(
            "ERROR_TEMPLATE_LIST_LOADING",
            trackMessage,
            response
          )
        );
      });
  };
};

export const selectedTemplateId = (template) => {
  return (dispatch) => {
    dispatch({
      type: SELECTED_TEMPLATE,
      data: template,
    });
  };
};

export const actions = {
  getTemplateList: templateList,
  setSelectedTemplateId: selectedTemplateId,
};
