import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import getPath from "./services/getPath";
import { createBrowserHistory as createHistory } from "history";
import { getRenderComponent } from "./App";
import Loading from "./components/pres/Loading/index.jsx";
const PreviewNavigation = lazy(() =>
  import("./components/pres/PreviewNavigation")
);
const SurveyList = lazy(() => import("./components/page/SurveyList"));
const SurveyAnswer = lazy(() => import("./components/page/SurveyAnswer"));
const SurveyTakerCustomer = lazy(() =>
  import("./components/page/SurveyTakerCustomer/index.jsx")
);
const SurveyDone = lazy(() => import("./components/page/SurveyDone"));
const SurveyCreate = lazy(() => import("./components/page/SurveyCreate"));
const SurveyResults = lazy(() => import("./components/page/SurveyResults"));
const SurveyInformation = lazy(() =>
  import("./components/page/SurveyInformation")
);

export const Routes = () => {
  const history = createHistory();
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={getPath("/create/:id")}
          render={(props) => getRenderComponent(<SurveyCreate {...props} />)}
        />
        <Route
          path={getPath("/create")}
          render={(props) => getRenderComponent(<SurveyCreate {...props} />)}
        />

        <Route
          path={getPath("/results/:id")}
          render={(props) => getRenderComponent(<SurveyResults {...props} />)}
        />
        <Route
          path={getPath("/survey/info/:id")}
          render={(props) =>
            getRenderComponent(<SurveyInformation {...props} />)
          }
        />
        <Route
          path={getPath("/survey/preview/:id")}
          render={(props) =>
            getRenderComponent(<PreviewNavigation {...props} />)
          }
        />
        <Route
          path={getPath("/survey/:id/:encodedValues?")}
          component={SurveyAnswer}
        />
        <Route
          path={getPath("/campaign/:encodedValues?")}
          component={SurveyTakerCustomer}
        />
        <Route path={getPath("/preview/:id")} component={SurveyAnswer} />
        <Route
          path={getPath("/share/:id")}
          render={(props) =>
            getRenderComponent(<SurveyList history={history} {...props} />)
          }
        />
        <Route path={getPath("/done")} component={SurveyDone} />
      </Switch>
    </Suspense>
  );
};
