import _ from "lodash";
import { createSelector } from "reselect";
import moment from "moment";
import { getUserInfo, postUserInfo, updateUserInfo } from "../../services/api";
import i18n from "../../locales";
import isJSON from "../../utils/jsonValidator";

const GET = "userInfo/GET";
const GET_COMPLETED = "userInfo/GET_COMPLETED";
const UPDATE = "userInfo/UPDATE";
const UPDATE_COMPLETED = "userInfo/UPDATE_COMPLETED";
const POST = "userInfo/POST";
const POST_COMPLETED = "userInfo/POST_COMPLETED";

const availableAppNotifications = ["template", "authorGuide"];

const initialState = {
  data: {
    lang_preference: i18n.language,
  },
  requestInProgress: false,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case GET:
      state.requestInProgress = true;
      break;
    case GET_COMPLETED:
      state.requestInProgress = false;
      state.data = { ...state.data, ...action.data };
      break;
    case UPDATE:
      state.requestInProgress = true;
      state.data = { ...state.data, ...action.data };
      break;
    case UPDATE_COMPLETED:
      state.requestInProgress = false;
      break;
    case POST:
      state.requestInProgress = true;
      break;
    case POST_COMPLETED:
      state.requestInProgress = false;
      break;
    default:
      break;
  }
  return state;
};

const updateLanguagesPreference = (langPreference) => {
  return (dispatch) => {
    i18n.changeLanguage(langPreference);
    const data = { lang_preference: langPreference };
    return dispatch(putUserInfo(data));
  };
};

const requestUserInfo = () => {
  return (dispatch) => {
    dispatch({
      type: GET,
    });
    const userInfo = getUserInfo();
    return userInfo
      .then((data) => {
        return dispatch({
          type: GET_COMPLETED,
          data,
        });
      })
      .catch((err) => {
        const errData = isJSON(err) ? JSON.parse(err) : err;
        if (errData.message === "No user info present") {
          dispatch(createUserInfo());
        }
      });
  };
};

const createUserInfo = () => {
  return (dispatch) => {
    dispatch({
      type: POST,
    });

    const data = {
      app_notifications: availableAppNotifications.map((notification) => ({
        name: notification,
        status: true,
        created_on: new Date().toISOString(),
      })),
    };

    const userInfo = postUserInfo(data);
    return userInfo
      .then(() => {
        dispatch({
          type: POST_COMPLETED,
        });
        return dispatch(requestUserInfo());
      })
      .catch(() => {});
  };
};

const putUserInfo = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE,
      data,
    });
    const userInfo = updateUserInfo(data);
    return userInfo
      .then(() =>
        dispatch({
          type: UPDATE_COMPLETED,
        })
      )
      .catch(() => {});
  };
};

const putAppNotification = (name, status) => {
  return (dispatch, getState) => {
    const state = getState();
    const appNotification = getAppNotificationByName(name)(state);
    if (appNotification) {
      const appNotifications = getAppNotifications(state).map((item) => {
        if (item["name"] === appNotification["name"]) {
          return {
            ...item,
            status,
            modified_on: new Date().toISOString(),
          };
        }
        return item;
      });
      const data = {
        app_notifications: appNotifications,
      };
      return dispatch(putUserInfo(data));
    }
  };
};

const getRequestInProgress = (state) => state.userInfo?.requestInProgress;

const userInfoData = (state) => state.userInfo?.data;

const getAppNotifications = createSelector(
  [userInfoData],
  (state) => state?.app_notifications || []
);

const getAppNotificationByName = (name) =>
  createSelector([getAppNotifications], (appNotifications) =>
    appNotifications.find((appNotification) => appNotification.name === name)
  );

const getAppNotificationStatus = (name) =>
  createSelector([getAppNotificationByName(name)], (appNotification) => {
    if (appNotification) {
      if (name === "authorGuide") {
        const prevDate = moment(
          new Date(
            appNotification.modified_on ?? appNotification.created_on
          ).getTime()
        );
        const currentDate = moment(new Date().getTime());
        const diff = currentDate.diff(prevDate, "month", true);
        return appNotification.status || diff < 0 || diff > 2;
      }
    }
    return appNotification && appNotification.status;
  });

export const actions = {
  getUserInfo: requestUserInfo,
  postUserInfo: createUserInfo,
  updateUserInfo: putUserInfo,
  updateAppNotification: putAppNotification,
  updateLanguagesPreference: updateLanguagesPreference,
};

export const selectors = {
  getRequestInProgress,
  userInfoData,
  getAppNotifications,
  getAppNotificationByName,
  getAppNotificationStatus,
};
