import common from "./common.json";
import dashboard from "./dashboard.json";
import modal from "./modal.json";
import survey from "./survey.json";
import surveyResult from "./surveyResult.json";
import surveyTaker from "./surveyTaker.json";
import surveyBuilder from "./surveyBuilder.json";
import launch from "./launch.json";

export default {
  common,
  dashboard,
  modal,
  survey,
  surveyResult,
  surveyTaker,
  surveyBuilder,
  launch,
};
