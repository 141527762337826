import _ from "lodash";

const SET_TOKEN = "auth/SET_TOKEN";

const initialState = {
  token: null,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case SET_TOKEN:
      state.token = action.token;
      break;
    default:
      break;
  }
  return state;
};

const setToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOKEN,
      token,
    });
  };
};

export const actions = {
  authSetToken: setToken,
};
