import React, { Fragment, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { PRIVACY_POLICY } from "../../constants";
import { showTerms } from "../../../modules/dialog";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { parseJwtToken } from "../../../services/commons";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PrivacyPolicyDialog({
  classes,
  open,
  handleLogout,
  setOpen,
  anonymousAccess,
  hasMobileAuth,
}) {
  const { t } = useTranslation("surveyTaker");
  const [surveyPolicy, setSurveyPolicy] = useState(PRIVACY_POLICY);
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const { survey } = useSelector((state) => state);
  const stringified = JSON.stringify(survey.data);
  const dispatch = useDispatch();
  const linkRef = [];
  const USER_NAME_MAX_DISPLAY_LENGTH = 15;
  const handleTermsAndConditions = () => {
    handleClose();
    dispatch(showTerms());
  };

  const [privacyExpanded, setprivacyExpanded] = useState(false);
  const [popen, setpOpen] = React.useState(false);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setpOpen(false);
    } else if (event.key === "Enter" || event.keyCode === 32) {
      event.target.querySelector("a").click();
    } else if (event.key === "Escape") {
      setpOpen(false);
    }
  }

  const handleMenuItemClick = (e, index) => {
    linkRef[index].click();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setprivacyExpanded(false);
  };

  useEffect(() => {
    if (survey && survey.currentSurveyId) {
      const { currentSurveyId } = survey;
      const { thirdPartySettings } = survey.data[currentSurveyId];

      if (thirdPartySettings && thirdPartySettings.selected_policy) {
        if (thirdPartySettings.selected_policy.length > 1) {
          setSelectedPolicy([...thirdPartySettings.selected_policy]);
        }

        if (thirdPartySettings.selected_policy.length === 1) {
          setSelectedPolicy([...thirdPartySettings.selected_policy]);
          setSurveyPolicy(thirdPartySettings.selected_policy[0].policy_link);
        }

        if (
          thirdPartySettings.selected_policy[0].policy_name.toLowerCase() ===
          "canada"
        ) {
          setDisableFlag(true);
        } else {
          setDisableFlag(false);
        }
      }
    }
  }, [stringified]);

  const payload = parseJwtToken();

  let userName = payload?.fullname;
  const { userid = "" } = payload;
  if (userName && userName.length > USER_NAME_MAX_DISPLAY_LENGTH) {
    userName = userName.substr(0, USER_NAME_MAX_DISPLAY_LENGTH) + "...";
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        data-testid={"privacyModal"}
      >
        <div className={classes.privacyModal}>
          <DialogTitle className={classes.heading}>
            {privacyExpanded ? (
              <div
                onClick={() => setprivacyExpanded(false)}
                data-testid="backbutton"
              >
                {/* Back button */}
                <Button className={classes.iconCorners}>
                  <ChevronLeft className={classes.iconBack} />
                </Button>
              </div>
            ) : (
              <div />
            )}

            {privacyExpanded ? (
              <div data-testid="headingPrivacy">
                {" "}
                <b>Privacy Policy</b>
              </div>
            ) : (
              <div data-testid="headingInfo">
                {" "}
                <b>Information</b>
              </div>
            )}
            <Button
              aria-label="close"
              onClick={handleClose}
              data-testid="closebutton"
              className={classes.iconCorners}
            >
              <CloseOutlined className={classes.iconClose} />
            </Button>
          </DialogTitle>

          <DialogContent>
            {privacyExpanded ? (
              <MenuList
                data-testid="menuList1"
                autoFocusItem={popen}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
              >
                {selectedPolicy &&
                  selectedPolicy.map((data, index) => (
                    <MenuItem
                      data-testid={`menuItem${index}`}
                      key={index}
                      onClick={(e) => handleMenuItemClick(e, index)}
                      aria-label={data.policy_name}
                      style={{ padding: 0 }}
                    >
                      <a
                        className={classes.footerLinksPrivacyLink}
                        href={data.policy_link}
                        target="_blank"
                        rel="noreferrer"
                        ref={(ref) => (linkRef[index] = ref)}
                      >
                        {data.policy_name}{" "}
                        <OpenInNewIcon className={classes.openicon} />
                      </a>
                    </MenuItem>
                  ))}
              </MenuList>
            ) : (
              <DialogContentText id="alert-dialog-slide-description">
                {/* User Name or User ID */}
                {(userName || userid) && !anonymousAccess && (
                  <div className={classes.alignIcons}>
                    <PermIdentityIcon
                      className={classes.iconCenter}
                      fontSize="large"
                    />
                    {
                      <Button
                        className={classes.footerLinksTerms}
                        disabled
                        data-testid={"personalInfo"}
                      >
                        {`${String(userName).toUpperCase()} (${
                          String(userid).split("@")[0]
                        })`}
                      </Button>
                    }
                  </div>
                )}

                {/* Terms of Use */}
                <div className={classes.alignIcons}>
                  <FeedbackOutlinedIcon className={classes.iconCenter} />
                  {!disableFlag ? (
                    <Button
                      className={classes.footerLinksTerms}
                      onClick={handleTermsAndConditions}
                      data-testid={"termsOfUse"}
                    >
                      {" "}
                      {t("termsOfUse")}{" "}
                    </Button>
                  ) : null}
                </div>

                {/* Privacy Policy */}
                <div className={classes.alignIcons}>
                  <LockOutlinedIcon className={classes.iconCenter} />
                  {selectedPolicy && selectedPolicy.length > 1 ? (
                    <Button
                      className={classes.privacyButton}
                      onClick={setprivacyExpanded}
                      data-testid={"privacyPolicy"}
                    >
                      Privacy Policy
                      <ChevronRight className={classes.openicon} />
                    </Button>
                  ) : (
                    <a
                      className={classes.footerLinksPrivacy}
                      href={surveyPolicy}
                      target="_blank"
                      rel="noreferrer"
                      data-testid={"privacyPolicy1"}
                    >
                      {" "}
                      {t("privacyPolicy")}{" "}
                      <OpenInNewIcon
                        style={{ paddingLeft: "5px", marginRight: "7px" }}
                        className={classes.openicon}
                      />
                    </a>
                  )}
                </div>

                {/* Log out*/}
                {!anonymousAccess && !hasMobileAuth && (
                  <div className={classes.alignIconsLogout}>
                    {
                      <Button
                        className={classes.footerLinksTerms}
                        onClick={handleLogout}
                        data-testid={"logout"}
                      >
                        {t("dashboard:logout")}
                      </Button>
                    }
                  </div>
                )}
              </DialogContentText>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </Fragment>
  );
}

export default withStyles(styles)(PrivacyPolicyDialog);
