import React from "react";
import MuiTextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import noop from "../../../services/noop";
import s from "./style.module.scss";
import { useTranslation } from "react-i18next";

const handleChange = function (props, e) {
  let newValue = e.currentTarget.value;
  if (props.maxChar && newValue.length > props.maxChar) {
    newValue = newValue.substring(0, props.maxChar);
  }
  props.handleChange(newValue, e);
};

const onBlur = function (props, e) {
  if (props.onBlur) {
    props.onBlur(e.currentTarget.value, e);
  }
};

const Text = (props) => {
  const { t } = useTranslation("surveyTaker");
  return (
    <div>
      <MuiTextField
        required={props.required === true ? true : false}
        label={props.label}
        className={props.className}
        placeholder={props.placeholder}
        onChange={handleChange.bind(null, props)}
        onBlur={onBlur.bind(null, props)}
        margin="normal"
        value={props.value}
        type={props.password ? "password" : "text"}
        error={props.error}
        disabled={props.disabled ? props.disabled : false}
      />
      {props.maxChar && (
        <div className={s.textBottomContainer}>
          {props.maxChar && props.value.length >= props.maxChar && (
            <div className={s.limitWarning}>
              {" "}
              {props.charLimitWarnMsg
                ? props.charLimitWarnMsg
                : t("surveyTaker:characterLimitReached")}{" "}
            </div>
          )}
          {props.maxChar && (
            <div className={s.counterBox}>
              {props.value.length}/{props.maxChar}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Text.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};

Text.defaultProps = {
  label: "",
  className: "",
  placeholder: "",
  handleChange: noop,
  value: "",
};

export default Text;
