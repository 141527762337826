import _ from "lodash";

const PAGE_NAME = "pageInfo/PAGE_NAME";
const CLEAR_PAGE_NAME = "pageInfo/CLEAR_PAGE_NAME";

const initialState = {
  pageName: null,
  active: null,
};

export default (state = initialState, action) => {
  state = _.cloneDeep(state);
  switch (action.type) {
    case PAGE_NAME:
      state.pageName = action.pageName;
      state.pageSubName = action.pageSubName;
      state.active = action.active;
      break;
    case CLEAR_PAGE_NAME:
      state.pageName = null;
      break;
    default:
      break;
  }
  return state;
};

export const updateName = (data) => {
  return (dispatch) => {
    dispatch({
      type: PAGE_NAME,
      pageName: data.pageName,
      pageSubName: data.pageSubName,
      active: data.active,
    });
  };
};

export const clearName = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PAGE_NAME,
    });
  };
};

export const actions = {
  updatePageName: updateName,
  clearPageName: clearName,
};
