import _ from "lodash";
import { DEFAULT_PARENT_ID, QUESTION_TYPES } from "../components/constants";

export default function getUpdatedBlockEmptyState(questions) {
  const updatedQuestions = _.cloneDeep(questions);
  const cache = {};
  updatedQuestions.forEach((q) => {
    const parentId = q.parentId || DEFAULT_PARENT_ID;
    if (!cache[parentId]) {
      cache[parentId] = [];
    }
    cache[parentId].push(q);
  });

  updatedQuestions.forEach((q) => {
    if (q.type === QUESTION_TYPES.BLOCK) {
      if (cache[q.id] && cache[q.id].length) {
        q.isBlockEmpty = false;
      } else {
        q.isBlockEmpty = true;
      }
    }
  });

  return updatedQuestions;
}
