import React from "react";
import PropTypes from "prop-types";
import noop from "../../../services/noop/index";
import colors from "../../../style/colors.module.scss";

const Icon = (props) => (
  <i
    className={["material-icons", props.className].join(" ")}
    style={{ color: props.color }}
    onClick={props.handleClick}
    data-testid={props.name}
  >
    {props.name}
  </i>
);

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  handleClick: PropTypes.func,
};

Icon.defaultProps = {
  className: "",
  name: "",
  color: colors.darker,
  handleClick: noop,
};

export default Icon;
